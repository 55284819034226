import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CompanyBranchForm from '@shared/modules/Organization/forms/CompanyBranchForm';
import useUpdateCompanyBranchMutation from '@shared/modules/Organization/hooks/useUpdateCompanyBranchMutation';

const EditCompanyBranchDrawer = ({
  isOpen,
  handleClose,
  refetch,
  organization,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  organization: OrganizationModel;
}) => {
  const updateSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Changes saved.',
  });
  const companyBranchForm = CompanyBranchForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateCompanyBranchMutation({
    companyBranchForm,
    onSuccess: () => {
      updateSuccessToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'companyBranchForm';
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Branch'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
      shouldCloseOnClickOutside={false}
      primaryActionIcon={Icon.Check}
    >
      <FieldInput
        {...form}
        label={'Name'}
        name={`${field}.name`}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter branch name',
        }}
        style={{flex: 1}}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCompanyBranchDrawer.fragment = gql`
  ${CompanyBranchForm.edit.fragment}
  fragment EditCompanyBranchDrawer on Organization {
    id
    ...CompanyBranchForm_edit
  }
`;

export default EditCompanyBranchDrawer;
