// Supermove
import {gql} from '@supermove/graphql';
import {UserModel} from '@supermove/models';
import {List, withFragment} from '@supermove/utils';

// App
import {NavigationItem} from 'core/NavigationItems';

/**
 * Retrieves Company Details navigation items.
 */
const getItems = withFragment(
  ({viewer}: {viewer: UserModel}): NavigationItem[] => {
    return [
      {
        label: 'Company',
        value: 'COMPANY',
        valueKey: 'section',
        items: [
          {
            label: 'Overview',
            value: 'CompanyDetailsOverviewPage',
            valueKey: 'route',
          },
          {
            label: 'Products',
            value: 'CompanyDetailsProductsPage',
            valueKey: 'route',
          },
          ...(List.insertIf(viewer.isEpd, {
            label: 'Branches',
            value: 'CompanyDetailsBranchesPage',
            valueKey: 'route',
          }) as NavigationItem[]),
        ],
      },
      {
        label: 'Modules',
        value: 'MODULES',
        valueKey: 'section',
        items: [
          {
            label: 'Calendars',
            value: 'CompanyModulesCalendarsPage',
            valueKey: 'route',
          },
          {
            label: 'Communication',
            value: 'CompanyModulesCommunicationPage',
            valueKey: 'route',
          },
          {
            label: 'Equipment & Materials',
            value: 'CompanyModulesEquipmentAndMaterialsPage',
            valueKey: 'route',
          },
          {
            label: 'Inventory',
            value: 'CompanyModulesInventoryPage',
            valueKey: 'route',
          },
          {
            label: 'Payments',
            value: 'CompanyModulesPaymentsPage',
            valueKey: 'route',
          },
          {
            label: 'Storage',
            value: 'CompanyModulesStoragePage',
            valueKey: 'route',
          },
          {
            label: 'Timesheets',
            value: 'CompanyModulesTimesheetsPage',
            valueKey: 'route',
          },
        ],
      },
      {
        label: 'AI Sales Copilot',
        value: 'AI_SALES_COPILOT',
        valueKey: 'section',
        items: [
          {
            label: 'General',
            value: 'CompanyDetailsAiSalesCopilotGeneralPage',
            valueKey: 'route',
          },
        ],
      },
    ];
  },
  gql`
    fragment CompanyDetailsNavigation_getItems on User {
      id
      isEpd
    }
  `,
);

const CompanyDetailsNavigation = {
  getItems,
};

export default CompanyDetailsNavigation;
