// Libraries
import React from 'react';

// Supermove
import {Emoji, Icon, Styled, Space, IconSource} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.ButtonV2<{
  size: keyof typeof SIZE;
  borderColor: string;
  backgroundColor: string;
}>`
  width: fit-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({size}) => (size === SIZE.LARGE ? '32px' : '24px')};
  padding-horizontal: 12px;
  padding-vertical: 4px;
  border-radius: 24px;
  border-color: ${({borderColor}) => borderColor};
  border-width: 1px;
  background-color: ${({backgroundColor}) => backgroundColor};
`;

const IconContainer = Styled.ButtonV2`
`;

const Text = Styled.Text<{color: string}>`
  ${Typography.Responsive.Micro}
  color: ${({color}) => color};
`;

const EmojiContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 18px;
`;

const EmojiText = Styled.Text`
  ${Typography.Micro}
`;

const SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

const TagIcon = ({
  icon,
  color,
  onPress,
  isDisabled,
}: {
  icon: IconSource;
  color: string;
  onPress?: () => void;
  isDisabled?: boolean;
}) => {
  return (
    <IconContainer disabled={isDisabled || !onPress} onPress={onPress}>
      <Icon source={icon} size={12} color={color} />
    </IconContainer>
  );
};

const getBackgroundColor = ({
  isSelected,
  isDisabled,
  hasWarning,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  hasWarning?: boolean;
}) => {
  if (isSelected) {
    if (isDisabled) {
      return colors.gray.disabled;
    }
    return colors.blue.interactive;
  }
  if (hasWarning) {
    return colors.orange.accent;
  }
  return colors.gray.background;
};

const getBorderColor = ({
  isSelected,
  isDisabled,
  hasWarning,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  hasWarning?: boolean;
}) => {
  if (isSelected && !isDisabled) {
    return colors.blue.interactive;
  }
  if (hasWarning) {
    return colors.orange.hover;
  }
  return colors.gray.border;
};

const getIconColor = ({
  isSelected,
  isDisabled,
  hasWarning,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  hasWarning?: boolean;
}) => {
  if (isDisabled) {
    if (isSelected) {
      return colors.gray.tertiary;
    }
    return colors.gray.disabled;
  }
  if (isSelected) {
    return colors.white;
  }
  if (hasWarning) {
    return colors.orange.status;
  }
  return colors.gray.secondary;
};

const getTextColor = ({
  isSelected,
  isDisabled,
  hasWarning,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
  hasWarning?: boolean;
}) => {
  if (isDisabled) {
    return colors.gray.tertiary;
  }
  if (isSelected) {
    return colors.white;
  }
  if (hasWarning) {
    return colors.orange.status;
  }
  return colors.gray.secondary;
};

const Tag = ({
  label,
  onPress,
  emoji,
  iconLeft,
  iconRight,
  onIconRightPress,
  isDisabled,
  isSelected,
  hasWarning,
}: {
  label?: string | null;
  onPress?: () => void;
  emoji?: string;
  iconLeft?: IconSource;
  iconRight?: IconSource;
  onIconRightPress?: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  hasWarning?: boolean;
}) => {
  const responsive = useResponsive();
  const size = responsive.desktop ? SIZE.SMALL : SIZE.LARGE;
  const backgroundColor = getBackgroundColor({isSelected, isDisabled, hasWarning});
  const borderColor = getBorderColor({isSelected, isDisabled, hasWarning});
  const iconColor = getIconColor({isSelected, isDisabled, hasWarning});
  const textColor = getTextColor({isSelected, isDisabled, hasWarning});
  return (
    <Container
      size={size}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      disabled={!onPress}
      onPress={onPress}
    >
      {iconLeft && (
        <React.Fragment>
          <TagIcon icon={iconLeft} color={iconColor} />
          <Space width={4} />
        </React.Fragment>
      )}
      {emoji && (
        <React.Fragment>
          <EmojiContainer>
            <Emoji component={EmojiText} name={emoji} />
          </EmojiContainer>
          <Space width={4} />
        </React.Fragment>
      )}
      <Text color={textColor} responsive={responsive}>
        {label}
      </Text>
      {iconRight && (
        <React.Fragment>
          <Space width={4} />
          <TagIcon
            icon={iconRight}
            onPress={onIconRightPress}
            isDisabled={isDisabled}
            color={iconColor}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

export default Tag;
