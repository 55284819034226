// Libraries
import _ from 'lodash';

const MAIN = 'MAIN' as const;
const BRANCH = 'BRANCH' as const;
const CONTRACTOR = 'CONTRACTOR' as const;
const FRANCHISE = 'FRANCHISE' as const;

const VALUES = [MAIN, BRANCH, CONTRACTOR, FRANCHISE];

export type OrganizationKindType = (typeof VALUES)[number];

const VALUE_TO_DROPDOWN_LABEL = {
  [MAIN]: 'Main',
  [BRANCH]: 'Corporate',
  [CONTRACTOR]: 'Contractor',
  [FRANCHISE]: 'Franchise',
};

const getFilterDropdownOptions = () => {
  return [
    {
      label: 'Has only',
      options: [
        {
          value: MAIN,
          label: VALUE_TO_DROPDOWN_LABEL[MAIN],
        },
      ],
    },
    {
      label: 'Has any',
      options: [
        {
          value: BRANCH,
          label: VALUE_TO_DROPDOWN_LABEL[BRANCH],
        },
        {
          value: CONTRACTOR,
          label: VALUE_TO_DROPDOWN_LABEL[CONTRACTOR],
        },
        {
          value: FRANCHISE,
          label: VALUE_TO_DROPDOWN_LABEL[FRANCHISE],
        },
      ],
    },
  ];
};

const getIsIndependent = (kind: any) => _.includes([MAIN, FRANCHISE], kind);

const getLabel = (kind: OrganizationKindType) => VALUE_TO_DROPDOWN_LABEL[kind];

const OrganizationKind = {
  MAIN,
  BRANCH,
  CONTRACTOR,
  FRANCHISE,

  // Helpers
  getFilterDropdownOptions,
  getIsIndependent,
  getLabel,
};

export default OrganizationKind;
