// Supermove
import {useNavigation, useMountEffect} from '@supermove/hooks';

// TODO(Hammad) navigator.replace does not seem to solve an issue with back-button behavior.
const CompanyRootPage = () => {
  const {navigator, params} = useNavigation();

  useMountEffect(() => {
    navigator.replace('CompanyDetailsOverviewPage', {slug: params.slug});
  });

  return null;
};

export default CompanyRootPage;
