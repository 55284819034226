const GROUP_BY = {
  KIND: 'KIND',
  PRODUCT: 'PRODUCT',
  STATUS: 'STATUS',
};

const GROUP_BY_DEFINITIONS = [
  {
    label: 'By Type',
    value: GROUP_BY.KIND,
  },
  {
    label: 'By Product',
    value: GROUP_BY.PRODUCT,
  },
  {
    label: 'By Status',
    value: GROUP_BY.STATUS,
  },
];

const Company = {
  GROUP_BY,
  GROUP_BY_DEFINITIONS,
};

export default Company;
