// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {ResponsiveType} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const Container = Styled.View<{responsive: ResponsiveType; color: string}>`
  flex-direction: ${({responsive}) => (responsive.mobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding-vertical: 10px;
  background-color: ${({color}) => color};
`;

const Text = Styled.Text`
  ${Typography.Subheading}
  color: ${colors.white};
`;

const Banner = ({
  color,
  text,
  responsive,
  children,
}: {
  color: string;
  text: string;
  responsive: ResponsiveType;
  children: React.ReactNode;
}) => (
  <Container color={color} responsive={responsive}>
    <Text>{text}</Text>
    {children}
  </Container>
);

export default Banner;
