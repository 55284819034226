// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {useDrawer, useNavigation, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import AdminAppPage from 'modules/App/components/AdminAppPage';
import ExploDashboardPanel from 'modules/Dashboards/DashboardDetails/components/ExploDashboardPanel';
import GlobalDashboardDetailsPageAboutPanel from 'modules/Dashboards/DashboardDetails/components/GlobalDashboardDetailsPageAboutPanel';
import GlobalDashboardDetailsPageHeader from 'modules/Dashboards/DashboardDetails/components/GlobalDashboardDetailsPageHeader';
import GlobalDashboardDetailsPageSettingsPanel from 'modules/Dashboards/DashboardDetails/components/GlobalDashboardDetailsPageSettingsPanel';
import ManageGlobalDashboardCompaniesDrawer from 'modules/Dashboards/DashboardDetails/components/ManageGlobalDashboardCompaniesDrawer';
import UpdateGlobalDashboardDrawer from 'modules/Dashboards/components/UpdateGlobalDashboardDrawer';

const Container = Styled.View`
  display: flex;
  flex: 1;
`;

const ContentContainer = Styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 24px;
`;

const ContentBodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const SidebarContainer = Styled.View`
  flex-direction: column;
`;

const GlobalDashboardDetailsPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigation();
  const updateGlobalDashboardDrawer = useDrawer({name: 'Update Global Dashboard Drawer'});
  const manageCompaniesDrawer = useDrawer({name: 'Manage Companies Drawer'});

  const {loading, data, refetch} = useQuery(GlobalDashboardDetailsPageQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      globalDashboardUuid: params.globalDashboardUuid,
    },
  });

  if (!data || !data.globalDashboard) {
    return <AdminAppPage title={'Reports / ...'} isLoading />;
  }

  return (
    <AdminAppPage
      title={data.globalDashboard.name}
      HeaderElement={
        <GlobalDashboardDetailsPageHeader
          globalDashboard={data.globalDashboard}
          manageCompaniesDrawer={manageCompaniesDrawer}
          handleEdit={updateGlobalDashboardDrawer.handleOpen}
          refetch={refetch}
        />
      }
    >
      <ScrollView contentContainerStyle={{flex: 1}}>
        <Container>
          <ContentContainer style={{flexDirection: responsive.desktop ? 'row' : 'column'}}>
            <ContentBodyContainer>
              <ExploDashboardPanel
                globalDashboard={data.globalDashboard}
                viewerId={_.toNumber(data.viewer?.id)}
              />
            </ContentBodyContainer>
            <Space width={24} height={24} />
            <SidebarContainer
              style={{
                width: responsive.desktop ? 400 : undefined,
                flex: responsive.desktop ? undefined : 1,
              }}
            >
              <GlobalDashboardDetailsPageSettingsPanel globalDashboard={data.globalDashboard} />
              <Space height={24} />
              <GlobalDashboardDetailsPageAboutPanel
                globalDashboard={data.globalDashboard}
                manageCompaniesDrawer={manageCompaniesDrawer}
              />
            </SidebarContainer>
          </ContentContainer>
        </Container>
      </ScrollView>
      <UpdateGlobalDashboardDrawer
        key={updateGlobalDashboardDrawer.key}
        isOpen={updateGlobalDashboardDrawer.isOpen}
        globalDashboardUuid={data.globalDashboard.uuid}
        handleClose={updateGlobalDashboardDrawer.handleClose}
        refetch={refetch}
      />
      <ManageGlobalDashboardCompaniesDrawer
        isOpen={manageCompaniesDrawer.isOpen}
        globalDashboardUuid={data.globalDashboard.uuid}
        handleClose={manageCompaniesDrawer.handleClose}
        refetch={refetch}
      />
    </AdminAppPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const GlobalDashboardDetailsPageQuery = gql(`
  query GlobalDashboardDetailsPage(
        $globalDashboardUuid: String!
    ) {
    viewer {
      id
      viewingOrganization {
        id
        exploUserGroupToken
      }
    }
    globalDashboard(
      uuid: $globalDashboardUuid, 
    ) {
        id
        uuid
        name
        exploDashboardEmbedId
        exploVariables
        status
        ...GlobalDashboardDetailsPageSettingsPanel
        ...GlobalDashboardDetailsPageAboutPanel
    }
  }
`);

export default GlobalDashboardDetailsPage;
