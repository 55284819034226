// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';

const PaginationBarContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IconTile = Styled.ButtonV2`
  padding: 2px 8px;
`;

const Tile = Styled.ButtonV2`
  border: 1px solid ${(props: any) =>
    props.isActive ? colors.blue.interactive : colors.transparent};
  background-color: ${(props: any) => (props.isActive ? colors.blue.interactive : colors.transparent)};
  border-radius: 4px;
  padding: 2px 8px;
`;

const TileText = Styled.Text`
  ${Typography.Label}
  color: ${({vars}: any) => (vars.isActive ? colors.white : colors.gray.secondary)};
`;

const PreviousButton = ({disabled, handlePress}: any) => {
  return (
    <IconTile disabled={disabled} onPress={handlePress}>
      <Icon
        source={Icon.ChevronLeft}
        size={12}
        color={disabled ? colors.gray.tertiary : colors.blue.interactive}
      />
    </IconTile>
  );
};

const NextButton = ({disabled, handlePress}: any) => {
  return (
    <IconTile disabled={disabled} onPress={handlePress}>
      <Icon
        source={Icon.ChevronRight}
        size={12}
        color={disabled ? colors.gray.tertiary : colors.blue.interactive}
      />
    </IconTile>
  );
};

const PaginationBarTile = ({isActive, page, handlePress}: any) => {
  return (
    <Tile isActive={isActive} disabled={isActive} onPress={() => handlePress(page)}>
      <TileText vars={{isActive}}>{page}</TileText>
    </Tile>
  );
};

const PaginationBar = ({pagination}: any) => {
  const {currentPage, hasNext, hasPrevious, pageRange, handleChangePage} = pagination;
  if (!pageRange) {
    return null;
  }

  return (
    <PaginationBarContainer>
      <PreviousButton
        disabled={!hasPrevious}
        handlePress={() => handleChangePage(currentPage - 1)}
      />
      <Space width={8} />
      {pageRange.map((page: any, index: any) => (
        <React.Fragment key={index}>
          {page === 'ELLIPSIS' ? (
            <Tile isActive={false} disabled>
              <Icon source={Icon.EllipsisH} size={12} color={colors.gray.secondary} />
            </Tile>
          ) : (
            <PaginationBarTile
              page={page}
              isActive={currentPage === page}
              handlePress={handleChangePage}
            />
          )}
          <Space width={8} />
        </React.Fragment>
      ))}
      <NextButton disabled={!hasNext} handlePress={() => handleChangePage(currentPage + 1)} />
    </PaginationBarContainer>
  );
};

PaginationBar.DEFAULT_PAGINATION = {
  page: 1,
  resultsPerPage: 20,
};

// --------------------------------------------------
// Props
// --------------------------------------------------
PaginationBar.propTypes = {
  pagination: PropTypes.object.isRequired,
};

export default PaginationBar;
