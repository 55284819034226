import React from 'react';

// Libraries
import {PageContext} from '@supermove/analytics';
import {useActiveRoute, useTrackPageview} from '@supermove/hooks';
import {NavigationType} from '@supermove/navigation/src/NavigationTypes';

const RouterWrapper = ({
  navigation,
  children,
}: {
  navigation: NavigationType;
  children: React.ReactNode;
}) => {
  const {route} = useActiveRoute({navigation});
  const {routeName} = route || {};
  useTrackPageview({routeName: routeName || ''});
  return <PageContext.Provider value={{pageName: routeName}}>{children}</PageContext.Provider>;
};

export default RouterWrapper;
