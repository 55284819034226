// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {Storage} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const MobileBodyText = Styled.Text`
  ${Typography.Mobile.Body}
`;

const logOut = async () => {
  await Storage.clear();
  window.location.href = '/';
};

const SignOutModal = ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
  return (
    <SmallModal isOpen={isOpen} style={{alignItems: 'center'}}>
      <SmallModal.HeaderIcon source={Icon.ArrowRight} color={colors.blue.interactive} />
      <Space height={12} />
      <SmallModal.HeaderText style={{color: colors.blue.interactive}}>
        Sign out?
      </SmallModal.HeaderText>
      <Space height={12} />
      <MobileBodyText>
        You will be logged out of this account. You can log back in anytime.
      </MobileBodyText>
      <Space height={12} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={() => logOut()}>
          Sign Out
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default SignOutModal;
