// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {generated} from '@supermove/graphql';
import {
  useDrawer,
  useModal,
  useNavigation,
  usePopover,
  useResponsive,
  useToast,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import {DropdownActionType} from '@shared/design/components/DropdownInput/components/DropdownSheet';
import IconButton from '@shared/design/components/IconButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import {DashboardStatusType} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import useDuplicateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useDuplicateGlobalDashboardMutation';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import ArchiveDashboardModal from 'modules/Dashboards/components/ArchiveDashboardModal';
import PublishDashboardModal from 'modules/Dashboards/components/PublishDashboardModal';
import RestoreAsDraftDashboardModal from 'modules/Dashboards/components/RestoreAsDraftDashboardModal';
import RevertToDraftDashboardModal from 'modules/Dashboards/components/RevertToDraftDashboardModal';
import getGlobalDashboardStatusChangeActionsMap from 'modules/Dashboards/helpers/getGlobalDashboardStatusChangeActionsMap';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
`;

const TitleContainer = Styled.View`
  justify-content: center;
`;

const HeaderContainer = Styled.View`
`;

interface GlobalDashboardDetailsPageHeaderProps {
  globalDashboard: NonNullable<generated.GlobalDashboardDetailsPageQuery['globalDashboard']>;
  manageCompaniesDrawer: ReturnType<typeof useDrawer>;
  handleEdit: () => void;
  refetch: () => void;
}

const GlobalDashboardDetailsPageHeader = ({
  globalDashboard,
  manageCompaniesDrawer,
  handleEdit,
  refetch,
}: GlobalDashboardDetailsPageHeaderProps) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigation();
  const actionsPopover = usePopover();
  const publishGlobalDashboardModal = useModal({name: 'Publish Global Dashboard Modal'});
  const archiveGlobalDashboardModal = useModal({name: 'Archive Global Dashboard Modal'});
  const revertToDraftGlobalDashboardModal = useModal({
    name: 'Revert To Draft Global Dashboard Modal',
  });
  const restoreAsDraftGlobalDashboardModal = useModal({
    name: 'Restore As Draft Global Dashboard Modal',
  });

  const duplicateGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
    actionText: 'View',
    handleAction: ({
      uuid,
    }: NonNullable<generated.GlobalDashboardDetailsPageQuery['globalDashboard']>) => {
      navigator.navigate('GlobalDashboardDetailsPage', {globalDashboardUuid: uuid});
    },
  });
  const {handleSubmit: handleDuplicate} = useDuplicateGlobalDashboardMutation({
    duplicateGlobalDashboardForm: {globalDashboardId: globalDashboard.id},
    onSuccess: ({
      globalDashboard,
    }: {
      globalDashboard: NonNullable<generated.GlobalDashboardDetailsPageQuery['globalDashboard']>;
    }) => {
      duplicateGlobalDashboardSuccessToast.handleToast({
        message: `'${globalDashboard.name}' created!`,
        actionPayload: globalDashboard,
      });
    },
    onError: (errors) => console.log({errors}),
  });

  const statusChangeActionsMap = getGlobalDashboardStatusChangeActionsMap({
    handleDuplicate,
    handlePublish: publishGlobalDashboardModal.handleOpen,
    handleRestoreAsDraft: restoreAsDraftGlobalDashboardModal.handleOpen,
    handleRevertToDraft: revertToDraftGlobalDashboardModal.handleOpen,
    handleArchive: archiveGlobalDashboardModal.handleOpen,
  });

  const actions =
    globalDashboard.status === 'PUBLISHED'
      ? ([
          {
            label: 'Report Actions',
            actions: statusChangeActionsMap[globalDashboard.status],
          },
          {
            label: 'Management',
            actions: [
              {
                text: 'Manage companies',
                onPress: manageCompaniesDrawer.handleOpen,
              },
            ],
          },
        ] as DropdownActionType[])
      : (statusChangeActionsMap[
          globalDashboard.status as DashboardStatusType
        ] as DropdownActionType[]);

  return (
    <React.Fragment>
      <HeaderContainer>
        <AdminAppPage.HeaderContainer
          responsive={responsive}
          style={{flexDirection: responsive.desktop ? 'row' : 'column'}}
        >
          {responsive.mobile && (
            <TertiaryButton
              onPress={() => navigator.navigate('GlobalDashboardsPage')}
              style={{flexDirection: 'row', justifyContent: 'start', alignItems: 'center'}}
            >
              <Icon source={Icon.AngleLeft} size={16} color={colors.gray.secondary} />
              <AdminAppPage.PageHeadingText
                responsive={responsive}
                style={{color: colors.gray.secondary, fontSize: 14}}
              >
                Reports
              </AdminAppPage.PageHeadingText>
            </TertiaryButton>
          )}
          <HeaderRow style={{flex: responsive.desktop ? 1 : undefined}}>
            {responsive.desktop && (
              <TertiaryButton onPress={() => navigator.navigate('GlobalDashboardsPage')}>
                <AdminAppPage.PageHeadingText
                  responsive={responsive}
                  style={{color: colors.gray.tertiary}}
                >
                  {'Reports / '}
                </AdminAppPage.PageHeadingText>
              </TertiaryButton>
            )}
            <TitleContainer style={{flex: 1}}>
              <AdminAppPage.PageHeadingText
                responsive={responsive}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {globalDashboard.name}
              </AdminAppPage.PageHeadingText>
            </TitleContainer>
            <Space width={4} />
            <Row>
              <Button
                text={responsive.desktop ? 'Edit Report' : 'Edit'}
                iconLeft={Icon.Pen}
                onPress={handleEdit}
                isSmall
              />
              <Space width={16} />
              <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
                <IconButton
                  onPress={actionsPopover.handleToggle}
                  source={Icon.EllipsisV}
                  isSecondary
                />
              </ActionMenuPopover>
            </Row>
          </HeaderRow>
        </AdminAppPage.HeaderContainer>
      </HeaderContainer>
      <PublishDashboardModal
        isOpen={publishGlobalDashboardModal.isOpen}
        handleClose={publishGlobalDashboardModal.handleClose}
        refetch={refetch}
        globalDashboard={globalDashboard}
      />
      <ArchiveDashboardModal
        isOpen={archiveGlobalDashboardModal.isOpen}
        handleClose={archiveGlobalDashboardModal.handleClose}
        refetch={refetch}
        globalDashboard={globalDashboard}
      />
      <RevertToDraftDashboardModal
        isOpen={revertToDraftGlobalDashboardModal.isOpen}
        handleClose={revertToDraftGlobalDashboardModal.handleClose}
        refetch={refetch}
        globalDashboard={globalDashboard}
      />
      <RestoreAsDraftDashboardModal
        isOpen={restoreAsDraftGlobalDashboardModal.isOpen}
        handleClose={restoreAsDraftGlobalDashboardModal.handleClose}
        refetch={refetch}
        globalDashboard={globalDashboard}
      />
    </React.Fragment>
  );
};

export default GlobalDashboardDetailsPageHeader;
