// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, UrlFiltersType, useResponsive} from '@supermove/hooks';
import {Company} from '@supermove/models';

// App
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import Tabs from '@shared/design/components/Tabs';
import CompanyKind from '@shared/modules/Company/enums/CompanyKind';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';

type CountsType = {
  active: number;
  inactive: number;
  office: number;
  crew: number;
  sales: number;
  customer: number;
  internal: number;
  all: number;
};

const getGroupTabs = ({urlFilters, counts}: {urlFilters: UrlFiltersType; counts?: CountsType}) => {
  switch (urlFilters.get('groupBy')) {
    case Company.GROUP_BY.PRODUCT:
      return SupermoveProductKind.getDropdownOptions().map((option) => ({
        ...option,
        count: _.get(counts, _.toLower(option.value)) ?? <CountLoader />,
      }));
    case Company.GROUP_BY.STATUS:
      return [
        {
          label: 'Active',
          value: 'ACTIVE',
          count: counts?.active ?? <CountLoader />,
        },
        {
          label: 'Inactive',
          value: 'INACTIVE',
          count: counts?.inactive ?? <CountLoader />,
        },
      ];
    case Company.GROUP_BY.KIND:
    default:
      return CompanyKind.getDropdownOptions().map((option) => ({
        ...option,
        count: _.get(counts, _.toLower(option.value)) ?? <CountLoader />,
      }));
  }
};

const getTabs = ({urlFilters, counts}: {urlFilters: UrlFiltersType; counts?: CountsType}) => {
  return [
    {
      label: 'All',
      value: undefined,
      count: (urlFilters.get('groupBy') === Company.GROUP_BY.STATUS
        ? counts?.all
        : counts?.active) ?? <CountLoader />,
    },
    ...getGroupTabs({urlFilters, counts}),
  ];
};

const CountLoader = () => {
  const responsive = useResponsive();
  return <SkeletonLoader height={responsive.desktop ? 8 : 10} width={20} style={{marginTop: 4}} />;
};

const CompaniesSearchAndFilters = ({
  urlFilters,
  handleUpdateGroup,
  style,
}: {
  urlFilters: UrlFiltersType;
  handleUpdateGroup: (group?: string) => void;
  style?: React.CSSProperties;
}) => {
  const {data} = useQuery(CompaniesSearchAndFilters.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery: urlFilters.get('searchQuery'),
      companyKinds: urlFilters.get('companyKinds'),
      organizationKinds: urlFilters.get('organizationKinds'),
      productKinds: urlFilters.get('productKinds'),
      status: urlFilters.get('status'),
      numberOfLicensesMin: urlFilters.get('numberOfLicensesMin'),
      numberOfLicensesMax: urlFilters.get('numberOfLicensesMax'),
      numberOfTrucksMin: urlFilters.get('numberOfTrucksMin'),
      numberOfTrucksMax: urlFilters.get('numberOfTrucksMax'),
      createdAtMin: urlFilters.get('createdAtMin'),
      createdAtMax: urlFilters.get('createdAtMax'),
      groupBy: urlFilters.get('groupBy'),
    },
  });

  const tabs = getTabs({urlFilters, counts: data?.counts});

  return (
    <Tabs
      tabs={tabs}
      handlePressTab={({value}) => handleUpdateGroup(value)}
      activeTabIndex={_.findIndex(tabs, {
        value: (urlFilters.get('group') as string) || tabs[0].value,
      })}
      scrollViewStyle={style}
      tabStyle={{paddingLeft: 24, paddingRight: 24}}
      isSpacedTabs={false}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesSearchAndFilters.query = gql`
  query CompaniesSearchAndFilters(
    $searchQuery: String
    $companyKinds: [String]
    $organizationKinds: [String]
    $productKinds: [String]
    $status: String
    $numberOfLicensesMin: Int
    $numberOfLicensesMax: Int
    $numberOfTrucksMin: Int
    $numberOfTrucksMax: Int
    $createdAtMin: String
    $createdAtMax: String
    $groupBy: String
  ) {
    ${gql.query}
    counts: filteredCompaniesCountsByGroup(
      searchQuery: $searchQuery
      companyKinds: $companyKinds
      organizationKinds: $organizationKinds
      productKinds: $productKinds
      status: $status
      numberOfLicensesMin: $numberOfLicensesMin
      numberOfLicensesMax: $numberOfLicensesMax
      numberOfTrucksMin: $numberOfTrucksMin
      numberOfTrucksMax: $numberOfTrucksMax
      createdAtMin: $createdAtMin
      createdAtMax: $createdAtMax
      groupBy: $groupBy
    ) {
      customer
      internal
      office
      crew
      sales
      active
      inactive
      all
    }
  }
`;

export default CompaniesSearchAndFilters;
