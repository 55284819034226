// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {
  useDebouncedCallback,
  useMountEffect,
  usePopover,
  useResponsive,
  useState,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import MobileSearch from 'modules/App/components/MobileSearch';
import SearchBar from 'modules/App/components/SearchBar';
import {GlobalDashboardsPageUrlFilters} from 'modules/Dashboards/components/types';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderContainer = Styled.View`
`;

interface GlobalDashboardsPageHeaderProps {
  title: string;
  urlFilters: GlobalDashboardsPageUrlFilters;
  isMobileSearchVisible: boolean;
  setIsMobileSearchVisible: (isVisible: boolean | ((currentValue: boolean) => boolean)) => void;
  handleCreate: () => void;
  handleManageCategories: () => void;
  handleManageTags: () => void;
}

const GlobalDashboardsPageHeader = ({
  title,
  urlFilters,
  isMobileSearchVisible,
  setIsMobileSearchVisible,
  handleCreate,
  handleManageCategories,
  handleManageTags,
}: GlobalDashboardsPageHeaderProps) => {
  const responsive = useResponsive();
  const actionsPopover = usePopover();
  const toggleMobileSearch = () =>
    setIsMobileSearchVisible((currentValue: boolean) => !currentValue);

  const [query, setQuery] = useState(urlFilters.get('searchQuery'));

  useMountEffect(() => {
    setQuery(urlFilters.get('searchQuery') || '');
  });

  const debouncedFilterUpdate = useDebouncedCallback(urlFilters.handleUpdate, 1000);
  const handleSearch = (searchQuery: string) => {
    setQuery(searchQuery);
    debouncedFilterUpdate({searchQuery});
  };

  return (
    <HeaderContainer>
      <AdminAppPage.HeaderContainer responsive={responsive}>
        <AdminAppPage.PageHeadingText style={{alignContent: 'center'}} responsive={responsive}>
          {title}
        </AdminAppPage.PageHeadingText>
        <Space style={{flex: 1}} />
        <Row>
          {responsive.desktop ? (
            <SearchBar
              placeholder='Search reports by name, description, or tags'
              desktopWidth={282}
              isClearable
              searchQuery={query}
              setSearchQuery={(query: string) => handleSearch(query)}
            />
          ) : (
            <TertiaryButton onPress={toggleMobileSearch}>
              <Icon source={Icon.MagnifyingGlass} size={16} color={colors.gray.secondary} />
            </TertiaryButton>
          )}
          <Space width={16} />
          <Button
            text={responsive.desktop ? 'Add Report' : 'Add'}
            iconLeft={Icon.Plus}
            onPress={handleCreate}
          />
          <Space width={16} />
          <ActionMenuPopover
            popover={actionsPopover}
            width={200}
            actions={[
              {
                text: 'Manage categories',
                onPress: handleManageCategories,
              },
              {
                text: 'Manage tags',
                onPress: handleManageTags,
              },
            ]}
          >
            <IconButton onPress={actionsPopover.handleToggle} source={Icon.EllipsisV} isSecondary />
          </ActionMenuPopover>
        </Row>
      </AdminAppPage.HeaderContainer>
      <MobileSearch
        searchQuery={query}
        setSearchQuery={(query: string) => handleSearch(query)}
        isVisible={isMobileSearchVisible}
        handleClose={() => setIsMobileSearchVisible(false)}
      />
    </HeaderContainer>
  );
};

export default GlobalDashboardsPageHeader;
