// Need to use require here to avoid SSR issues

// Libraries
import {useEditor, EditorContent} from '@tiptap/react';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useRef, useEffect, useState} from '@supermove/hooks';
import {ExecutionEnvironment} from '@supermove/sdk';
import {colors} from '@supermove/styles';

import './RichTextInput.css';

import {getFormattingEditorExtensions} from '../utils/getEditorExtensions';

import RichTextEditorToolbar from './Toolbar';

const Container = Styled.View`
    z-index: 1;
    border: 1px solid ${colors.gray.border};
    background-color: ${colors.white};
    box-sizing: border-box;
`;

// Can't use the typical Line component, as it's in shared
const Line = Styled.View`
    flex: 1;
    border-bottom-width: 1px;
    border-color: ${colors.gray.border};
`;

const EditorContainer = Styled.View`
    overflow: auto;    
`;

// Needed to get the flex behavior to properly have the toolbar take the space it needs
// While letting the rich text editor expand to fill the rest of the space
const ToolbarContainer = Styled.View`
`;

interface BaseRichTextEditorProps {
  placeholder?: string;
  value?: string;
  hasAdvancedFormatting?: boolean;
  style?: React.CSSProperties;
  editorStyle?: React.CSSProperties;
}

interface FieldRichTextEditorProps extends BaseRichTextEditorProps {
  name: string;
  setFieldValue: (name: string, value?: string) => void;
  onChange?: never;
}

interface NoFieldRichTextEditorProps extends BaseRichTextEditorProps {
  onChange: (value: string) => void;
  name?: never;
  setFieldValue?: never;
}

export type RichTextEditorProps = FieldRichTextEditorProps | NoFieldRichTextEditorProps;

type RichTextEditorContentProps = Omit<RichTextEditorProps, 'name' | 'setFieldValue'> & {
  onChange: (value: string) => void;
};

const RichTextContent = ({
  placeholder,
  value,
  onChange,
  hasAdvancedFormatting,
  style,
  editorStyle,
}: RichTextEditorContentProps) => {
  const editor = useEditor(
    {
      extensions: getFormattingEditorExtensions(placeholder),
      content: value,
      onUpdate: ({editor}: {editor: {getHTML: () => string}}) => {
        onChange(editor.getHTML());
      },
    },
    [value],
  );

  if (!editor) {
    return null;
  }

  return (
    <Container style={style}>
      <ToolbarContainer>
        <RichTextEditorToolbar editor={editor} hasAdvancedFormatting={hasAdvancedFormatting} />
        <Line />
      </ToolbarContainer>
      <EditorContainer>
        <EditorContent editor={editor} style={{padding: 16, minHeight: 140, ...editorStyle}} />
      </EditorContainer>
    </Container>
  );
};

const RichTextEditor = ({value, setFieldValue, onChange, name, ...props}: RichTextEditorProps) => {
  // Rich text editor is not a controlled component, it takes in the initial value and then tracks internally (useRef)
  // It will re-render based on the dependency array in the useEditor hook
  // But when that happens, the cursor loses focus, so we don't want that happening on every update
  // So we track the current value the editor has in the ref contentValue,
  // updating the parent at the same time (setFieldValueWrapper)
  // So value from props and contentValue should normally be the same
  // If they're different, it means the parent has programmatically changed the value
  // and we should update the editor with the new value (useEffect)
  const contentValue = useRef(value);
  const [parentValue, setParentValue] = useState(value);

  const setFieldValueWrapper = (value: string) => {
    // This keeps track of the value as it is initial set and changes through the editor
    contentValue.current = value;
    if (setFieldValue) {
      setFieldValue(name, value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  useEffect(() => {
    if (value !== contentValue.current) {
      // If here, the parent component has programmatically changed the value
      // and we should re-render the editor with the new value
      contentValue.current = value;
      setParentValue(value);
    }
  }, [value, contentValue, setParentValue]);

  if (!ExecutionEnvironment.canUseDOM) {
    return null;
  }

  return <RichTextContent {...props} value={parentValue} onChange={setFieldValueWrapper} />;
};

export default RichTextEditor;
