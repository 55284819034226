// Supermove
import {gql} from '@supermove/graphql';
import {SettingsModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// Shared
import {ProjectStatusType} from '@shared/modules/Project/enums/ProjectStatus';
import {CalendarKindType} from '@shared/modules/Settings/enums/CalendarKind';
import {CrewVisibleJobsForMoverType} from '@shared/modules/Settings/enums/CrewVisibleJobsForMover';

export interface SettingsFormType {
  settingsId: string;
  updatedById?: string;
  isCrewChargeSavedCardEnabled: boolean;
  primaryCalendar: CalendarKindType;
  visibleCalendarStatuses: ProjectStatusType[];
  crewVisibleJobsByProjectStatus: ProjectStatusType[];
  crewVisibleJobsForMover: CrewVisibleJobsForMoverType;
  aiCallActionItemsPromptExcludeList: string[];
  aiCallSummaryPromptExcludeList: string[];
  aiCallSummaryPromptIncludeList: string[];
  aiSalesCopilotVoiceAgentsEnabled: boolean;
}

const edit = withFragment(
  (settings: SettingsModel, {updatedById}: {updatedById?: string} = {}): SettingsFormType => ({
    settingsId: settings.id,
    updatedById,
    isCrewChargeSavedCardEnabled: settings.isCrewChargeSavedCardEnabled,
    primaryCalendar: settings.primaryCalendar,
    visibleCalendarStatuses: settings.visibleCalendarStatuses,
    crewVisibleJobsByProjectStatus: settings.crewVisibleJobsByProjectStatus,
    crewVisibleJobsForMover: settings.crewVisibleJobsForMover,
    aiCallActionItemsPromptExcludeList: settings.aiCallActionItemsPromptExcludeList,
    aiCallSummaryPromptExcludeList: settings.aiCallSummaryPromptExcludeList,
    aiCallSummaryPromptIncludeList: settings.aiCallSummaryPromptIncludeList,
    aiSalesCopilotVoiceAgentsEnabled: settings.aiSalesCopilotVoiceAgentsEnabled,
  }),
  gql`
    fragment SettingsForm_edit on Settings {
      id
      isCrewChargeSavedCardEnabled
      primaryCalendar
      visibleCalendarStatuses
      crewVisibleJobsByProjectStatus
      crewVisibleJobsForMover
      aiCallActionItemsPromptExcludeList
      aiCallSummaryPromptExcludeList
      aiCallSummaryPromptIncludeList
      aiSalesCopilotVoiceAgentsEnabled
    }
  `,
);

const toForm = ({
  settingsId,
  updatedById,
  isCrewChargeSavedCardEnabled,
  primaryCalendar,
  visibleCalendarStatuses,
  crewVisibleJobsByProjectStatus,
  crewVisibleJobsForMover,
  aiCallActionItemsPromptExcludeList,
  aiCallSummaryPromptExcludeList,
  aiCallSummaryPromptIncludeList,
  aiSalesCopilotVoiceAgentsEnabled,
}: SettingsFormType) => ({
  settingsId,
  updatedById,
  isCrewChargeSavedCardEnabled,
  primaryCalendar,
  visibleCalendarStatuses,
  crewVisibleJobsByProjectStatus,
  crewVisibleJobsForMover,
  aiCallActionItemsPromptExcludeList,
  aiCallSummaryPromptExcludeList,
  aiCallSummaryPromptIncludeList,
  aiSalesCopilotVoiceAgentsEnabled,
});

export type SettingsFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  settingsId,
  updatedById,
  isCrewChargeSavedCardEnabled,
  primaryCalendar,
  visibleCalendarStatuses,
  crewVisibleJobsByProjectStatus,
  crewVisibleJobsForMover,
  aiCallActionItemsPromptExcludeList,
  aiCallSummaryPromptExcludeList,
  aiCallSummaryPromptIncludeList,
  aiSalesCopilotVoiceAgentsEnabled,
}: SettingsFormToFormType) => ({
  settingsId,
  updatedById,
  isCrewChargeSavedCardEnabled,
  primaryCalendar,
  visibleCalendarStatuses,
  crewVisibleJobsByProjectStatus,
  crewVisibleJobsForMover,
  // Filter out any empty strings from the list
  aiCallActionItemsPromptExcludeList: aiCallActionItemsPromptExcludeList.filter(Boolean),
  aiCallSummaryPromptExcludeList: aiCallSummaryPromptExcludeList.filter(Boolean),
  aiCallSummaryPromptIncludeList: aiCallSummaryPromptIncludeList.filter(Boolean),
  aiSalesCopilotVoiceAgentsEnabled,
});

const SettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default SettingsForm;
