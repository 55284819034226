// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Phone, withFragment, Datetime} from '@supermove/utils';

// App
import CallDirection from '@shared/modules/Call/enums/CallDirection';
import CallStatus from '@shared/modules/Call/enums/CallStatus';

const getLeadDisplayName = withFragment(
  (call) => {
    return (call as any)?.lead?.client?.primaryContact?.fullName;
  },
  gql`
    fragment Call_getLeadDisplayName on Call {
      id
      lead {
        id
        client {
          id
          primaryContact {
            id
            fullName
          }
        }
      }
    }
  `,
);

const getDisplayPhoneNumber = withFragment(
  (call: any) => {
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return Phone.display(callerPhoneNumber);
    } else {
      return Phone.display(calleePhoneNumber);
    }
  },
  gql`
    fragment Call_getDisplayPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getDisplaySalespersonPhoneNumber = withFragment(
  (call: any) => {
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return Phone.display(calleePhoneNumber);
    } else {
      return Phone.display(callerPhoneNumber);
    }
  },
  gql`
    fragment Call_getDisplaySalespersonPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getPhoneNumber = withFragment(
  (call) => {
    // @ts-expect-error TS(2339): Property 'calleePhoneNumber' does not exist on typ... Remove this comment to see the full error message
    const {calleePhoneNumber, callerPhoneNumber, direction} = call;
    if (direction === CallDirection.INBOUND) {
      return callerPhoneNumber;
    } else {
      return calleePhoneNumber;
    }
  },
  gql`
    fragment Call_getPhoneNumber on Call {
      id
      direction
      calleePhoneNumber
      callerPhoneNumber
    }
  `,
);

const getCallBadge = withFragment(
  (call) => {
    switch (true) {
      case (call as any).status === CallStatus.CANCELLED:
        return {
          icon: Icon.PhoneSlash,
          color: colors.orange.status,
          text: 'Cancelled',
        };
      case (call as any).direction === CallDirection.OUTBOUND:
        return {
          icon: Icon.PhoneOutgoing,
          color: colors.blue.interactive,
          text: 'Outgoing',
        };
      case (call as any).status === CallStatus.MISSED:
        return {
          icon: Icon.PhoneMissed,
          color: colors.red.warning,
          text: 'Missed',
        };
      default:
        return {
          icon: Icon.PhoneIncoming,
          color: colors.green.status,
          text: 'Incoming',
        };
    }
  },
  gql`
    fragment Call_getCallBadge on Call {
      id
      direction
      status
    }
  `,
);

const getDisplayLocation = withFragment(
  (call) => {
    // @ts-expect-error TS(2339): Property 'calleeCity' does not exist on type 'unkn... Remove this comment to see the full error message
    const {calleeCity, calleeState, callerCity, callerState, direction} = call;
    if (direction === CallDirection.INBOUND) {
      if (!callerCity || !callerState) {
        return '';
      }
      return `${callerCity}, ${callerState}`;
    } else {
      if (!calleeCity || !calleeState) {
        return '';
      }
      return `${calleeCity}, ${calleeState}`;
    }
  },
  gql`
    fragment Call_getDisplayLocation on Call {
      id
      direction
      calleeCity
      calleeState
      callerCity
      callerState
    }
  `,
);

const getDisplayTime = withFragment(
  (call) => {
    // Fall back to createdAt if endTime is not available
    const endTime = (call as any).endTime || (call as any).createdAt;
    switch ((call as any).status) {
      case CallStatus.MISSED:
      case CallStatus.COMPLETE:
      case CallStatus.ERROR:
      case CallStatus.CANCELLED:
        return Datetime.toDisplayTime(Datetime.fromDatetime(endTime));
      case CallStatus.OUTGOING_RINGING_TO_NUMBER:
      case CallStatus.OUTGOING_RINGING_FROM_NUMBER:
      case CallStatus.IN_PROGRESS:
        return 'In Progress';
      default:
        if ((call as any).endTime) {
          return Datetime.toDisplayTime(Datetime.fromDatetime(endTime));
        }
        return;
    }
  },
  gql`
    fragment Call_getDisplayTime on Call {
      id
      endTime
      createdAt
      status
    }
  `,
);

const getDisplayTimeWithDay = withFragment(
  (call) => {
    // Fall back to createdAt if endTime is not available
    const startTime = (call as any).startTime || (call as any).createdAt;

    const startTimeMoment = Datetime.fromDatetime(startTime); // Converts to a Moment-like object
    const {now} = Datetime; // Gets the current time
    const oneWeekAgo = now.clone().subtract(7, 'days'); // Defines the "last week" range

    if (Datetime.isSameDay(startTimeMoment, now)) {
      // Same day: Display "Today HH:mm"
      return `Today ${Datetime.toDisplayTime(startTimeMoment)}`;
    } else if (Datetime.isYesterday(startTimeMoment)) {
      // Yesterday: Display "Yesterday HH:mm"
      return `Yesterday ${Datetime.toDisplayTime(startTimeMoment)}`;
    } else if (startTimeMoment.isAfter(oneWeekAgo)) {
      // Within last 7 days: Display "Day HH:mm" (e.g., "Saturday 6:45pm")
      return `${startTimeMoment.format('dddd')} ${Datetime.toDisplayTime(startTimeMoment)}`;
    } else {
      // Older than 7 days: Display "MM/DD/YY HH:mm"
      return Datetime.toDisplayDatetime(startTimeMoment);
    }
  },
  gql`
    fragment Call_getDisplayTimeWithDay on Call {
      id
      startTime
      createdAt
      status
    }
  `,
);

const getDisplayDate = withFragment(
  (call, format) => {
    const endTime = (call as any).endTime || (call as any).createdAt;
    return Datetime.toDisplayDate(Datetime.fromDatetime(endTime), format);
  },
  gql`
    fragment Call_getDisplayDate on Call {
      id
      endTime
      createdAt
    }
  `,
);

const getElapsedTime = withFragment(
  (call) => {
    const startTime = (call as any).startTime || (call as any).createdAt;
    return Datetime.getElapsedSeconds(Datetime.fromDatetime(startTime));
  },
  gql`
    fragment Call_getElapsedTime on Call {
      id
      startTime
      createdAt
    }
  `,
);

const Call = {
  getDisplayPhoneNumber,
  getDisplaySalespersonPhoneNumber,
  getPhoneNumber,
  getCallBadge,
  getDisplayTime,
  getDisplayTimeWithDay,
  getDisplayLocation,
  getLeadDisplayName,
  getDisplayDate,
  getElapsedTime,
};

export default Call;
