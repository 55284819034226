// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  height: 100%;
`;

const Panel = ({
  title,
  BodyComponent,
  handleEdit,
}: {
  title: string;
  BodyComponent: React.ComponentType;
  handleEdit: () => void;
}) => {
  return (
    <ActionPanel
      title={title}
      ActionButtonComponent={() => (
        <EditPanel.EditButton handleEdit={handleEdit} isEditing={false} isDisabled={false} />
      )}
      BodyComponent={BodyComponent}
    />
  );
};

const CompanyDetailsPanel = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.COMPANY_DETAILS)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.COMPANY_DETAILS)}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <FieldValue
              label={'Company Name'}
              value={_.get(form.values, `${field}.name`)}
              isResponsive
            />
            <Space height={16} />
            <FieldValue
              label={'Identifier'}
              value={_.get(form.values, `${field}.identifier`)}
              isResponsive
            />
          </React.Fragment>
        );
      }}
    />
  );
};

const ProductsOverviewPanel = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`, []);
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.PRODUCTS.OVERVIEW)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.PRODUCTS.OVERVIEW)}
      BodyComponent={() => {
        return (
          <Row style={{flexWrap: 'wrap'}}>
            {supermoveProductForms.map(({kind}: {kind: string}, index: number) => (
              <Row key={index}>
                <Badge
                  isResponsive
                  label={SupermoveProductKind.getLabel(kind)}
                  color={SupermoveProductKind.getColor(kind)}
                />
                <Space width={8} />
              </Row>
            ))}
          </Row>
        );
      }}
    />
  );
};

const SalesProductPanel = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const supermoveProductForms = _.get(form.values, `${field}.supermoveProductForms`, []);
  const salesProductForm = _.find(supermoveProductForms, {kind: SupermoveProductKind.SALES});
  return (
    <Panel
      title={CreateCompanyStepKinds.getStepTitle(CreateCompanyStepKinds.PRODUCTS.SALES)}
      handleEdit={() => setCurrentStepKind(CreateCompanyStepKinds.PRODUCTS.SALES)}
      BodyComponent={() => {
        return (
          <React.Fragment>
            <FieldValue
              label={'Number of Licenses'}
              value={salesProductForm?.numberOfLicenses}
              empty={'N/A'}
              isResponsive
            />
          </React.Fragment>
        );
      }}
    />
  );
};

const CreateCompanyReviewStep = ({
  form,
  field,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const responsive = useResponsive();
  return (
    <Container style={responsive.desktop ? {} : {backgroundColor: colors.white}}>
      <CompanyDetailsPanel form={form} field={field} setCurrentStepKind={setCurrentStepKind} />
      {responsive.desktop && <Space height={16} />}
      <ProductsOverviewPanel form={form} field={field} setCurrentStepKind={setCurrentStepKind} />
      {responsive.desktop && <Space height={16} />}
      <SalesProductPanel form={form} field={field} setCurrentStepKind={setCurrentStepKind} />
    </Container>
  );
};

export default CreateCompanyReviewStep;
