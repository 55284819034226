// Supermove
import {gql} from '@supermove/graphql';
import {UserFlowStepModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getUserFlowStep = withFragment(
  (userFlowStep: UserFlowStepModel) => {
    return userFlowStep;
  },
  gql`
    fragment UserFlowStep_getUserFlowStep on UserFlowStep {
      id
      buttonText
      isDefault
      index
      documentTemplate {
        id
        name
      }
    }
  `,
);

const UserFlowStep = {
  getUserFlowStep,
};

export default UserFlowStep;
