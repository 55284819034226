// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {PaginationType, UrlFiltersType} from '@supermove/hooks';
import {Company, CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';
import CompaniesGroupTabs from 'modules/Company/List/components/CompaniesGroupTabs';
import CompaniesSearchAndFilters from 'modules/Company/List/components/CompaniesSearchAndFilters';
import CompaniesTable from 'modules/Company/List/components/CompaniesTable';

const TabsContainer = Styled.View`
  background-color: ${colors.white};
`;

const SearchAndFiltersContainer = Styled.View`
  padding-horizontal: 16px;
`;

const CompaniesPageContentMobile = ({
  urlFilters,
  companies,
  isLoading,
  handleUpdateGroupBy,
  handleUpdateGroup,
  pagination,
  refetch,
}: {
  urlFilters: UrlFiltersType;
  companies: CompanyModel[];
  isLoading: boolean;
  handleUpdateGroupBy: (groupBy?: string) => void;
  handleUpdateGroup: (group?: string) => void;
  pagination: PaginationType;
  refetch: () => void;
}) => {
  return (
    <React.Fragment>
      <TabsContainer>
        <Space height={8} />
        <FullWidthTabs
          tabs={Company.GROUP_BY_DEFINITIONS.map(({label, value}) => ({
            label: label.replace('By ', ''),
            value,
          }))}
          handlePressTab={({value}) => handleUpdateGroupBy(value)}
          activeTabIndex={_.findIndex(Company.GROUP_BY_DEFINITIONS, {
            value: (urlFilters.get('groupBy') as string) || Company.GROUP_BY_DEFINITIONS[0].value,
          })}
        />
      </TabsContainer>
      <Line />
      <Space height={16} />
      <SearchAndFiltersContainer>
        <CompaniesSearchAndFilters urlFilters={urlFilters} />
      </SearchAndFiltersContainer>
      <Space height={8} />
      <CompaniesGroupTabs
        urlFilters={urlFilters}
        handleUpdateGroup={handleUpdateGroup}
        style={{paddingLeft: 16, paddingRight: 16}}
      />
      <Line />
      <CompaniesTable companies={companies} isLoading={isLoading} refetch={refetch} />
      <Line />
      <Space height={16} />
      <PaginationBar pagination={pagination} />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesPageContentMobile.fragment = gql`
  ${CompaniesTable.fragment}

  fragment CompaniesPageContentMobile on Company {
    id
    ...CompaniesTable
  }
`;

export default CompaniesPageContentMobile;
