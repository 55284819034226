// Supermove
import {gql} from '@supermove/graphql';
import {UserFlowModel, UserFlowStep} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getUserFlowWithUserFlowSteps = withFragment(
  (userFlow: UserFlowModel) => {
    return userFlow;
  },
  gql`
    ${UserFlowStep.getUserFlowStep.fragment}
    fragment UserFlow_getUserFlowWithUserFlowRunSteps on UserFlow {
      id
      expirationUnitType
      expirationValue
      shouldIncludePdfs
      defaultEmailTemplate {
        id
        name
      }
      userFlowSteps {
        id
        ...UserFlowStep_getUserFlowStep
      }
    }
  `,
);

const UserFlow = {
  getUserFlowWithUserFlowSteps,
};

export default UserFlow;
