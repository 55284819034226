// Libraries
import React from 'react';

// Supermove
import {PreventPropagation, Space, Icon, Popover, Styled} from '@supermove/components';
import {useResponsive, PopoverType} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Button from '@shared/design/components/Button/index';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';

const PopoverContainer = Styled.View`
  width: 400px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  padding: 24px;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FilterCount = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const ActionButtonContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: -1;
`;

const ClearFilter = ({handleClear}: {handleClear: () => void}) => {
  const responsive = useResponsive();

  return (
    <TertiaryButton
      onPress={handleClear}
      iconLeft={Icon.Trash}
      text={`Clear${responsive.desktop ? ' Filters' : ''}`}
      isResponsive
    />
  );
};

const PopoverContent = ({
  activeFiltersCount,
  handleClear,
  handleApply,
  popover,
  children,
}: PopoverFilterWithFooterProps) => {
  const responsive = useResponsive();

  return (
    <PopoverContainer>
      <Header>
        <FilterCount responsive={responsive}>{`Filters (${activeFiltersCount})`}</FilterCount>
        <Space flex={1} />
        <ClearFilter handleClear={handleClear} />
      </Header>
      <Space height={12} />
      {children}
      <Footer handleCancel={popover.handleClose} handleApply={handleApply} />
    </PopoverContainer>
  );
};

const Footer = ({
  handleCancel,
  handleApply,
}: {
  handleCancel: () => void;
  handleApply: () => void;
}) => {
  const {desktop: isDesktop} = useResponsive();

  return (
    <ActionButtonContainer style={{justifyContent: isDesktop ? 'flex-end' : 'space-around'}}>
      <QuaternaryButton
        onPress={handleCancel}
        text='Cancel'
        textColor={colors.blue.interactive}
        isWidthOfContainer={!isDesktop}
        style={isDesktop ? undefined : {flex: 1}}
        isResponsive
      />
      <Space width={16} />
      <Button
        onPress={handleApply}
        text='Apply'
        iconLeft={Icon.Check}
        isWidthOfContainer={!isDesktop}
        style={isDesktop ? undefined : {flex: 1}}
        isResponsive
      />
    </ActionButtonContainer>
  );
};

interface PopoverFilterWithFooterProps {
  activeFiltersCount: number;
  handleClear: () => void;
  handleApply: () => void;
  popover: PopoverType;
  children: React.ReactNode;
}
const PopoverFilterWithFooter = ({
  activeFiltersCount,
  handleClear,
  handleApply,
  popover,
  children,
}: PopoverFilterWithFooterProps) => {
  const responsive = useResponsive();

  return responsive.desktop ? (
    <Popover
      key={popover.key}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
      placement={Popover.Positions.BottomStart}
    >
      <PopoverContent
        activeFiltersCount={activeFiltersCount}
        handleClear={handleClear}
        handleApply={handleApply}
        popover={popover}
      >
        {children}
      </PopoverContent>
    </Popover>
  ) : (
    <PreventPropagation>
      <SheetWithFooter
        isOpen={popover.isOpen}
        handleClose={popover.handleClose}
        headerText={`Filters (${activeFiltersCount || 0})`}
        HeaderRightComponent={() => <ClearFilter handleClear={handleClear} />}
        secondaryActionText={'Cancel'}
        primaryActionText={'Apply'}
        handleSecondaryAction={popover.handleClose}
        handlePrimaryAction={handleApply}
        isFixedFooter
      >
        {children}
      </SheetWithFooter>
    </PreventPropagation>
  );
};

export default PopoverFilterWithFooter;
