// Supermove
import {gqlTyped as gql} from '@supermove/graphql';
import {MutationError, useForm, useTypedFormMutation as useFormMutation} from '@supermove/hooks';

// App
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';

const useCreateGlobalDashboardMutation = ({
  globalDashboardForm,
  onSuccess,
  onError,
}: {
  globalDashboardForm: ReturnType<typeof GlobalDashboardForm.new | typeof GlobalDashboardForm.edit>;
  onSuccess: (response: any) => void;
  onError: (error: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      globalDashboardForm: GlobalDashboardForm.toForm(globalDashboardForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateGlobalDashboardMutation.mutation,
    variables: {
      globalDashboardForm: GlobalDashboardForm.toMutation(form.values.globalDashboardForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateGlobalDashboardMutation.mutation = gql(`
  mutation useCreateGlobalDashboardMutation($globalDashboardForm: GlobalDashboardForm!) {
    response: createGlobalDashboard(globalDashboardForm: $globalDashboardForm) {
      errors {
        field
        message
      }
      globalDashboard {
        id
        uuid
        name
      }
    }
  }
`);

export default useCreateGlobalDashboardMutation;
