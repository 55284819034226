// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const PageHeaderContainer = Styled.View<{responsive: ResponsiveType}>`
  z-index: 100;
  align-items: flex-start;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const TitleRow = Styled.View`
  width: 100%;
  flex-direction: row;
`;

const PageTitle = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

interface CompanyPageContentHeaderProps {
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
}

const CompanyPageContentHeader = ({
  pageTitle,
  pageDescription,
  actionElement,
}: CompanyPageContentHeaderProps) => {
  const responsive = useResponsive();
  return (
    <PageHeaderContainer responsive={responsive}>
      <TitleRow>
        <PageTitle responsive={responsive}>{pageTitle}</PageTitle>
        <Space style={{flex: 1}} />
        {actionElement}
      </TitleRow>
      <Space height={8} />
      <PageDescription responsive={responsive}>{pageDescription}</PageDescription>
    </PageHeaderContainer>
  );
};

export default CompanyPageContentHeader;
