// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import CompanyForm, {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';

const PrimaryActionButton = ({
  canSubmitStep,
  nextStepKind,
  currentStepKind,
  form,
  setCurrentStepKind,
  handleSubmit,
  submitting,
  setCompletedSteps,
  isWidthOfContainer,
}: {
  canSubmitStep: boolean;
  nextStepKind: string | null;
  currentStepKind: string;
  form: Form<{companyForm: CompanyFormProps}>;
  setCurrentStepKind: (value: string | ((prevVar: string) => string)) => void;
  handleSubmit: () => void;
  submitting: boolean;
  setCompletedSteps: (value: string[] | ((prevVar: string[]) => string[])) => void;
  isWidthOfContainer: boolean;
}) => {
  return (
    <Button
      text={
        canSubmitStep
          ? 'Submit'
          : nextStepKind
            ? `Next: ${CreateCompanyStepKinds.getNextStepButtonText(nextStepKind)}`
            : 'Next'
      }
      onPress={async () => {
        const errors = await CompanyForm.validateStep({stepKind: currentStepKind, form});

        if (!_.isEmpty(errors)) {
          return;
        }

        // If the current step is a product step, we mark the setup as complete
        CreateCompanyStepKinds.completeProductSetup({stepKind: currentStepKind, form});

        setCompletedSteps((prevItems: string[]) => _.union(prevItems, [currentStepKind]));

        if (canSubmitStep) {
          handleSubmit();
        } else {
          nextStepKind && setCurrentStepKind(nextStepKind);
        }
      }}
      isSubmitting={submitting}
      iconLeft={canSubmitStep ? Icon.Check : null}
      iconRight={canSubmitStep ? null : Icon.ArrowRight}
      iconSize={12}
      isWidthOfContainer={isWidthOfContainer}
      style={{width: isWidthOfContainer ? '100%' : 248}}
      isResponsive
    />
  );
};

const CreateCompanyModalFooter = ({
  isViewingFirstStep,
  previousStepKind,
  nextStepKind,
  currentStepKind,
  setCurrentStepKind,
  setCompletedSteps,
  form,
  isReviewingSteps,
  handleSubmit,
  submitting,
}: {
  isViewingFirstStep: boolean;
  previousStepKind: string | null;
  nextStepKind: string | null;
  currentStepKind: string;
  setCurrentStepKind: (value: string | ((prevVar: string) => string)) => void;
  setCompletedSteps: (value: string[] | ((prevVar: string[]) => string[])) => void;
  form: Form<{companyForm: CompanyFormProps}>;
  isReviewingSteps: boolean;
  handleSubmit: () => void;
  submitting: boolean;
}) => {
  const responsive = useResponsive();
  const canSubmitStep = currentStepKind === CreateCompanyStepKinds.REVIEW;
  return (
    <React.Fragment>
      {responsive.desktop && (
        <React.Fragment>
          {!isViewingFirstStep && (
            <SecondaryButton
              text={'Back'}
              onPress={() => {
                previousStepKind && setCurrentStepKind(previousStepKind);
              }}
              iconLeft={Icon.ArrowLeft}
              iconSize={12}
            />
          )}
          <Space style={{flex: 1}} />
        </React.Fragment>
      )}
      {isReviewingSteps && currentStepKind !== CreateCompanyStepKinds.REVIEW ? (
        <Button
          text={'Return to Review'}
          onPress={async () => {
            const errors = await CompanyForm.validateStep({stepKind: currentStepKind, form});

            if (!_.isEmpty(errors)) {
              return;
            }

            setCurrentStepKind(CreateCompanyStepKinds.REVIEW);
          }}
          iconRight={Icon.ArrowRight}
          iconSize={12}
          isWidthOfContainer={!responsive.desktop}
          style={responsive.desktop ? {} : {width: '100%'}}
          isResponsive
        />
      ) : (
        <PrimaryActionButton
          canSubmitStep={canSubmitStep}
          nextStepKind={nextStepKind}
          currentStepKind={currentStepKind}
          form={form}
          setCurrentStepKind={setCurrentStepKind}
          handleSubmit={handleSubmit}
          submitting={submitting}
          setCompletedSteps={setCompletedSteps}
          isWidthOfContainer={!responsive.desktop}
        />
      )}
    </React.Fragment>
  );
};

export default CreateCompanyModalFooter;
