// Libraries
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import BetaBadge from '@shared/design/components/BetaBadge';

const Container = Styled.View`
`;

const EnclosedTabsItemsContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 5px;
  padding: 4px;
`;

const TabUnderline = Styled.View`
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: ${colors.blue.interactive};
`;

const TabSeparator = Styled.View`
  height: ${({isSecondary}: any) => (isSecondary ? '20px' : '24px')};
  width: 1px;
  background-color: ${colors.gray.tertiary};
`;

const Text = Styled.Text`
  ${({vars}: any) => (vars.isSecondary ? Typography.Label2 : Typography.Label1)}
  color: ${({vars}: any) => (vars.isActive ? colors.blue.interactive : colors.gray.tertiary)};
`;

const TabButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

const TabCountContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  border-radius: 56px;
  background-color: ${({isActive}: any) =>
    isActive ? colors.blue.interactive : colors.gray.tertiary};
`;

const TabCountText = Styled.Text`
  ${({vars}: any) => (vars.isSecondary ? Typography.Label4 : Typography.MicroLabel)}
  color: ${colors.white};
`;

export interface TabType {
  label: string;
  count?: number;
  icon?: string;
  colorOverride?: string;
  isBeta?: boolean;
  prependSeparator?: boolean;
}

const TabText = ({isActive, isSecondary, style, children}: any) => {
  return (
    <Text style={style} vars={{isActive, isSecondary}}>
      {children}
    </Text>
  );
};

const TabCount = ({tab, isActive, isSecondary, style}: any) => {
  return (
    <TabCountContainer isActive={isActive} style={style}>
      <TabCountText vars={{isSecondary}}>{tab.count}</TabCountText>
    </TabCountContainer>
  );
};

const TabContent = ({tab, isActive, isSecondary, isEnclosed, children}: any) => {
  return (
    <React.Fragment>
      {tab.icon && (
        <React.Fragment>
          <Icon
            source={tab.icon}
            size={16}
            color={
              tab.colorOverride
                ? tab.colorOverride
                : isActive
                  ? colors.blue.interactive
                  : colors.gray.tertiary
            }
          />
          <Space width={8} />
        </React.Fragment>
      )}
      {/* @ts-expect-error TS(2322): Type '{ children: any; isActive: any; isSecondary:... Remove this comment to see the full error message */}
      <TabText
        isActive={isActive}
        isSecondary={isSecondary}
        style={tab.colorOverride ? {color: tab.colorOverride} : {}}
      >
        {tab.label}
      </TabText>
      {!_.isNil(tab.count) && (
        <React.Fragment>
          <Space width={8} />
          <TabCount
            tab={tab}
            isActive={isActive}
            isSecondary={isSecondary}
            // @ts-expect-error TS(2322): Type '{ tab: any; isActive: any; isSecondary: any;... Remove this comment to see the full error message
            style={tab.colorOverride ? {backgroundColor: tab.colorOverride} : {}}
          />
        </React.Fragment>
      )}
      {children}
      {tab.isBeta && (
        <React.Fragment>
          <Space width={8} />
          <BetaBadge isSmall />
        </React.Fragment>
      )}
      {isActive && !isEnclosed && (
        <TabUnderline style={tab.colorOverride ? {backgroundColor: tab.colorOverride} : {}} />
      )}
    </React.Fragment>
  );
};

const Tab = ({
  tab,
  handlePressTab,
  isActive,
  isSecondary,
  style,
  children,
  isEnclosed,
  isSpacedTabs,
}: any) => {
  const isEnclosedStyle = isEnclosed
    ? {
        backgroundColor: isActive ? colors.blue.accent : colors.white,
        borderRadius: 5,
        minWidth: 100,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        flex: 1,
      }
    : {};
  const tabButtonStyle = {
    ...isEnclosedStyle,
    ...style,
  };
  return (
    <React.Fragment>
      {tab.prependSeparator && (
        <React.Fragment>
          <Space width={12} />
          <TabSeparator isSecondary={isSecondary} />
          <Space width={12} />
        </React.Fragment>
      )}
      {isSpacedTabs && !!tab.index && !isEnclosed && <Space width={12} />}
      <TabButton onPress={handlePressTab} isActive={isActive} style={tabButtonStyle}>
        {children}
      </TabButton>
      {isSpacedTabs && !isEnclosed && <Space width={12} />}
    </React.Fragment>
  );
};

const PrimaryTab = ({
  tab,
  handlePressTab,
  isActive,
  style,
  isEnclosed,
  isSpacedTabs,
  children,
}: any) => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; isEnclosed: any; isSpac... Remove this comment to see the full error message
    <Tab
      isEnclosed={isEnclosed}
      isSpacedTabs={isSpacedTabs}
      tab={tab}
      handlePressTab={handlePressTab}
      isActive={isActive}
      style={style}
    >
      <TabContent tab={tab} isActive={isActive} isEnclosed={isEnclosed}>
        {children}
      </TabContent>
    </Tab>
  );
};

const SecondaryTab = ({tab, handlePressTab, isActive, style, isEnclosed, isSpacedTabs}: any) => {
  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; isEnclosed: any; isSpac... Remove this comment to see the full error message
    <Tab
      isEnclosed={isEnclosed}
      isSpacedTabs={isSpacedTabs}
      tab={tab}
      handlePressTab={handlePressTab}
      isActive={isActive}
      isSecondary
      style={style}
    >
      <TabContent tab={tab} isActive={isActive} isSecondary />
    </Tab>
  );
};

const TabsItemsWrapper = ({isEnclosed, isFullWidth, children, scrollViewStyle}: any) => {
  const responsive = useResponsive();

  if (isEnclosed) {
    return <EnclosedTabsItemsContainer>{children}</EnclosedTabsItemsContainer>;
  }
  return (
    <ScrollView
      horizontal
      style={scrollViewStyle}
      contentContainerStyle={isFullWidth ? {flex: 1} : null}
      showsHorizontalScrollIndicator={responsive.desktop}
    >
      {children}
    </ScrollView>
  );
};

const TabsItems = ({
  tabs,
  TabComponent,
  handlePressTab,
  activeTabIndex,
  isEnclosed,
  isFullWidth,
  isSpacedTabs,
  scrollViewStyle,
  tabStyle,
}: any) => {
  return (
    <TabsItemsWrapper
      isEnclosed={isEnclosed}
      isFullWidth={isFullWidth}
      scrollViewStyle={scrollViewStyle}
    >
      {tabs.map((tab: any, index: any) => {
        const tabWithIndex = {...tab, index};
        return (
          <TabComponent
            key={index}
            tab={tabWithIndex}
            handlePressTab={() => handlePressTab(tabWithIndex)}
            isActive={index === activeTabIndex}
            isEnclosed={isEnclosed}
            isSpacedTabs={isSpacedTabs}
            style={tabStyle}
          />
        );
      })}
    </TabsItemsWrapper>
  );
};

export interface TabsProps<T> {
  tabs: T[];
  activeTabIndex: number;
  handlePressTab: (tab: T & {index: number}) => void;
  TabComponent: React.FC<{tab: T; isActive: boolean; onPress: () => void}>;
  style?: React.CSSProperties;
  scrollViewStyle?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  isEnclosed?: boolean;
  isFullWidth?: boolean;
  isSpacedTabs?: boolean;
}

const Tabs = <T,>({
  tabs,
  TabComponent,
  activeTabIndex,
  handlePressTab,
  style,
  scrollViewStyle,
  tabStyle,
  isEnclosed,
  isFullWidth,
  isSpacedTabs,
}: TabsProps<T>) => {
  return (
    <Container style={style}>
      <TabsItems
        tabs={tabs}
        TabComponent={TabComponent}
        activeTabIndex={activeTabIndex}
        handlePressTab={handlePressTab}
        isEnclosed={isEnclosed}
        isFullWidth={isFullWidth}
        isSpacedTabs={isSpacedTabs}
        scrollViewStyle={scrollViewStyle}
        tabStyle={tabStyle}
      />
    </Container>
  );
};

Tabs.PrimaryTab = PrimaryTab;
Tabs.SecondaryTab = SecondaryTab; // Pass this in as TabComponent for smaller tabs
Tabs.TabsItems = TabsItems;
Tabs.Tab = Tab;
Tabs.TabContent = TabContent;
Tabs.TabButton = TabButton;
Tabs.TabText = TabText;
Tabs.TabCount = TabCount;
Tabs.TabUnderline = TabUnderline;
Tabs.TabSeparator = TabSeparator;

// --------------------------------------------------
// Props
// --------------------------------------------------
Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  TabComponent: PropTypes.func,
  handlePressTab: PropTypes.func.isRequired,
  activeTabIndex: PropTypes.number,
  style: PropTypes.object,
  tabStyle: PropTypes.object,
  scrollViewStyle: PropTypes.object,
  isFullWidth: PropTypes.bool,
  isSpacedTabs: PropTypes.bool,
};

Tabs.defaultProps = {
  TabComponent: Tabs.PrimaryTab,
  activeTabIndex: 0,
  style: null,
  tabStyle: null,
  scrollViewStyle: {flex: 1},
  isFullWidth: false,
  isSpacedTabs: true,
};

Tab.propTypes = {
  tab: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSpacedTabs: PropTypes.bool,
  handlePressTab: PropTypes.func.isRequired,
  style: PropTypes.object,
  index: PropTypes.number,
};

Tab.defaultProps = {
  isActive: false,
  isSecondary: false,
  isSpacedTabs: true,
  style: null,
  index: 0,
};

TabText.propTypes = {
  isActive: PropTypes.bool,
  isSecondary: PropTypes.bool,
  style: PropTypes.object,
};

TabText.defaultProps = {
  isActive: false,
  isSecondary: false,
  style: null,
};

TabCount.propTypes = {
  tab: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isSecondary: PropTypes.bool,
};

TabCount.defaultProps = {
  isActive: false,
  isSecondary: false,
};

TabSeparator.propTypes = {
  isSecondary: PropTypes.bool,
};

TabSeparator.defaultProps = {
  isSecondary: false,
};

export default Tabs;
