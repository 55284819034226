import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Toast from '@shared/design/components/Toast';
import DeleteOrganizationForm from '@shared/modules/Organization/forms/DeleteOrganizationForm';
import useDeleteOrganizationMutation from '@shared/modules/Organization/hooks/useDeleteOrganizationMutation';

const DeleteOrganizationModal = ({
  isOpen,
  handleClose,
  organization,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const deleteToast = useToast({
    ToastComponent: Toast,
    message: `${organization.name} deleted.`,
  });
  const deleteOrganizationForm = DeleteOrganizationForm.edit({organizationId: organization.id});
  const {handleSubmit, submitting} = useDeleteOrganizationMutation({
    deleteOrganizationForm,
    onSuccess: () => {
      deleteToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`Delete ${organization.name}?`}
      subtitle={
        'Deleting this branch will remove it from the company. This action cannot be undone.'
      }
      isSubmitting={submitting}
      isMobileSheet
      handleDelete={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteOrganizationModal.fragment = gql`
  fragment DeleteOrganizationModal on Organization {
    id
    name
  }
`;

export default DeleteOrganizationModal;
