// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useDrawer, useNavigation, useQuery, useResponsive} from '@supermove/hooks';
import {
  CompanyModel,
  PhoneNumberModel,
  ProductUserModel,
  SupermoveProductModel,
  OrganizationModel,
} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import EmptyState from '@shared/design/components/EmptyState';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Switch from '@shared/design/components/Switch';
import ProductUserStatus from '@shared/modules/ProductUser/enums/ProductUserStatus';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsAISalesCopilotVoiceAgentsEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsAISalesCopilotVoiceAgentsEnabledMutation';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import useUpsertSupermoveProductMutation from '@shared/modules/SupermoveProduct/hooks/useUpsertSupermoveProductMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const DrawerHeaderText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const DrawerSubHeaderText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const getProductUserCountByStatus = (productUsers: ProductUserModel[], status: string) => {
  return _.filter(productUsers, {status}).length;
};

interface UpdateLicensesFieldsProps {
  form: Form<{supermoveProductForm: SupermoveProductFormProps}>;
  field: string;
  currentNumberOfLicenses: number | null;
}

const UpdateLicensesFields = ({
  form,
  field,
  currentNumberOfLicenses,
}: UpdateLicensesFieldsProps) => {
  return (
    <React.Fragment>
      <FieldInput
        label={'Current Number of Licenses'}
        isResponsive
        input={{
          disabled: true,
          value: _.toString(currentNumberOfLicenses || 0),
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.numberOfLicenses`}
        label={'Updated Number of Licenses'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter updated number of licenses',
          setFieldValue: form.setFieldValue,
        }}
      />
    </React.Fragment>
  );
};

interface UpdateLicensesDrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  company: CompanyModel;
  currentNumberOfLicenses: number | null;
}

const UpdateLicensesDrawer = ({
  isOpen,
  handleClose,
  refetch,
  company,
  currentNumberOfLicenses,
}: UpdateLicensesDrawerProps) => {
  const responsive = useResponsive();
  const supermoveProductForm = SupermoveProductForm.new({
    kind: SupermoveProductKind.SALES,
    status: SupermoveProductStatus.ACTIVE,
    companyId: company.id,
  });

  const {form, handleSubmit} = useUpsertSupermoveProductMutation({
    supermoveProductForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: unknown) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Update Licenses'}
      isSubmitting={false}
      handleSubmit={handleSubmit}
    >
      <WarningCallout
        text={"Updating the number of licenses requires an update to this organization's billing."}
      />
      <Space height={16} />
      <DrawerHeaderText responsive={responsive}>Licenses</DrawerHeaderText>
      <Space height={4} />
      <DrawerSubHeaderText responsive={responsive}>
        Update the number of Sales Copilot licenses for this organization.
      </DrawerSubHeaderText>
      <Space height={16} />
      <UpdateLicensesFields
        form={form}
        field={'supermoveProductForm'}
        currentNumberOfLicenses={currentNumberOfLicenses}
      />
    </DrawerWithAction>
  );
};

interface ActionPanelBodyProps {
  salesAppProduct: SupermoveProductModel;
}

const ActionPanelBody = ({salesAppProduct}: ActionPanelBodyProps) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigation();

  if (!salesAppProduct) {
    return (
      <EmptyState.DescriptionText responsive={responsive} style={{textAlign: 'center'}}>
        {'Sales Copilot is not enabled for this organization. '}
        <EmptyState.LinkText
          responsive={responsive}
          onPress={() => navigator.navigate('CompanyDetailsProductsPage', {slug: params.slug})}
        >
          Navigate to products to enable it.
        </EmptyState.LinkText>
      </EmptyState.DescriptionText>
    );
  }

  const activeUserCount = getProductUserCountByStatus(
    salesAppProduct.productUsers,
    ProductUserStatus.ACTIVE,
  );
  const pendingUserCount = getProductUserCountByStatus(
    salesAppProduct.productUsers,
    ProductUserStatus.INVITED,
  );
  return (
    <React.Fragment>
      <FieldValue
        isResponsive
        label={'Number of Licenses'}
        value={`${activeUserCount + pendingUserCount}/${
          salesAppProduct.numberOfLicenses || 0
        } used`}
      />
      <Space height={16} />
      <FieldValue isResponsive label={'Active Users'} value={`${activeUserCount}`} />
      <Space height={16} />
      <FieldValue isResponsive label={'Pending Invitations'} value={`${pendingUserCount}`} />
    </React.Fragment>
  );
};

interface PhoneNumberPanelBodyProps {
  twilioPhoneNumbers: PhoneNumberModel[];
}

const PhoneNumberPanelBody = ({twilioPhoneNumbers}: PhoneNumberPanelBodyProps) => {
  // Right now, all numbers are both active and registered. We have not built out the concept of deactivating and unregistering these numbers yet.
  const twilioPhoneNumberCount = twilioPhoneNumbers ? twilioPhoneNumbers.length : 0;
  return (
    <React.Fragment>
      <FieldValue
        isResponsive
        label={'Number of Registered Phone Numbers'}
        value={`${twilioPhoneNumberCount}`}
      />
      <Space height={16} />
      <FieldValue
        isResponsive
        label={'Number of Active Phone Numbers'}
        value={`${twilioPhoneNumberCount}`}
      />
      <Space height={16} />
      <FieldValue isResponsive label={'Number of Inactive Phone Numbers'} value={'0'} />
    </React.Fragment>
  );
};

const VoiceAgentsPanelBody = ({
  organization,
  refetch,
}: {
  organization: OrganizationModel;
  refetch: () => {};
}) => {
  const {form, handleSubmit} = useToggleSettingsAISalesCopilotVoiceAgentsEnabledMutation({
    settingsForm: SettingsForm.edit({
      ...organization.settings,
      aiSalesCopilotVoiceAgentsEnabled: organization.settings.aiSalesCopilotVoiceAgentsEnabled,
    }),
    onSuccess: () => {
      refetch();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <Switch
        labelRight={'Enable Voice Agents'}
        isResponsive
        isOn={form.values.settingsForm.aiSalesCopilotVoiceAgentsEnabled}
        onChange={(newValue) => {
          form.setFieldValue('settingsForm.aiSalesCopilotVoiceAgentsEnabled', newValue);
          setImmediate(() => {
            handleSubmit();
          });
        }}
      />
    </React.Fragment>
  );
};

const CompanyDetailsAiSalesCopilotGeneralPage = () => {
  const {params} = useNavigation();
  const updateLicensesDrawer = useDrawer({name: 'Update Licenses Drawer'});
  const {loading, data, refetch} = useQuery(CompanyDetailsAiSalesCopilotGeneralPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });
  return (
    <CompanyDetailsPage
      selectedLabel={'ai-sales-copilot/general'}
      pageTitle={'AI Sales Copilot'}
      pageDescription={'Configure Sales Copilot including the number of licenses for this company.'}
    >
      {!loading && (
        <React.Fragment>
          <ActionPanel
            title={'Licenses'}
            BodyComponent={ActionPanelBody}
            bodyComponentProps={{
              salesAppProduct: data?.organization.salesAppProduct,
            }}
            ActionButtonComponent={data?.organization.salesAppProduct && TertiaryButton}
            actionButtonComponentProps={{
              iconLeft: Icon.Pen,
              text: 'Edit',
              onPress: updateLicensesDrawer.handleOpen,
            }}
          />
          <Space height={16} />
          <ActionPanel
            title={'Phone Numbers'}
            BodyComponent={PhoneNumberPanelBody}
            bodyComponentProps={{
              twilioPhoneNumbers: data?.organization.twilioPhoneNumbers,
            }}
          />
          <Space height={16} />
          <ActionPanel
            title={'Voice Agents'}
            BodyComponent={VoiceAgentsPanelBody}
            bodyComponentProps={{
              organization: data?.organization,
              refetch,
            }}
          />
          <UpdateLicensesDrawer
            isOpen={updateLicensesDrawer.isOpen}
            handleClose={updateLicensesDrawer.handleClose}
            refetch={refetch}
            company={data?.organization.company}
            currentNumberOfLicenses={data?.organization.salesAppProduct?.numberOfLicenses}
          />
        </React.Fragment>
      )}
    </CompanyDetailsPage>
  );
};

CompanyDetailsAiSalesCopilotGeneralPage.query = gql`
  ${SettingsForm.edit.fragment}
  query CompanyDetailsAiSalesCopilotGeneralPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      salesAppProduct: supermoveProduct(kind: "SALES") {
        id
        numberOfLicenses
        productUsers {
          id
          status
        }
      }
      twilioPhoneNumbers {
        id
      }
      company {
        id
      }
      settings {
        id
        aiSalesCopilotVoiceAgentsEnabled
        ...SettingsForm_edit
      }
    }
  }
`;

export default CompanyDetailsAiSalesCopilotGeneralPage;
