// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PreventPropagation} from '@supermove/components';
import {usePopover, useResponsive, ResponsiveType} from '@supermove/hooks';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import {
  ColumnDefinitionCellActionsType,
  ActionType,
  DefaultItemType,
} from '@shared/design/components/Table/components/TableBuilder';

type ActionWithHookOutputType<T extends DefaultItemType = DefaultItemType> = Omit<
  ActionType<T>,
  'onPress'
> & {
  onPress: () => void;
};
const getActionWithHookOutput = <T extends DefaultItemType = DefaultItemType>({
  action,
  item,
}: {
  action: ActionType<T>;
  item: T;
}): ActionWithHookOutputType<T> => {
  const hookOutput = action.actionHook?.hook(action.actionHook?.hookArgument) ?? {
    isOpen: false,
    handleClose: () => {},
    handleOpen: () => {},
    handleSubmit: () => {},
  };
  return {
    ...action,
    onPress: () =>
      action.onPress({
        item,
        handleOpen: hookOutput.handleOpen,
        handleSubmit: hookOutput.handleSubmit,
      }),
    hookOutput,
  };
};

const getAllActionsWithHookOutput = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  item,
  responsive,
}: {
  columnDefinitions: ColumnDefinitionCellActionsType<T>[];
  item: T;
  responsive: ResponsiveType;
}): ActionWithHookOutputType[] => {
  const actions = _.flatMap(
    columnDefinitions,
    (column) => (column.actions && column.actions(item)) ?? [],
  );
  const actionsWithHookOutput = actions.map((action) => getActionWithHookOutput({action, item}));
  if (responsive.desktop) {
    return actionsWithHookOutput.filter((action) => !action.desktopIcon && !action.desktopLabel);
  }
  return actionsWithHookOutput;
};

const ItemActionMenuButton = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  item,
}: {
  columnDefinitions: ColumnDefinitionCellActionsType<T>[];
  item: T;
}) => {
  const responsive = useResponsive();
  const actionsMenuPopover = usePopover();
  const actionsWithHookOutput = getAllActionsWithHookOutput({columnDefinitions, item, responsive});

  return (
    <PreventPropagation>
      <ActionMenuPopover popover={actionsMenuPopover} actions={actionsWithHookOutput}>
        <IconButton
          source={'ellipsis-v'}
          onPress={actionsMenuPopover.handleToggle}
          isSecondary={responsive.desktop}
        />
      </ActionMenuPopover>
      {actionsWithHookOutput.map((actionWithHookOutput) => {
        const {hookOutput} = actionWithHookOutput;
        return (
          <React.Fragment key={actionWithHookOutput.text}>
            {actionWithHookOutput.actionHook?.renderComponent &&
              actionWithHookOutput.actionHook.renderComponent({
                isOpen: hookOutput?.isOpen ?? false,
                handleClose: hookOutput?.handleClose,
                hookKey: hookOutput?.key,
              })}
          </React.Fragment>
        );
      })}
    </PreventPropagation>
  );
};

export default ItemActionMenuButton;
