// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PreventPropagation, Space, Styled} from '@supermove/components';
import {useEffect, useHover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ItemActionButton from '@shared/design/components/Table/components/ItemActionButton';
import ItemActionMenuButton from '@shared/design/components/Table/components/ItemActionMenuButton';
import TableBuilder, {
  ColumnDefinitionType,
  ColumnDefinitionCellActionsType,
  RowHookType,
  ItemFunctionReturnVoidType,
  DefaultItemType,
  ColumnDefinitionCellContentType,
} from '@shared/design/components/Table/components/TableBuilder';

export type RowVerticalPaddingType = 8 | 12;

const CellRow = Styled.View<{
  rowVerticalPadding?: RowVerticalPaddingType;
}>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: ${({rowVerticalPadding}) => rowVerticalPadding || 8}px;
`;

const CellRowButton = Styled.ButtonV2<{
  rowVerticalPadding?: RowVerticalPaddingType;
  isHighlighted: boolean;
}>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
  padding-vertical: ${({rowVerticalPadding}) => rowVerticalPadding || 8}px;
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.Blue10 : colors.white)};
  cursor: pointer;
`;
const TableRowPressHandler = <T extends DefaultItemType = DefaultItemType>({
  rowHook = TableBuilder.PLACEHOLDER_ROW_HOOK,
  onRowPress,
  onRowHover,
  isRowSelected,
  rowVerticalPadding,
  rowIndex,
  item,
  children,
}: {
  onRowPress?: ItemFunctionReturnVoidType<T>;
  onRowHover?: (item: T | undefined, index: number) => void;
  rowHook?: RowHookType;
  isRowSelected: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  rowIndex: number;
  item: T;
  children: React.ReactNode;
}) => {
  const {isHovered, ref} = useHover();
  const {isOpen, handleOpen, handleClose, key} = rowHook.hook(rowHook.hookArgument);

  useEffect(() => {
    onRowHover?.(isHovered ? item : undefined, rowIndex);
  }, [isHovered]);

  return onRowPress ? (
    <React.Fragment>
      <CellRowButton
        ref={ref}
        onPress={() => onRowPress(item, {isOpen, handleOpen, handleClose})}
        isHighlighted={isHovered || isRowSelected}
        rowVerticalPadding={rowVerticalPadding}
      >
        {children}
      </CellRowButton>
      {rowHook.renderComponent?.({item, isOpen, handleClose, hookKey: key})}
    </React.Fragment>
  ) : (
    <CellRow rowVerticalPadding={rowVerticalPadding}>{children}</CellRow>
  );
};

const ItemActions = <T extends DefaultItemType = DefaultItemType>({
  actions,
  columnDefinition,
  item,
}: {
  actions: ColumnDefinitionCellActionsType<T>['actions'];
  columnDefinition: ColumnDefinitionCellActionsType<T>;
  item: T;
}) => {
  const actionsList = actions ? actions(item) : [];
  const actionsForMenu = actionsList.filter(
    (action) => !action.desktopIcon && !action.desktopLabel,
  );
  const hasIndependentAndMenuActions = actionsList.length > actionsForMenu.length;
  return (
    <PreventPropagation style={{flexDirection: 'row'}}>
      {actionsList?.map((action, index) => {
        if (action.desktopIcon || action.desktopLabel) {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Space width={8} />}
              <ItemActionButton action={action} item={item} />
            </React.Fragment>
          );
        }
        return null;
      })}
      {_.some(actionsForMenu) && (
        <React.Fragment>
          {hasIndependentAndMenuActions && <Space width={8} />}
          <ItemActionMenuButton columnDefinitions={[columnDefinition]} item={item} />
        </React.Fragment>
      )}
    </PreventPropagation>
  );
};

export interface TableRowProps<T extends DefaultItemType = DefaultItemType> {
  onRowPress?: ItemFunctionReturnVoidType<T>;
  onRowHover?: (item: T | undefined, index: number) => void;
  rowHook?: RowHookType;
  isRowSelected: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  item: T;
  rowIndex: number;
  columnDefinitions: ColumnDefinitionType<T>[];
}
const TableRow = <T extends DefaultItemType = DefaultItemType>({
  item,
  rowIndex,
  columnDefinitions,
  onRowPress,
  onRowHover,
  isRowSelected,
  rowVerticalPadding,
  rowHook,
}: TableRowProps<T>) => {
  return (
    <TableRowPressHandler
      onRowPress={onRowPress}
      onRowHover={onRowHover}
      rowHook={rowHook}
      isRowSelected={isRowSelected}
      rowVerticalPadding={rowVerticalPadding}
      rowIndex={rowIndex}
      item={item}
    >
      {columnDefinitions.map((columnDefinition, index) => {
        const {flex, minWidth, maxWidth, width, cellStyle = {}} = columnDefinition;
        return (
          <TableBuilder.Cell key={index} style={{flex, minWidth, maxWidth, width, ...cellStyle}}>
            {TableBuilder.getColumnHasActions(columnDefinition) ? (
              <ItemActions
                columnDefinition={columnDefinition}
                item={item}
                actions={columnDefinition.actions}
              />
            ) : (
              <React.Fragment>
                <TableBuilder.DisplayContent
                  // Because of the ternary this should be auto-typed as ColumnDefinitionCellContentType
                  // Unsure why this isn't working
                  cellIcon={(columnDefinition as ColumnDefinitionCellContentType<T>).cellIcon}
                  cellIconColor={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).cellIconColor
                  }
                  cellIconSize={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).cellIconSize
                  }
                  cellText={(columnDefinition as ColumnDefinitionCellContentType<T>).cellText}
                  cellTextLink={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).cellTextLink
                  }
                  emptyText={(columnDefinition as ColumnDefinitionCellContentType<T>).emptyText}
                  handlePressCellText={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).handlePressCellText
                  }
                  cellComponent={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).cellComponent
                  }
                  tooltip={(columnDefinition as ColumnDefinitionCellContentType<T>).tooltip}
                  tooltipProps={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).tooltipProps
                  }
                  numberOfLines={
                    (columnDefinition as ColumnDefinitionCellContentType<T>).numberOfLines
                  }
                  item={item}
                  rowIndex={rowIndex}
                />
                {'secondary' in columnDefinition &&
                  columnDefinition.secondary &&
                  !columnDefinition.secondary.isHidden && (
                    <React.Fragment>
                      <Space height={4} />
                      <TableBuilder.DisplayContent
                        cellIcon={
                          (columnDefinition.secondary as ColumnDefinitionCellContentType<T>)
                            .cellIcon
                        }
                        cellIconColor={
                          (columnDefinition.secondary as ColumnDefinitionCellContentType<T>)
                            .cellIconColor
                        }
                        cellIconSize={
                          (columnDefinition.secondary as ColumnDefinitionCellContentType<T>)
                            .cellIconSize
                        }
                        cellText={columnDefinition.secondary.cellText}
                        cellTextLink={columnDefinition.secondary.cellTextLink}
                        emptyText={columnDefinition.secondary.emptyText}
                        handlePressCellText={columnDefinition.secondary.handlePressCellText}
                        cellComponent={columnDefinition.secondary.cellComponent}
                        tooltip={columnDefinition.secondary.tooltip}
                        tooltipProps={columnDefinition.secondary.tooltipProps}
                        numberOfLines={columnDefinition.secondary.numberOfLines}
                        item={item}
                        rowIndex={rowIndex}
                        isSecondary
                      />
                    </React.Fragment>
                  )}
              </React.Fragment>
            )}
          </TableBuilder.Cell>
        );
      })}
    </TableRowPressHandler>
  );
};

export default TableRow;
