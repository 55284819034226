// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CompanyIsActiveForm, {
  CompanyIsActiveFormType,
} from '@shared/modules/Company/forms/CompanyIsActiveForm';

const useUpdateCompanyIsActiveMutation = ({
  companyIsActiveForm,
  onSuccess,
  onError,
}: {
  companyIsActiveForm: CompanyIsActiveFormType;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyIsActiveForm: CompanyIsActiveForm.toForm(companyIsActiveForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateCompanyIsActiveMutation.mutation,
    variables: {
      companyIsActiveForm: CompanyIsActiveForm.toMutation(form.values.companyIsActiveForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateCompanyIsActiveMutation.mutation = gql`
  mutation useUpdateCompanyIsActiveMutation($companyIsActiveForm: CompanyIsActiveForm!) {
    response: updateCompanyIsActive(companyIsActiveForm: $companyIsActiveForm) {
      ${gql.errors}
      company {
        id
        isActive
      }
    }
  }
`;

export default useUpdateCompanyIsActiveMutation;
