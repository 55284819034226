// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Form, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ProgressModal from '@shared/design/components/ProgressModal';
import useProgress, {StepType} from '@shared/modules/App/hooks/useProgress';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import CompanyForm, {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';
import useCreateCompanyMutation from '@shared/modules/Company/hooks/useCreateCompanyMutation';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';
import CompanyDetailsFields from 'modules/Company/components/CompanyDetailsFields';
import CreateCompanyModalFooter from 'modules/Company/components/CreateCompanyModalFooter';
import CreateCompanyReviewStep from 'modules/Company/components/CreateCompanyReviewStep';
import ProductsOverviewFields from 'modules/Company/components/ProductsOverviewFields';
import SalesProductFields from 'modules/Company/components/SalesProductFields';

interface ResponsiveType {
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
}

const MobilePaddingContainer = Styled.View`
  padding-horizontal: ${({responsive}: {responsive: ResponsiveType}) =>
    responsive.desktop ? 0 : 16}px;
`;

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const STEPS: StepType[] = [
  {
    kind: CreateCompanyStepKinds.COMPANY_DETAILS,
  },
  {
    kind: CreateCompanyStepKinds.PRODUCTS.OVERVIEW,
    steps: [
      {
        kind: CreateCompanyStepKinds.PRODUCTS.SALES,
      },
    ],
  },
  {
    kind: CreateCompanyStepKinds.REVIEW,
  },
];

const StepFields = ({
  stepKind,
  form,
  field,
  setCurrentStepKind,
  responsive,
}: {
  stepKind: string;
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  responsive: ResponsiveType;
}) => {
  switch (stepKind) {
    case CreateCompanyStepKinds.COMPANY_DETAILS:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <CompanyDetailsFields form={form} field={field} />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.PRODUCTS.OVERVIEW:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <ProductsOverviewFields
            form={form}
            field={field}
            setCurrentStepKind={setCurrentStepKind}
          />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.PRODUCTS.SALES:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <SalesProductFields form={form} field={field} />
        </MobilePaddingContainer>
      );
    case CreateCompanyStepKinds.REVIEW:
      return (
        <CreateCompanyReviewStep
          form={form}
          field={field}
          setCurrentStepKind={setCurrentStepKind}
        />
      );
    default:
      return null;
  }
};

const CreateCompanyStepFields = ({
  form,
  field,
  currentStepKind,
  setCurrentStepKind,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
  currentStepKind: string;
  setCurrentStepKind: (kind: string) => void;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <StepHeaderText responsive={responsive}>
          {CreateCompanyStepKinds.getStepTitle(currentStepKind)}
        </StepHeaderText>
        <Space height={8} />
        <StepHeaderDescription responsive={responsive}>
          {CreateCompanyStepKinds.getStepDescription(currentStepKind)}
        </StepHeaderDescription>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

const CreateCompanyModal = ({
  isOpen,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const companyForm = CompanyForm.new({
    supermoveProductForms: [
      SupermoveProductForm.new({
        kind: SupermoveProductKind.SALES,
        status: SupermoveProductStatus.ACTIVE,
      }),
    ],
  });
  const {form, handleSubmit, submitting} = useCreateCompanyMutation({
    companyForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps: STEPS,
    getPreviousStepKind: CreateCompanyStepKinds.getPreviousStepKind,
    getNextStepKind: CreateCompanyStepKinds.getNextStepKind,
  });

  // If all steps are completed, we are reviewing, allowing the user to easily navigate to the review step.
  const isReviewingSteps = _.isEqual(
    _.sortBy(allStepKinds.filter((stepKind) => stepKind !== CreateCompanyStepKinds.REVIEW)),
    _.sortBy(completedSteps),
  );

  return (
    <ProgressModal
      isOpen={isOpen}
      handleClose={handleClose}
      steps={STEPS}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={CreateCompanyStepKinds.getStepTitle}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Create Company'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <CreateCompanyStepFields
          form={form}
          field={'companyForm'}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
        />
      }
      FooterElement={
        <CreateCompanyModalFooter
          isViewingFirstStep={isViewingFirstStep}
          previousStepKind={previousStepKind}
          nextStepKind={nextStepKind}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          setCompletedSteps={setCompletedSteps}
          form={form}
          isReviewingSteps={isReviewingSteps}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      }
    />
  );
};

export default CreateCompanyModal;
