// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {withFragment, Validation} from '@supermove/utils';
import {ValidationErrors} from '@supermove/utils/src/Validation';

// App
import CreateCompanyBranchStep from '@shared/modules/Organization/enums/CreateCompanyBranchStep';

export interface CompanyBranchFormType {
  organizationId?: string;
  companyId: string;
  name: string;
  slug: string;
  kind?: string;
}

const _new = ({
  companyId,
  name,
  slug,
}: {
  companyId: string;
  name?: string;
  slug?: string;
}): CompanyBranchFormType => ({
  organizationId: undefined,
  companyId,
  name: name || '',
  slug: slug || '',
  kind: undefined,
});

const edit = withFragment(
  (organization: OrganizationModel): CompanyBranchFormType => ({
    organizationId: organization.id,
    companyId: organization.company.id,
    name: organization.name,
    slug: organization.slug,
    kind: organization.kind,
  }),
  gql`
    fragment CompanyBranchForm_edit on Organization {
      id
      name
      slug
      kind
      company {
        id
      }
    }
  `,
);

export interface CompanyBranchFormToFormType {
  organizationId?: string;
  companyId: string;
  name: string;
  slug: string;
  kind?: string;
}

const toForm = ({
  organizationId,
  companyId,
  name,
  slug,
  kind,
}: CompanyBranchFormType): CompanyBranchFormToFormType => ({
  organizationId,
  companyId,
  name,
  slug,
  kind,
});

const toMutation = ({
  organizationId,
  companyId,
  name,
  slug,
  kind,
}: CompanyBranchFormToFormType) => ({
  organizationId,
  companyId,
  name,
  slug,
  kind,
});

// Valid slugs must be at least 3 characters long and only contain letters, numbers, -, and _
const validateSlug = ({form, field}: {form: any; field: string}) => {
  const slug = _.get(form.values, field);
  const isValidIdentifier =
    slug.length >= 3 && /^[a-zA-Z0-9-_]+$/.test(slug) && !slug.includes(' ');
  if (!isValidIdentifier) {
    return {
      [field]: `This identifier does not meet the requirements\n • Must be unique\n • Must contain at least 3 characters\n • Only letters, numbers, -, and _ are allowed\n • Cannot contain spaces`,
    };
  }
  return {};
};

const validateBranchDetails = ({
  form,
  field,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
}) => {
  const nameErrors = Validation.requiredStringField({
    form,
    field: `${field}.name`,
    message: 'Please enter a name.',
  });
  const identifierErrors = validateSlug({form, field: `${field}.slug`});
  return {
    ...nameErrors,
    ...identifierErrors,
  };
};

const validateBranchType = ({
  form,
  field,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
}) => {
  return Validation.requiredStringField({
    form,
    field: `${field}.kind`,
    message: 'Please select a branch type.',
  });
};

const validateFormForStep = ({
  stepKind,
  form,
  field,
}: {
  stepKind: string;
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
}): ValidationErrors => {
  switch (stepKind) {
    case CreateCompanyBranchStep.BRANCH_DETAILS:
      return validateBranchDetails({form, field});
    case CreateCompanyBranchStep.BRANCH_TYPE:
      return validateBranchType({form, field});
    default:
      return {};
  }
};

const validateStep = async ({
  stepKind,
  form,
}: {
  stepKind: string;
  form: any;
}): Promise<ValidationErrors> => {
  const errors = validateFormForStep({stepKind, form, field: 'companyBranchForm'});
  await form.setTouched(_.mapValues(errors, () => true));
  await form.setErrors(errors);
  return errors;
};

const CompanyBranchForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
  validateStep,
};

export default CompanyBranchForm;
