import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserTyped} from '@supermove/app';

import AppWrapper from 'core/AppWrapper';

import {client, clientTyped} from './config';

ReactDOM.render(
  <React.StrictMode>
    <BrowserTyped client={client} clientTyped={clientTyped}>
      <AppWrapper />
    </BrowserTyped>
  </React.StrictMode>,
  document.getElementById('___gatsby'),
);
