// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useResponsive, useToast, ResponsiveType} from '@supermove/hooks';
import {CompanyModel, SupermoveProductModel} from '@supermove/models';
import {NavigationType} from '@supermove/navigation/src/NavigationTypes';
import {colors} from '@supermove/styles';
import {Datetime, List, pluralize, openNewTab} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table, {TableComponents} from '@shared/design/components/Table';
import Toast from '@shared/design/components/Toast';
// @ts-expect-error - Need to declare modules for pngs in ts
import SalesforceLogo from '@shared/modules/App/assets/salesforce-logo.png';
// @ts-expect-error - Need to declare modules for pngs in ts
import SlackLogo from '@shared/modules/App/assets/slack-logo.png';
// @ts-expect-error - Need to declare modules for pngs in ts
import VitallyLogo from '@shared/modules/App/assets/vitally-logo.png';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import DeactivateCompanyModal from 'modules/Company/List/components/DeactivateCompanyModal';
import ReactivateCompanyModal from 'modules/Company/List/components/ReactivateCompanyModal';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const IdentifierButton = Styled.ButtonV2`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const ServiceLogoButton = Styled.ButtonV2`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  align-items: center;
  justify-content: center;
`;

const LogoImage = Styled.Image`
  width: 20px;
  height: 20px;
`;

const handleGoToCompany = ({
  company,
  responsive,
  navigator,
}: {
  company: CompanyModel;
  responsive: ResponsiveType;
  navigator: NavigationType;
}) => {
  if (responsive.desktop) {
    navigator.navigate('CompanyDetailsOverviewPage', {slug: company.slug});
  } else {
    navigator.navigate('CompanyDetailsPageMobileNavigation', {slug: company.slug});
  }
};

const getCompanyServices = ({company}: {company: CompanyModel}) => {
  return [
    ...List.insertIf(!!company.slackChannelId, {
      link: `https://supermove.slack.com/archives/${company.slackChannelId}`,
      logo: SlackLogo,
    }),
    ...List.insertIf(!!company.salesforceId, {
      link: `https://supermove.vitally.io/customers/${company.salesforceId}`,
      logo: VitallyLogo,
    }),
    ...List.insertIf(!!company.salesforceId, {
      link: `https://supermove.my.salesforce.com/${company.salesforceId}`,
      logo: SalesforceLogo,
    }),
  ];
};

const getActions = ({company, refetch}: {company: CompanyModel; refetch: () => void}) => {
  return [
    ...List.insertIf(company.isActive, {
      text: 'Deactivate company',
      onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
      actionHook: {
        hook: useModal,
        hookArgument: {name: 'Deactivate Company Modal'},
        renderComponent: ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
          return (
            <DeactivateCompanyModal company={company} isOpen={isOpen} handleClose={handleClose} />
          );
        },
      },
    }),
    ...List.insertIf(!company.isActive, {
      text: 'Reactivate company',
      onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
      actionHook: {
        hook: useModal,
        hookArgument: {name: 'Reactivate Company Modal'},
        renderComponent: ({isOpen, handleClose}: {isOpen: boolean; handleClose: () => void}) => {
          return (
            <ReactivateCompanyModal company={company} isOpen={isOpen} handleClose={handleClose} />
          );
        },
      },
    }),
  ];
};

const getColumnDefinitions = ({refetch}: {refetch: () => void}) => {
  return [
    {
      flex: 3,
      minWidth: 88,
      headerLabel: 'Company Name',
      cellText: (company: CompanyModel) => company.name,
      secondary: {
        headerLabel: 'Identifier',
        cellComponent: (company: CompanyModel) => <CompanyIdentifier company={company} />,
      },
      mobileOptions: {
        rank: 0,
      },
    },
    {
      flex: 2,
      minWidth: 80,
      headerLabel: 'Branches',
      cellText: (company: CompanyModel) =>
        pluralize('Branch', _.toNumber(company.branchCount), true),
      secondary: {
        headerLabel: 'Users',
        cellText: (company: CompanyModel) =>
          pluralize('User', _.toNumber(company.officeStaffCount), true),
      },
    },
    {
      flex: 2,
      minWidth: 88,
      headerLabel: 'Contract Info',
      cellText: (company: CompanyModel) =>
        pluralize('Truck', company.contractedTruckQuantity || 0, true),
      secondary: {
        cellText: (company: CompanyModel) => pluralize('License', company.licenseCount, true),
      },
    },
    {
      flex: 4,
      minWidth: 80,
      headerLabel: 'Products',
      cellComponent: (company: CompanyModel) => (
        <SupermoveProductBadges supermoveProducts={company.activeSupermoveProducts} />
      ),
      mobileOptions: {
        rank: 2,
      },
    },
    {
      flex: 2,
      minWidth: 80,
      headerLabel: 'Created At',
      cellText: (company: CompanyModel) =>
        Datetime.convertToDisplayDatetime(company.createdAt, 'MM/DD/YYYY'),
      secondary: {
        cellText: (company: CompanyModel) =>
          Datetime.convertToDisplayDatetime(company.createdAt, Datetime.FORM_TIME),
      },
    },
    {
      flex: 2,
      minWidth: 80,
      headerLabel: 'Services',
      cellComponent: (company: CompanyModel) => <CompanyServices company={company} />,
    },
    {
      flex: 1,
      actions: (company: CompanyModel) => getActions({company, refetch}),
    },
  ];
};

const CompanyIdentifier = ({company}: {company: CompanyModel}) => {
  const responsive = useResponsive();
  const copiedToast = useToast({
    ToastComponent: Toast,
    message: `Copied to clipboard.`,
  });

  return (
    <IdentifierButton
      onPress={() => {
        navigator.clipboard.writeText(company.slug);
        copiedToast.handleToast();
      }}
    >
      <Icon source={Icon.Copy} size={12} color={colors.blue.interactive} />
      <Space width={8} />
      <TableComponents.SecondaryText responsive={responsive} numberOfLines={1}>
        {company.slug}
      </TableComponents.SecondaryText>
    </IdentifierButton>
  );
};

const SupermoveProductBadges = ({
  supermoveProducts,
}: {
  supermoveProducts: SupermoveProductModel[];
}) => {
  const sortedSupermoveProductKinds = SupermoveProductKind.getSortedKinds();

  return (
    <Row style={{flex: 1, flexWrap: 'wrap'}}>
      {supermoveProducts
        .sort(
          (a, b) =>
            sortedSupermoveProductKinds.indexOf(a.kind) -
            sortedSupermoveProductKinds.indexOf(b.kind),
        )
        .map(({kind}: {kind: string}, index: number) => (
          <Row key={index}>
            <Badge
              isResponsive
              label={SupermoveProductKind.getLabel(kind)}
              color={SupermoveProductKind.getColor(kind)}
              style={{marginVertical: 2}}
            />
            <Space width={8} />
          </Row>
        ))}
    </Row>
  );
};

const CompanyServices = ({company}: {company: CompanyModel}) => {
  const companyServices = getCompanyServices({company});

  return (
    <Row>
      {companyServices.map(({link, logo}, index) => {
        return (
          <React.Fragment key={link}>
            {index > 0 && <Space width={4} />}
            <ServiceLogoButton onPress={() => openNewTab(link)}>
              <LogoImage resizeMode={'contain'} source={{uri: logo}} />
            </ServiceLogoButton>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

const CompaniesTable = ({
  companies,
  isLoading,
  refetch,
}: {
  companies: CompanyModel[];
  isLoading?: boolean;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();

  return (
    <Table
      key={navigator.state.routeName}
      itemKey={'id'}
      columnDefinitions={getColumnDefinitions({
        refetch,
      })}
      items={companies}
      emptyStateText={'No companies to display'}
      isLoading={isLoading}
      onRowPress={(company) => handleGoToCompany({company, responsive, navigator})}
      minWidth={800}
      horizontalScrollViewStyle={{paddingLeft: 24, paddingRight: 24}}
      isScrollable={responsive.desktop}
      containerStyle={responsive.desktop ? undefined : {flexGrow: 1}}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompaniesTable.fragment = gql`
  ${DeactivateCompanyModal.fragment}
  ${ReactivateCompanyModal.fragment}

  fragment CompaniesTable on Company {
    id
    name
    slug
    isActive
    officeStaffCount
    branchCount
    licenseCount
    contractedTruckQuantity
    createdAt
    salesforceId
    slackChannelId
    activeSupermoveProducts {
      id
      kind
    }
    ...DeactivateCompanyModal
    ...ReactivateCompanyModal
  }
`;

export default CompaniesTable;
