// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import UserForm, {UserFormProps} from '@shared/modules/User/forms/UserForm';

const useUpdateUserRoleMutation = ({
  userForm,
  onSuccess,
  onError,
}: {
  userForm: UserFormProps;
  onSuccess: ({user}: {user: UserModel}) => void;
  onError: (error: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      userForm: UserForm.toForm(userForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserRoleMutation.mutation,
    variables: {
      // @ts-expect-error TS(2345): Argument of type '{ userId: number | undefined; or... Remove this comment to see the full error message
      userForm: UserForm.toMutation(form.values.userForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserRoleMutation.mutation = gql`
  mutation useUpdateUserRoleMutation($userForm: UserForm!) {
    response: updateUserRole(userForm: $userForm) {
      user {
        id
        fullName
      }
      ${gql.errors}
    }
  }
`;

export default useUpdateUserRoleMutation;
