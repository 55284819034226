// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Icon, IconSource} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

interface SuccessModalProps {
  isOpen: boolean;
  icon?: IconSource;
  title: string;
  subtitle?: string;
  handlePressOutside?: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction?: () => void;
  primaryActionText: string;
  secondaryActionText?: string;
  isSubmitting?: boolean;
  color?: any;
  isDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  children?: React.ReactNode;
}

const SuccessModal = ({
  icon,
  title,
  subtitle,
  isOpen,
  handlePressOutside,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  isSubmitting,
  color,
  isDisabled,
  isSecondaryDisabled,
  children,
}: SuccessModalProps) => {
  return (
    <ActionModal
      color={color || colors.blue.interactive}
      icon={icon}
      title={title}
      message={subtitle}
      isOpen={isOpen}
      handlePressOutside={handlePressOutside}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleSecondaryAction}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      isSecondaryDisabled={isSecondaryDisabled}
      children={children}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
SuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  icon: Icon.SourcePropType,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handlePressOutside: PropTypes.func,
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

SuccessModal.defaultProps = {
  subtitle: undefined,
  icon: Icon.Check,
  handlePressOutside: () => {},
  handleSecondaryAction: null,
  secondaryActionText: '',
  isSubmitting: false,
};

export default SuccessModal;
