export interface DeleteOrganizationFormType {
  organizationId: string;
}

const edit = ({organizationId}: {organizationId: string}): DeleteOrganizationFormType => ({
  organizationId,
});

export type DeleteOrganizationFormToFormType = DeleteOrganizationFormType;

const toForm = ({
  organizationId,
}: DeleteOrganizationFormType): DeleteOrganizationFormToFormType => ({
  organizationId,
});

const toMutation = ({organizationId}: DeleteOrganizationFormToFormType) => ({
  organizationId,
});

const DeleteOrganizationForm = {
  edit,
  toForm,
  toMutation,
};

export default DeleteOrganizationForm;
