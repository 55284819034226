// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import ItemActionMenuButton from '@shared/design/components/Table/components/ItemActionMenuButton';
import TableBuilder, {
  ColumnDefinitionType,
  ColumnDefinitionCellActionsType,
  ColumnDefinitionCellContentType,
  RowHookType,
  DisplayContentProps,
  ItemFunctionReturnVoidType,
  DefaultItemType,
} from '@shared/design/components/Table/components/TableBuilder';

const Card = Styled.View`
`;

const CardHeaderContainer = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.white};
`;

const CardBodyContainer = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.gray.background};
`;

const CardTitleTextContainer = Styled.View`
  flex: 1;
`;

const CardHeaderButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const CardInformationRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CardInformationLabelContainer = Styled.View`
  flex: 2;
  max-width: 200px;
`;

const CardInformationLabel = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const CardInformationValueContainer = Styled.View`
  flex: 3;
`;

const sortColumnsForCard = (columnDefinitions: ColumnDefinitionCellContentType[]) => {
  return columnDefinitions.sort((a, b) => {
    const rankA = a.mobileOptions?.rank ?? Number.MAX_SAFE_INTEGER;
    const rankB = b.mobileOptions?.rank ?? Number.MAX_SAFE_INTEGER;
    return rankA - rankB;
  });
};

type ColumnsForCard = {
  headerColumnDefinitions: ColumnDefinitionCellContentType[];
  bodyColumnDefinitions: ColumnDefinitionCellContentType[];
  actionColumnDefinitions: ColumnDefinitionCellActionsType[];
};
const getColumnDefinitionsForCard = ({
  columnDefinitions,
}: {
  columnDefinitions: ColumnDefinitionType[];
}) => {
  const {headerColumnDefinitions, bodyColumnDefinitions, actionColumnDefinitions}: ColumnsForCard =
    _.reduce<ColumnDefinitionType, ColumnsForCard>(
      columnDefinitions,
      (result, column) => {
        if (TableBuilder.getColumnHasActions(column)) {
          result.actionColumnDefinitions.push(column);
        } else if (column.mobileOptions?.isInHeader) {
          result.headerColumnDefinitions.push(column);
        } else {
          result.bodyColumnDefinitions.push(column);
        }
        return result;
      },
      {
        headerColumnDefinitions: [],
        bodyColumnDefinitions: [],
        actionColumnDefinitions: [],
      },
    );
  const hasHeaderColumn = !!headerColumnDefinitions.length;
  const sortedHeaderColumns = sortColumnsForCard(
    hasHeaderColumn ? headerColumnDefinitions : bodyColumnDefinitions.slice(0, 1),
  );
  const sortedBodyColumns = sortColumnsForCard(
    hasHeaderColumn ? bodyColumnDefinitions : bodyColumnDefinitions.slice(1),
  );
  return {
    headerColumnDefinitions: sortedHeaderColumns,
    bodyColumnDefinitions: sortedBodyColumns,
    actionColumnDefinitions,
  };
};

const CardTitle = <T extends DefaultItemType = DefaultItemType>({
  definition,
  actionColumnDefinitions,
  item,
  rowIndex,
  isExpanded,
  setIsExpanded,
  hasBody,
  onRowPress,
  mobileRowPressLabel = 'View',
}: {
  definition: ColumnDefinitionCellContentType<T>;
  actionColumnDefinitions: ColumnDefinitionCellActionsType[];
  item: T;
  rowIndex: number;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  hasBody: boolean;
  onRowPress?: () => void;
  mobileRowPressLabel?: string;
}) => {
  const {
    cellIcon,
    cellIconColor,
    cellIconSize,
    cellText,
    emptyText,
    handlePressCellText,
    cellComponent,
  } = definition;

  return (
    <CardHeaderButton onPress={() => setIsExpanded(!isExpanded)} disabled={!hasBody}>
      {hasBody && (
        <React.Fragment>
          <IconButton
            isSecondary
            source={isExpanded ? 'angle-up' : 'angle-down'}
            onPress={() => setIsExpanded(!isExpanded)}
            isDisplayOnly
          />
          <Space width={12} />
        </React.Fragment>
      )}
      <CardTitleTextContainer>
        <TableBuilder.DisplayContent
          cellIcon={cellIcon}
          cellIconColor={cellIconColor}
          cellIconSize={cellIconSize}
          cellText={cellText}
          emptyText={emptyText}
          handlePressCellText={handlePressCellText}
          cellComponent={cellComponent}
          item={item}
          rowIndex={rowIndex}
          isTitle
        />
      </CardTitleTextContainer>
      {onRowPress && (
        <React.Fragment>
          <Space width={8} />
          <TertiaryButton onPress={onRowPress} text={mobileRowPressLabel} isResponsive />
        </React.Fragment>
      )}
      {_.some(actionColumnDefinitions) && (
        <React.Fragment>
          {!!onRowPress && <Space width={8} />}
          <ItemActionMenuButton columnDefinitions={actionColumnDefinitions} item={item} />
        </React.Fragment>
      )}
    </CardHeaderButton>
  );
};

const CardHeaderSection = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  actionColumnDefinitions,
  item,
  rowIndex,
  isExpanded,
  setIsExpanded,
  hasBody,
  onRowPress,
  mobileRowPressLabel,
  rowHook = TableBuilder.PLACEHOLDER_ROW_HOOK,
}: {
  columnDefinitions: ColumnDefinitionCellContentType<T>[];
  actionColumnDefinitions: ColumnDefinitionCellActionsType[];
  item: T;
  rowIndex: number;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  hasBody: boolean;
  onRowPress?: ItemFunctionReturnVoidType<T>;
  mobileRowPressLabel?: string;
  rowHook?: RowHookType;
}) => {
  const {isOpen, handleOpen, handleClose, key} = rowHook.hook(rowHook.hookArgument);

  return (
    <CardHeaderContainer>
      <Space height={12} />
      {columnDefinitions.map((definition: ColumnDefinitionCellContentType<T>, index: number) => {
        const {
          cellIcon,
          cellIconColor,
          cellIconSize,
          cellText,
          cellTextLink,
          headerLabel,
          emptyText,
          handlePressCellText,
          cellComponent,
          secondary,
        } = definition;
        const isFirstRow = index === 0;
        return (
          <React.Fragment key={index}>
            {!isFirstRow && <Space height={4} />}
            <CardInformationRow>
              {isFirstRow ? (
                <CardTitle
                  definition={definition}
                  actionColumnDefinitions={actionColumnDefinitions}
                  item={item}
                  rowIndex={rowIndex}
                  isExpanded={isExpanded}
                  setIsExpanded={setIsExpanded}
                  hasBody={hasBody}
                  onRowPress={
                    onRowPress
                      ? () => onRowPress(item, {isOpen, handleOpen, handleClose})
                      : undefined
                  }
                  mobileRowPressLabel={mobileRowPressLabel}
                />
              ) : (
                <TableBuilder.DisplayContent
                  cellIcon={cellIcon}
                  cellIconColor={cellIconColor}
                  cellIconSize={cellIconSize}
                  cellText={cellText}
                  cellTextLink={cellTextLink}
                  emptyLabel={headerLabel}
                  emptyText={emptyText}
                  handlePressCellText={handlePressCellText}
                  cellComponent={cellComponent}
                  item={item}
                  rowIndex={rowIndex}
                />
              )}
            </CardInformationRow>
            {secondary && !secondary.isHidden && (
              <React.Fragment>
                <Space height={4} />
                <TableBuilder.DisplayContent
                  cellIcon={secondary.cellIcon}
                  cellIconColor={secondary.cellIconColor}
                  cellIconSize={secondary.cellIconSize}
                  cellText={secondary.cellText}
                  cellTextLink={secondary.cellTextLink}
                  emptyLabel={secondary.headerLabel}
                  emptyText={secondary.emptyText}
                  handlePressCellText={secondary.handlePressCellText}
                  cellComponent={secondary.cellComponent}
                  item={item}
                  rowIndex={rowIndex}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
      <Space height={12} />
      {rowHook.renderComponent?.({item, isOpen, handleClose, hookKey: key})}
    </CardHeaderContainer>
  );
};

interface CardBodyInformationProps<T extends DefaultItemType = DefaultItemType>
  extends DisplayContentProps<T> {
  headerLabel?: string;
  rowIndex: number;
}
const CardBodyInformation = <T extends DefaultItemType = DefaultItemType>({
  headerLabel,
  cellIcon,
  cellIconColor,
  cellText,
  cellTextLink,
  emptyText,
  handlePressCellText,
  cellComponent,
  item,
  rowIndex,
}: CardBodyInformationProps<T>) => {
  const responsive = useResponsive();

  return (
    <CardInformationRow>
      <CardInformationLabelContainer>
        <CardInformationLabel responsive={responsive}>{headerLabel}</CardInformationLabel>
      </CardInformationLabelContainer>
      <Space width={16} />
      <CardInformationValueContainer>
        <TableBuilder.DisplayContent
          cellIcon={cellIcon}
          cellIconColor={cellIconColor}
          cellText={cellText}
          cellTextLink={cellTextLink}
          emptyText={emptyText}
          handlePressCellText={handlePressCellText}
          cellComponent={cellComponent}
          item={item}
          rowIndex={rowIndex}
        />
      </CardInformationValueContainer>
    </CardInformationRow>
  );
};

const CardBodySection = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  item,
  rowIndex,
}: {
  columnDefinitions: ColumnDefinitionCellContentType<T>[];
  item: T;
  rowIndex: number;
}) => {
  return (
    <CardBodyContainer>
      {columnDefinitions.map(
        (
          {
            headerLabel,
            cellIcon,
            cellIconColor,
            cellText,
            cellTextLink,
            emptyText,
            handlePressCellText,
            cellComponent,
            secondary,
          },
          index: number,
        ) => {
          return (
            <React.Fragment key={index}>
              <Space height={8} />
              <CardBodyInformation
                headerLabel={headerLabel}
                cellIcon={cellIcon}
                cellIconColor={cellIconColor}
                cellText={cellText}
                cellTextLink={cellTextLink}
                emptyText={emptyText}
                handlePressCellText={handlePressCellText}
                cellComponent={cellComponent}
                item={item}
                rowIndex={rowIndex}
              />
              {secondary && !secondary.isHidden && (
                <React.Fragment>
                  {!!secondary.headerLabel && <Space height={8} />}
                  <CardBodyInformation
                    headerLabel={secondary.headerLabel}
                    cellText={secondary.cellText}
                    cellTextLink={secondary.cellTextLink}
                    emptyText={secondary.emptyText}
                    handlePressCellText={secondary.handlePressCellText}
                    cellComponent={secondary.cellComponent}
                    item={item}
                    rowIndex={rowIndex}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        },
      )}
      <Space height={8} />
    </CardBodyContainer>
  );
};

export interface TableCardProps<T extends DefaultItemType = DefaultItemType> {
  mobileRowPressLabel?: string;
  onRowPress?: ItemFunctionReturnVoidType<T>;
  rowHook?: RowHookType;
  isRowSelected: boolean;
  item: T;
  rowIndex: number;
  columnDefinitions: ColumnDefinitionType<T>[];
}
const TableCard = <T extends DefaultItemType = DefaultItemType>({
  item,
  rowIndex,
  columnDefinitions,
  onRowPress,
  mobileRowPressLabel,
  rowHook,
}: TableCardProps<T>) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {headerColumnDefinitions, bodyColumnDefinitions, actionColumnDefinitions} =
    getColumnDefinitionsForCard({
      columnDefinitions,
    });
  const hasBody = _.some(bodyColumnDefinitions);
  return (
    <Card>
      <CardHeaderSection
        columnDefinitions={headerColumnDefinitions}
        actionColumnDefinitions={actionColumnDefinitions}
        item={item}
        rowIndex={rowIndex}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        hasBody={hasBody}
        onRowPress={onRowPress}
        mobileRowPressLabel={mobileRowPressLabel}
        rowHook={rowHook}
      />
      {hasBody && isExpanded && (
        <React.Fragment>
          <Line />
          <CardBodySection
            columnDefinitions={bodyColumnDefinitions}
            item={item}
            rowIndex={rowIndex}
          />
        </React.Fragment>
      )}
    </Card>
  );
};

export default TableCard;
