// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import DeleteOrganizationForm, {
  DeleteOrganizationFormType,
} from '@shared/modules/Organization/forms/DeleteOrganizationForm';

const useDeleteOrganizationMutation = ({
  deleteOrganizationForm,
  onSuccess,
  onError,
}: {
  deleteOrganizationForm: DeleteOrganizationFormType;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      deleteOrganizationForm: DeleteOrganizationForm.toForm(deleteOrganizationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteOrganizationMutation.mutation,
    variables: {
      deleteOrganizationForm: DeleteOrganizationForm.toMutation(form.values.deleteOrganizationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteOrganizationMutation.mutation = gql`
  mutation useDeleteOrganizationMutation($deleteOrganizationForm: DeleteOrganizationForm!) {
    response: deleteOrganization(deleteOrganizationForm: $deleteOrganizationForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useDeleteOrganizationMutation;
