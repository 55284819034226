// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gqlTyped as gql, useTypedQuery as useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {existenceFilter} from '@supermove/utils';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import GlobalDashboardForm from '@shared/modules/GlobalDashboard/forms/GlobalDashboardForm';
import useCreateGlobalDashboardMutation from '@shared/modules/GlobalDashboard/hooks/useCreateGlobalDashboardMutation';
import GlobalDashboardFields from 'modules/Dashboards/components/GlobalDashboardFields';

const CreateGlobalDashboardDrawer = ({
  isOpen,
  handleClose,
  handleView,
  refetch,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleView: ({globalDashboardUuid}: {globalDashboardUuid: string}) => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery(CreateGlobalDashboardDrawerQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const createGlobalDashboardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard created!',
  });
  const globalDashboardForm = GlobalDashboardForm.new();
  const {form, submitting, handleSubmit} = useCreateGlobalDashboardMutation({
    globalDashboardForm,
    onSuccess: ({globalDashboard}) => {
      createGlobalDashboardSuccessToast.handleToast({
        message: `'${globalDashboard.name}' created as draft!`,
      });
      refetch();
      handleClose();
      handleView({globalDashboardUuid: globalDashboard.uuid});
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={!form.dirty}
      headerText={'Add Report to Library'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
      isDisabled={loading}
    >
      <Loading loading={!data || loading}>
        {() =>
          data && (
            <GlobalDashboardFields
              form={form}
              dashboardCategories={data.dashboardCategories?.filter(existenceFilter) || []}
              dashboardTags={data.dashboardTags?.filter(existenceFilter) || []}
            />
          )
        }
      </Loading>
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const CreateGlobalDashboardDrawerQuery = gql(`
  query CreateGlobalDashboardDrawer {
    ...GlobalDashboardFields
  }
`);

export default CreateGlobalDashboardDrawer;
