// Libraries
import Image from '@tiptap/extension-image';
import Link, {LinkOptions} from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

// Supermove

import {
  CustomBackgroundColor,
  CustomColor,
  CustomHeading,
  CustomFontSize,
  CustomListItem,
  CustomTextAlign,
  KeepClasses,
  CustomOrderedList,
  UpdateListNesting,
  CustomBulletList,
} from './customExtensions';

// Types affected by text align
const textAlignTypes = ['paragraph', 'heading', 'orderedList', 'bulletList'];

const LinkConfigureParams: Partial<LinkOptions> = {
  openOnClick: false,
  autolink: false,
  defaultProtocol: 'https',
};

export const getFormattingEditorExtensions = (placeholder?: string) => {
  return [
    // We provide a custom list item, disable the default one
    StarterKit.configure({listItem: false, heading: false, orderedList: false, bulletList: false}),
    CustomListItem,
    // Email clients like gmail don't support base64 images, so don't let users add them
    CustomHeading,
    CustomBulletList,
    CustomOrderedList,
    Image.configure({inline: true, allowBase64: false}),
    KeepClasses,
    CustomColor,
    Link.configure(LinkConfigureParams),
    CustomFontSize,
    CustomTextAlign.configure({types: textAlignTypes}),
    CustomBackgroundColor,
    TextStyle,
    Underline,
    Placeholder.configure({placeholder}),
    UpdateListNesting,
  ];
};
