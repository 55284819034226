// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useModal, useDrawer} from '@supermove/hooks';
import {OrganizationModel, SupermoveProductModel} from '@supermove/models';
import {NavigationType, ParamsType} from '@supermove/navigation/src/NavigationTypes';
import {colors} from '@supermove/styles';
import {Datetime, List} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import AddSalesAppToCompanyModal from 'modules/Company/CompanyDetails/components/AddSalesAppToCompanyModal';
import UpdateSupermoveProductSettingsDrawer from 'modules/Company/CompanyDetails/components/UpdateSupermoveProductSettingsDrawer';

interface OrganizationQueryModel extends OrganizationModel {
  officeAppProduct: SupermoveProductModel;
  crewAppProduct: SupermoveProductModel;
  salesAppProduct: SupermoveProductModel;
}

const getColumnDefinitions = ({
  organization,
  refetch,
  navigator,
  params,
}: {
  organization: OrganizationModel;
  refetch: () => void;
  navigator: NavigationType;
  params: ParamsType;
}) => {
  return [
    {
      headerLabel: 'App',
      flex: 10,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        SupermoveProductKind.getLabel(supermoveProduct.kind),
    },
    {
      headerLabel: 'Status',
      flex: 10,
      cellComponent: (supermoveProduct: SupermoveProductModel) => (
        <Badge
          label={SupermoveProductStatus.getDisplay(supermoveProduct.status)}
          color={
            supermoveProduct.status === SupermoveProductStatus.ACTIVE
              ? colors.green.status
              : colors.gray.secondary
          }
          isResponsive
        />
      ),
    },
    {
      headerLabel: 'Deployed Date',
      flex: 10,
      cellText: (supermoveProduct: SupermoveProductModel) =>
        supermoveProduct.deployedDate
          ? Datetime.convertToDisplayDate(
              supermoveProduct.deployedDate,
              Datetime.DISPLAY_SHORT_DATE,
            )
          : null,
    },
    {
      headerLabel: 'Actions',
      flex: 1,
      minWidth: 50,
      cellStyle: {alignItems: 'center'},
      actions: (supermoveProduct: SupermoveProductModel) => [
        ...List.insertIf(supermoveProduct.status === SupermoveProductStatus.ACTIVE, {
          text: 'Edit',
          onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
          actionHook: {
            hook: useDrawer,
            hookArgument: {
              name: `Update Supermove Product Settings: ${SupermoveProductKind.getLabel(supermoveProduct.kind)}`,
            },
            renderComponent: ({
              isOpen,
              handleClose,
            }: {
              isOpen: boolean;
              handleClose: () => void;
            }) => {
              return (
                <UpdateSupermoveProductSettingsDrawer
                  isOpen={isOpen}
                  handleClose={handleClose}
                  refetch={refetch}
                  supermoveProduct={supermoveProduct}
                />
              );
            },
          },
        }),
        ...List.insertIf(
          supermoveProduct.status === SupermoveProductStatus.INACTIVE &&
            supermoveProduct.kind === SupermoveProductKind.SALES,
          {
            text: 'Add',
            onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
            actionHook: {
              hook: useModal,
              hookArgument: {
                name: 'Add Sales App To Company Modal',
              },
              renderComponent: ({
                isOpen,
                handleClose,
              }: {
                isOpen: boolean;
                handleClose: () => void;
              }) => {
                return (
                  <AddSalesAppToCompanyModal
                    isOpen={isOpen}
                    handleClose={handleClose}
                    refetch={refetch}
                    organization={organization}
                  />
                );
              },
            },
          },
        ),
        ...List.insertIf(
          supermoveProduct.status === SupermoveProductStatus.ACTIVE &&
            supermoveProduct.kind === SupermoveProductKind.SALES,
          {
            text: 'View',
            onPress: () =>
              navigator.navigate('CompanyDetailsAiSalesCopilotGeneralPage', {slug: params.slug}),
          },
        ),
      ],
    },
  ];
};

const createDefaultSupermoveProductItem = (kind: string) => ({
  id: `default-${kind}`,
  kind,
  status: SupermoveProductStatus.INACTIVE,
  deployedDate: null,
});

const getSupermoveProductItems = (
  officeApp: SupermoveProductModel | null,
  crewApp: SupermoveProductModel | null,
  salesApp: SupermoveProductModel | null,
) => {
  return [
    officeApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.OFFICE),
    crewApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.CREW),
    salesApp ?? createDefaultSupermoveProductItem(SupermoveProductKind.SALES),
  ];
};

// Needed to fix compatibility with the TableBuilder ItemType
interface SupermoveProductItem extends SupermoveProductModel {
  [key: string]: unknown;
}

const CompanyDetailsProductsPageContent = ({
  organization,
  refetch,
}: {
  organization: OrganizationQueryModel;
  refetch: () => void;
}) => {
  const {navigator, params} = useNavigation();
  const supermoveProducts = getSupermoveProductItems(
    organization.officeAppProduct,
    organization.crewAppProduct,
    organization.salesAppProduct,
  ) as SupermoveProductItem[];

  return (
    <Table
      items={supermoveProducts}
      itemKey={'id'}
      columnDefinitions={getColumnDefinitions({organization, refetch, navigator, params})}
      hasBorder
    />
  );
};

const CompanyDetailsProductsPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(CompanyDetailsProductsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'company/products'}
      pageTitle={'Products'}
      pageDescription={'Add and configure Supermove products for this company.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          const {organization} = data;

          return (
            <CompanyDetailsProductsPageContent organization={organization} refetch={refetch} />
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsProductsPage.query = gql`
  ${AddSalesAppToCompanyModal.fragment}
  ${UpdateSupermoveProductSettingsDrawer.fragment}

  query CompanyDetailsProductsPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      officeAppProduct: supermoveProduct(kind: "OFFICE") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      crewAppProduct: supermoveProduct(kind: "CREW") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      salesAppProduct: supermoveProduct(kind: "SALES") {
        id
        kind
        status
        deployedDate
        ...UpdateSupermoveProductSettingsDrawer
      }
      ...AddSalesAppToCompanyModal
    }
  }
`;

export default CompanyDetailsProductsPage;
