import {EnvironmentType, EnvironmentKeys, EnvironmentVariablesType} from './EnvironmentType';

/**
 * Must be kept in sync with tools/webpack/src/env.ts
 */
const envVars: {[K in EnvironmentKeys]?: EnvironmentType[K] | undefined} = {
  // NOTE(mark): Returns the GATSBY prefixed variables which are the only
  // environment variables that are exposed on the client side javascript.
  // We have to use a switch/case here because of how gatsby compiles the
  // env variables at build time.
  APP_BUILD: process.env.GATSBY_APP_BUILD,
  APP_ENV: process.env.GATSBY_APP_ENV,
  APP_NAME: process.env.GATSBY_APP_NAME,
  APP_VERSION: process.env.GATSBY_APP_VERSION,
  BRAINFISH_API_KEY: process.env.BRAINFISH_API_KEY,
  COHERE_KEY: process.env.COHERE_KEY,
  RENDER_GIT_COMMIT: process.env.RENDER_GIT_COMMIT,
  SENTRY_TRACES_SAMPLE_RATE: process.env.GATSBY_SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_URL: process.env.GATSBY_SENTRY_URL,
  SENTRY_ENABLE_SESSION_REPLAY: process.env.GATSBY_SENTRY_ENABLE_SESSION_REPLAY === 'true',
  NODE_ENV: process.env.NODE_ENV,
  USERFLOW_KEY: process.env.USERFLOW_KEY,
  SHOW_MAINTENANCE_PAGE: process.env.SHOW_MAINTENANCE_PAGE === 'true',
  ZENDESK_WEB_WIDGET_KEY: process.env.ZENDESK_WEB_WIDGET_KEY,
  LAUNCH_DARKLY_CLIENT_KEY: process.env.LAUNCH_DARKLY_CLIENT_KEY,
  LAUNCH_DARKLY_MOBILE_KEY: process.env.LAUNCH_DARKLY_MOBILE_KEY,
  RUTTER_PUBLIC_KEY: process.env.RUTTER_PUBLIC_KEY,
  VITALLY_TOKEN: process.env.VITALLY_TOKEN,
};

const EnvironmentVariables: EnvironmentVariablesType = {
  get: <T extends EnvironmentKeys>(key: T) => {
    return envVars[key] as EnvironmentType[T] | undefined;
  },
};

export default EnvironmentVariables;
