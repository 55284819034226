// Supermove
import {Icon} from '@supermove/components';

// App
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';

// Trigger Categories
const CONFIRMATION = 'CONFIRMATION';
const QUOTE = 'QUOTE';
const DOCUMENTS = 'DOCUMENTS';
const DOCUMENT_FLOW = 'DOCUMENT_FLOW';
const PROJECT = 'PROJECT';
const JOB = 'JOB';
const TASK = 'TASK';
const SCHEDULE = 'SCHEDULE';
const TAGS = 'TAGS';
const SMS = 'SMS';
const PAYMENT = 'PAYMENT';
const STAFF = 'STAFF';
const GPS = 'GPS';

// Confirmation
const CONFIRMATION_COMPLETED = 'CONFIRMATION_COMPLETED';
const CONFIRMATION_SENT = 'CONFIRMATION_SENT';
const CONFIRMATION_SKIPPED = 'CONFIRMATION_SKIPPED';

// Quote
const QUOTE_SENT = 'QUOTE_SENT';
const QUOTE_ACCEPTED = 'QUOTE_ACCEPTED';

// Project
const PROJECT_CREATED = 'PROJECT_CREATED';
const INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED = 'INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED';
const PROJECT_VALUE_UPDATED = 'PROJECT_VALUE_UPDATED';
const PROJECT_COMPLETED = 'PROJECT_COMPLETED';
const PROJECT_CANCELLED = 'PROJECT_CANCELLED';

// Staff
const PROJECT_SALESPERSON_CHANGED = 'PROJECT_SALESPERSON_CHANGED';
const PROJECT_COORDINATOR_CHANGED = 'PROJECT_COORDINATOR_CHANGED';
const PROJECT_ADDITIONAL_SALESPERSON_CHANGED = 'PROJECT_ADDITIONAL_SALESPERSON_CHANGED';

// Payment
const CUSTOMER_AUTOPAY_FAILED = 'CUSTOMER_AUTOPAY_FAILED';
const CUSTOMER_AUTOPAY_SUCCEEDED = 'CUSTOMER_AUTOPAY_SUCCEEDED';

// Storage Project
const STORAGE_PROJECT_CANCELLED = 'STORAGE_PROJECT_CANCELLED';

// Job
const JOB_CREATED = 'JOB_CREATED';
const JOB_COMPLETED = 'JOB_COMPLETED';
const JOB_CANCELLED = 'JOB_CANCELLED';
const PREPARE_STARTED = 'PREPARE_STARTED';
const TRACKING_STARTED = 'TRACKING_STARTED';
const CREW_LEFT_WAREHOUSE = 'CREW_LEFT_WAREHOUSE';
const TRACKING_ARRIVED = 'TRACKING_ARRIVED';
const CREW_RETURNED_TO_WAREHOUSE = 'CREW_RETURNED_TO_WAREHOUSE';

// Tag
const PROJECT_TAG_ADDED = 'PROJECT_TAG_ADDED';
const PROJECT_TAG_REMOVED = 'PROJECT_TAG_REMOVED';
const JOB_TAG_ADDED = 'JOB_TAG_ADDED';
const JOB_TAG_REMOVED = 'JOB_TAG_REMOVED';

// Schedule
const SCHEDULED_DAYS_BEFORE_JOB = 'SCHEDULED_DAYS_BEFORE_JOB';
const SCHEDULED_DAYS_BEFORE_PROJECT = 'SCHEDULED_DAYS_BEFORE_PROJECT';

// SMS
const TEXT_MESSAGE_RECEIVED = 'TEXT_MESSAGE_RECEIVED';

// Document flow
const PROJECT_DOCUMENT_FLOW_RUN_SENT = 'PROJECT_DOCUMENT_FLOW_RUN_SENT';
const PROJECT_DOCUMENT_FLOW_RUN_COMPLETED = 'PROJECT_DOCUMENT_FLOW_RUN_COMPLETED';
const PROJECT_DOCUMENT_FLOW_RUN_EXPIRED = 'PROJECT_DOCUMENT_FLOW_RUN_EXPIRED';
const PROJECT_DOCUMENT_FLOW_RUN_VIEWED = 'PROJECT_DOCUMENT_FLOW_RUN_VIEWED';
const PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS = 'PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS';

const CATEGORIES = [CONFIRMATION, DOCUMENT_FLOW, QUOTE, PROJECT, SCHEDULE, PAYMENT, STAFF, GPS];
const JOB_LEVEL_WORKFLOW_IDENTIFIERS = [
  JOB_COMPLETED,
  JOB_CREATED,
  SCHEDULED_DAYS_BEFORE_JOB,
  JOB_TAG_ADDED,
  JOB_TAG_REMOVED,
  JOB_CANCELLED,
  PREPARE_STARTED,
  TRACKING_STARTED,
  CREW_LEFT_WAREHOUSE,
  TRACKING_ARRIVED,
  CREW_RETURNED_TO_WAREHOUSE,
];
const PROJECT_LEVEL_WORKFLOW_IDENTIFIERS = [
  PROJECT_CREATED,
  PROJECT_CANCELLED,
  PROJECT_COMPLETED,
  PROJECT_VALUE_UPDATED,
  INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED,
  PROJECT_TAG_ADDED,
  PROJECT_TAG_REMOVED,
  TEXT_MESSAGE_RECEIVED,
  CUSTOMER_AUTOPAY_FAILED,
  CUSTOMER_AUTOPAY_SUCCEEDED,
  PROJECT_SALESPERSON_CHANGED,
  PROJECT_COORDINATOR_CHANGED,
  PROJECT_ADDITIONAL_SALESPERSON_CHANGED,
];

const CATEGORY_ATTRIBUTES = {
  [CONFIRMATION]: {
    label: 'Confirmation',
    icon: Icon.CheckCircle,
    triggers: [CONFIRMATION_COMPLETED, CONFIRMATION_SENT, CONFIRMATION_SKIPPED],
  },
  [DOCUMENT_FLOW]: {
    label: 'Document Flow',
    icon: Icon.ClipboardListCheck,
    triggers: [
      PROJECT_DOCUMENT_FLOW_RUN_SENT,
      PROJECT_DOCUMENT_FLOW_RUN_COMPLETED,
      PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS,
    ],
  },
  [QUOTE]: {
    label: 'Quote',
    icon: Icon.FileInvoice,
    triggers: [QUOTE_SENT, QUOTE_ACCEPTED],
  },
  [PROJECT]: {
    label: 'Project',
    icon: Icon.Folder,
    triggers: [
      PROJECT_CREATED,
      INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED,
      PROJECT_VALUE_UPDATED,
      PROJECT_CANCELLED,
      PROJECT_COMPLETED,
    ],
  },
  [SCHEDULE]: {
    label: 'Schedule',
    icon: Icon.Calendar,
    triggers: [SCHEDULED_DAYS_BEFORE_JOB, SCHEDULED_DAYS_BEFORE_PROJECT],
  },
  [JOB]: {
    label: 'Job',
    icon: Icon.Truck,
    triggers: [JOB_CREATED, JOB_COMPLETED, JOB_CANCELLED, PREPARE_STARTED],
  },
  [TAGS]: {
    label: 'Tags',
    icon: Icon.Tag,
    triggers: [PROJECT_TAG_ADDED, PROJECT_TAG_REMOVED, JOB_TAG_ADDED, JOB_TAG_REMOVED],
  },
  [SMS]: {
    label: 'SMS',
    icon: Icon.CommentSms,
    triggers: [TEXT_MESSAGE_RECEIVED],
  },
  [PAYMENT]: {
    label: 'Payment',
    icon: Icon.DollarSign,
    triggers: [CUSTOMER_AUTOPAY_FAILED, CUSTOMER_AUTOPAY_SUCCEEDED],
  },
  [STAFF]: {
    label: 'Staff',
    icon: Icon.User,
    triggers: [
      PROJECT_COORDINATOR_CHANGED,
      PROJECT_SALESPERSON_CHANGED,
      PROJECT_ADDITIONAL_SALESPERSON_CHANGED,
    ],
  },
  [GPS]: {
    label: 'GPS',
    icon: Icon.MapPin,
    triggers: [TRACKING_STARTED, CREW_LEFT_WAREHOUSE, TRACKING_ARRIVED, CREW_RETURNED_TO_WAREHOUSE],
  },
  // NOTE(dan) Categories that are not in V1
  [DOCUMENTS]: {
    label: 'Document',
    icon: Icon.FileAlt,
    triggers: [],
  },
  [TASK]: {
    label: 'Task',
    icon: Icon.ListUl,
    triggers: [],
  },
};

const getCategory = (workflowTriggerIdentifier: any) => {
  switch (workflowTriggerIdentifier) {
    case CONFIRMATION_COMPLETED:
    case CONFIRMATION_SENT:
    case CONFIRMATION_SKIPPED:
      return CONFIRMATION;
    case PROJECT_DOCUMENT_FLOW_RUN_SENT:
    case PROJECT_DOCUMENT_FLOW_RUN_COMPLETED:
    case PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS:
      return DOCUMENT_FLOW;
    case QUOTE_SENT:
    case QUOTE_ACCEPTED:
      return QUOTE;
    case PROJECT_CREATED:
    case INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED:
    case PROJECT_VALUE_UPDATED:
    case PROJECT_COMPLETED:
    case PROJECT_CANCELLED:
    case STORAGE_PROJECT_CANCELLED:
      return PROJECT;
    case CUSTOMER_AUTOPAY_FAILED:
    case CUSTOMER_AUTOPAY_SUCCEEDED:
      return PAYMENT;
    case SCHEDULED_DAYS_BEFORE_JOB:
    case SCHEDULED_DAYS_BEFORE_PROJECT:
      return SCHEDULE;
    case JOB_CREATED:
    case JOB_COMPLETED:
    case JOB_CANCELLED:
    case PREPARE_STARTED:
      return JOB;
    case JOB_TAG_ADDED:
    case PROJECT_TAG_ADDED:
    case JOB_TAG_REMOVED:
    case PROJECT_TAG_REMOVED:
      return TAGS;
    case TEXT_MESSAGE_RECEIVED:
      return SMS;
    case PROJECT_COORDINATOR_CHANGED:
    case PROJECT_SALESPERSON_CHANGED:
    case PROJECT_ADDITIONAL_SALESPERSON_CHANGED:
      return STAFF;
    case TRACKING_STARTED:
    case CREW_LEFT_WAREHOUSE:
    case TRACKING_ARRIVED:
    case CREW_RETURNED_TO_WAREHOUSE:
      return GPS;
    default:
      return '';
  }
};

const VARIABLE_IDENTIFIER_TO_DISPLAY = {
  [HardcodedSystemVariables.ALL_VARIABLES.PROJECT_TOTAL_REVENUE.value]:
    'Project Grand Total Updated',
};

const getDisplay = (workflowTriggerIdentifier: any, {triggerVariableIdentifier}: any = {}) => {
  switch (workflowTriggerIdentifier) {
    case CONFIRMATION_COMPLETED:
      return 'Confirmation Completed';
    case CONFIRMATION_SENT:
      return 'Confirmation Sent';
    case CONFIRMATION_SKIPPED:
      return 'Confirmation Skipped';
    case PROJECT_DOCUMENT_FLOW_RUN_SENT:
      return 'Document Flow Sent';
    case PROJECT_DOCUMENT_FLOW_RUN_COMPLETED:
      return 'Document Flow Completed';
    case PROJECT_DOCUMENT_FLOW_RUN_EXPIRED:
      return 'Document Flow Expired';
    case PROJECT_DOCUMENT_FLOW_RUN_VIEWED:
      return 'Document Flow Viewed';
    case PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS:
      return 'Document Flow In Progress';
    case QUOTE_SENT:
      return 'Quote Sent';
    case QUOTE_ACCEPTED:
      return 'Quote Accepted';
    case PROJECT_CREATED:
      return 'Project Created';
    case INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED:
      return 'Virtual Walkthrough Submitted';
    case PROJECT_VALUE_UPDATED:
      return triggerVariableIdentifier
        ? VARIABLE_IDENTIFIER_TO_DISPLAY[triggerVariableIdentifier]
        : 'Project Variable Updated';
    case SCHEDULED_DAYS_BEFORE_JOB:
      return 'Before Job Start';
    case SCHEDULED_DAYS_BEFORE_PROJECT:
      return 'Before Project Start';
    case JOB_CREATED:
      return 'Job Created';
    case JOB_COMPLETED:
      return 'Job Completed';
    case PROJECT_TAG_ADDED:
      return 'Project Tag Added';
    case PROJECT_TAG_REMOVED:
      return 'Project Tag Removed';
    case JOB_TAG_ADDED:
      return 'Job Tag Added';
    case JOB_TAG_REMOVED:
      return 'Job Tag Removed';
    case TEXT_MESSAGE_RECEIVED:
      return 'Text Message Received';
    case PROJECT_CANCELLED:
      return 'Project Cancelled';
    case PROJECT_COMPLETED:
      return 'Project Completed';
    case STORAGE_PROJECT_CANCELLED:
      return 'Storage Project Cancelled';
    case JOB_CANCELLED:
      return 'Job Cancelled';
    case CUSTOMER_AUTOPAY_FAILED:
      return 'Storage Autopay Failed';
    case CUSTOMER_AUTOPAY_SUCCEEDED:
      return 'Storage Autopay Succeeded';
    case PROJECT_COORDINATOR_CHANGED:
      return 'Project Coordinator Changed';
    case PROJECT_SALESPERSON_CHANGED:
      return 'Project Salesperson Changed';
    case PROJECT_ADDITIONAL_SALESPERSON_CHANGED:
      return 'Project Additional Salesperson Changed';
    case PREPARE_STARTED:
      return 'Job Started';
    case TRACKING_STARTED:
      return 'GPS Tracking Started';
    case CREW_LEFT_WAREHOUSE:
      return 'GPS Crew Left Warehouse';
    case TRACKING_ARRIVED:
      return 'GPS Crew Arrived';
    case CREW_RETURNED_TO_WAREHOUSE:
      return 'GPS Crew Returned to Warehouse';
    default:
      return '';
  }
};

const getFilteredCategory = (workflow: any) => {
  let categories = [CONFIRMATION, QUOTE, PROJECT, SCHEDULE, JOB, TAGS];

  if (workflow.organization.features.isEnabledSmsReceivedWorkflowTrigger) {
    categories = [...categories, SMS];
  }

  if (workflow.organization.features.isEnabledPaymentsAutomationTrigger) {
    categories = [...categories, PAYMENT];
  }

  if (workflow.organization.features.isEnabledAutomationSendProposalJobStatusStaffAssigned) {
    categories = [...categories, STAFF, GPS];
  }

  if (workflow.organization.features.isEnabledDocumentFlowAutomation) {
    categories = [...categories, DOCUMENT_FLOW];
  }

  return categories;
};

const isJobTypeRequiredWorkflow = (triggerIdentifier: any) => {
  // Add to this list for workflows that requires job type, match it with
  // workflow form validate_trigger_requires_job_type
  const requiredJobTypeWorkflow = [
    SCHEDULED_DAYS_BEFORE_JOB,
    JOB_COMPLETED,
    JOB_CREATED,
    JOB_TAG_ADDED,
    JOB_TAG_REMOVED,
    JOB_CANCELLED,
    PREPARE_STARTED,
    TRACKING_STARTED,
    CREW_LEFT_WAREHOUSE,
    TRACKING_ARRIVED,
    CREW_RETURNED_TO_WAREHOUSE,
  ];

  return requiredJobTypeWorkflow.includes(triggerIdentifier);
};

const WorkflowTriggerIdentifiers = {
  CONFIRMATION_COMPLETED,
  CONFIRMATION_SENT,
  CONFIRMATION_SKIPPED,
  PROJECT_DOCUMENT_FLOW_RUN_SENT,
  PROJECT_DOCUMENT_FLOW_RUN_COMPLETED,
  PROJECT_DOCUMENT_FLOW_RUN_EXPIRED,
  PROJECT_DOCUMENT_FLOW_RUN_VIEWED,
  PROJECT_DOCUMENT_FLOW_RUN_IN_PROGRESS,
  QUOTE_SENT,
  QUOTE_ACCEPTED,
  PROJECT_CREATED,
  INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED,
  PROJECT_VALUE_UPDATED,
  SCHEDULED_DAYS_BEFORE_JOB,
  SCHEDULED_DAYS_BEFORE_PROJECT,
  JOB_COMPLETED,
  JOB_CREATED,
  PROJECT_TAG_ADDED,
  PROJECT_TAG_REMOVED,
  JOB_TAG_ADDED,
  JOB_TAG_REMOVED,
  TEXT_MESSAGE_RECEIVED,
  PROJECT_COMPLETED,
  PROJECT_CANCELLED,
  STORAGE_PROJECT_CANCELLED,
  JOB_CANCELLED,
  CUSTOMER_AUTOPAY_FAILED,
  CUSTOMER_AUTOPAY_SUCCEEDED,
  PROJECT_COORDINATOR_CHANGED,
  PROJECT_SALESPERSON_CHANGED,
  PROJECT_ADDITIONAL_SALESPERSON_CHANGED,
  PREPARE_STARTED,
  TRACKING_STARTED,
  CREW_LEFT_WAREHOUSE,
  TRACKING_ARRIVED,
  CREW_RETURNED_TO_WAREHOUSE,

  CATEGORIES,
  CATEGORY_ATTRIBUTES,

  JOB_LEVEL_WORKFLOW_IDENTIFIERS,
  PROJECT_LEVEL_WORKFLOW_IDENTIFIERS,
  getCategory,
  getDisplay,
  getFilteredCategory,
  isJobTypeRequiredWorkflow,
};

export default WorkflowTriggerIdentifiers;
