// Libraries
import _ from 'lodash';

// Supermove
import {useNavigation, useNavigationDOM, useState, useMountEffect} from '@supermove/hooks';
import {Datetime, URL} from '@supermove/utils';

interface UseURLFiltersArgs<T extends Record<string, unknown>> {
  filterKeys: (keyof T)[];
  filtersForReset?: T;
  filtersForUpdate?: T;
  initialFilterValues?: Partial<T>;
  onUpdate?: (filters: T) => void;
  getRoute: () => string;
  navigationLib?: 'react-router-dom' | 'react-navigation';
}

const useUrlFilters = <T extends Record<string, unknown>>({
  filterKeys,
  filtersForReset,
  filtersForUpdate,
  initialFilterValues,
  onUpdate,
  getRoute,
  navigationLib = 'react-router-dom',
}: UseURLFiltersArgs<T>) => {
  const {navigator: reactRouterNavigator, params: routerDomParams} = useNavigationDOM();
  const {navigator: reactNavigationNavigator} = useNavigation();

  const currentParams =
    navigationLib === 'react-router-dom' ? routerDomParams : reactNavigationNavigator.state.params;

  const [resetKey, setResetKey] = useState(0);

  const getFilters = () => {
    return _.reduce<keyof T, T>(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: _.get(currentParams, key),
      }),
      {} as T,
    );
  };

  const handleUpdate = (updatedFilters: Record<string, unknown>, isPushRoute?: boolean) => {
    const currentFilters = getFilters();
    const filters = {
      ...currentFilters,
      ...(filtersForUpdate || {}),
      ...updatedFilters,
    };

    _navigate({
      navigationLib,
      reactRouterNavigator,
      reactNavigationNavigator,
      route: getRoute(),
      filters,
      isPushRoute,
    });

    onUpdate && onUpdate(filters);
  };

  // Find filters that are not set and apply initial values
  const initializeFilters = () => {
    if (!initialFilterValues) {
      return;
    }
    const currentFilters = getFilters();
    const filtersToApply = _.reduce<keyof T, Partial<T>>(
      filterKeys,
      (result, key) => {
        const value = currentFilters[key];
        if (_.isNil(value) && key in initialFilterValues) {
          return {...result, [key]: initialFilterValues[key]};
        }
        return result;
      },
      {},
    );

    if (!_.isEmpty(filtersToApply)) {
      handleUpdate(filtersToApply, false);
    }
  };
  useMountEffect(() => {
    initializeFilters();
  });

  const handleUpdateDate = (
    {date, dateKey}: {date: Date; dateKey: string},
    isPushRoute?: boolean,
  ) => {
    handleUpdate({[dateKey]: date ? Datetime.convertToDate(date) : ''}, isPushRoute);
  };

  const handleReset = (customFilters: Record<string, unknown>, isPushRoute?: boolean) => {
    const resetFilters = _.reduce(
      filterKeys,
      (result, key) => ({
        ...result,
        [key]: undefined,
      }),
      {},
    );

    const newFilters = {
      ...resetFilters,
      ...(filtersForReset || {}),
      ...(customFilters || {}),
    };

    _navigate({
      navigationLib,
      reactRouterNavigator,
      reactNavigationNavigator,
      route: getRoute(),
      filters: newFilters,
      isPushRoute,
    });

    setResetKey(resetKey + 1);
  };

  const getInputValueForDate = ({dateKey}: {dateKey: string}) => {
    const urlDateValue = currentParams[dateKey];
    const isValidDate = Datetime.isValidDateString(urlDateValue);
    if (isValidDate) {
      return Datetime.fromDate(urlDateValue);
    }
    return '';
  };

  const getFilterCount = ({filterKeys: selectKeys}: {filterKeys?: string[]} = {}) => {
    const filters = _.reduce(
      selectKeys || filterKeys,
      (result, key) => {
        const value = _.get(currentParams, key);
        if (_.isArray(value) && value.length === 0) {
          return result;
        }
        return [...result, value];
      },
      [] as string[],
    );

    return _.compact(filters).length;
  };

  const getFilterCountLabel = ({filterKeys: selectKeys}: {filterKeys?: string[]} = {}) => {
    const filterCount = getFilterCount({filterKeys: selectKeys});

    return `Filters${filterCount ? ` (${filterCount})` : ''}`;
  };

  const get = (filterKey: string) => {
    return _.get(currentParams, filterKey);
  };

  return {
    // Handlers
    handleUpdate, // Set any amount of filters
    handleUpdateDate, // For updating a Datetime (eg. from DateInput)
    handleReset, // Can take filters to set
    resetKey,

    // Input Helpers
    getInputValueForDate, // Transform date string to Datetime

    // Getters
    get, // Takes only a key as an argument
    getFilters, // Can use for query variables
    getFilterCount, // Count of applied filters
    getFilterCountLabel, // eg. "Filters (2)"
  };
};

interface NavigateArgs {
  navigationLib: 'react-router-dom' | 'react-navigation';
  reactRouterNavigator: any;
  reactNavigationNavigator: any;
  route: string;
  filters: Record<string, any>;
  isPushRoute?: boolean;
}

const _navigate = ({
  navigationLib,
  reactRouterNavigator,
  reactNavigationNavigator,
  route,
  filters,
  isPushRoute,
}: NavigateArgs) => {
  if (navigationLib === 'react-router-dom') {
    const navigate = _getNavigate({navigator: reactRouterNavigator, isPushRoute});
    navigate(URL.getUrlFromVariables(route, filters));
  } else {
    const navigate = _getNavigate({navigator: reactNavigationNavigator, isPushRoute});
    navigate(reactNavigationNavigator.state.routeName, {
      ...reactNavigationNavigator.state.params,
      ...filters,
    });
  }
};

interface GetNavigateArgs {
  navigator: any;
  isPushRoute?: boolean;
}

const _getNavigate = ({navigator, isPushRoute}: GetNavigateArgs) => {
  if (isPushRoute) {
    return navigator.push;
  }
  return navigator.replace;
};

export default useUrlFilters;
