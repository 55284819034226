// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

import {useCallback, useRef, useState} from '@supermove/hooks';
import {UseHoverType} from '@supermove/hooks/src/ui/useHover/UseHoverTypes';

const useHover: UseHoverType = ({hoverCheckType = 'overOut'} = {}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Wrap in useCallback so that these aren't redefined each render.
  const handleMouseOver = useCallback(() => setIsHovered(true), []);
  const handleMouseOut = useCallback(() => setIsHovered(false), []);

  // Store the latest node passed to the refCallback.
  const ref = useRef<Element | null | Text>();

  const refCallback = useCallback(
    (item: React.ReactInstance) => {
      // Whenever there is a new node, we need to remove the event listeners
      // from the previous reference and add to the new reference.
      if (ref.current) {
        ref.current.removeEventListener('mouseover', handleMouseOver);
        ref.current.removeEventListener('mouseout', handleMouseOut);
        ref.current.removeEventListener('mouseenter', handleMouseOver);
        ref.current.removeEventListener('mouseleave', handleMouseOut);
      }

      // We have to look up the actual DOM node.
      const node = ReactDOM.findDOMNode(item);
      ref.current = node;

      if (ref.current) {
        if (hoverCheckType === 'overOut') {
          ref.current.addEventListener('mouseover', handleMouseOver);
          ref.current.addEventListener('mouseout', handleMouseOut);
        } else {
          ref.current.addEventListener('mouseenter', handleMouseOver);
          ref.current.addEventListener('mouseleave', handleMouseOut);
        }
      }
    },
    [handleMouseOver, handleMouseOut],
  );

  return {
    isHovered,
    // This is the old way of using ref callbacks, but the typing doesn't show that anymore even if though it technically works
    ref: refCallback as unknown as ReturnType<UseHoverType>['ref'],
    handleToggleOff: () => setIsHovered(false),
  };
};

export default useHover;
