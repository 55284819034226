// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import CompanyIsActiveForm from '@shared/modules/Company/forms/CompanyIsActiveForm';
import useUpdateCompanyIsActiveMutation from '@shared/modules/Company/hooks/useUpdateCompanyIsActiveMutation';

const ReactivateCompanyModal = ({
  company,
  isOpen,
  handleClose,
}: {
  company: CompanyModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const companyIsActiveForm = CompanyIsActiveForm.edit(company, {isActive: true});
  const {handleSubmit, submitting} = useUpdateCompanyIsActiveMutation({
    companyIsActiveForm,
    onSuccess: handleClose,
    onError: (error: unknown) => {
      console.log({error});
    },
  });

  return (
    <ActionModal
      isOpen={isOpen}
      icon={Icon.CirclePlus}
      color={colors.blue.interactive}
      title={'Reactivate Company?'}
      message={'This company will be reactivated. Access permissions will be turned back on.'}
      handlePressOutside={handleClose}
      handleSecondaryAction={handleClose}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      primaryActionText={'Activate'}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReactivateCompanyModal.fragment = gql`
  ${CompanyIsActiveForm.edit.fragment}

  fragment ReactivateCompanyModal on Company {
    id
    ...CompanyIsActiveForm_edit
  }
`;

export default ReactivateCompanyModal;
