// Libraries
import _ from 'lodash';
import React from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Select, {components as SelectComponents} from 'react-select';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive, useSheet} from '@supermove/hooks';
import {Typography, colors, INPUT_BORDER_COLOR} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import Line from '@shared/design/components/Line';

const SELECT_ALL = 'SELECT_ALL';

const SelectOptionRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1
`;

const Container = Styled.View`
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const GrayText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const CheckboxContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${({desktop}: any) => (desktop ? '16px' : '18px')};
  height: ${({desktop}: any) => (desktop ? '16px' : '18px')};
  background-color: ${({isChecked}: any) => (isChecked ? colors.blue.interactive : colors.white)};
  border-color: ${({isChecked}: any) =>
    isChecked ? colors.blue.interactive : colors.gray.tertiary};
  border-radius: 4px;
  border-width: 2px;
`;

const controlStyle = {
  minHeight: 40,
  borderRadius: 3,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: INPUT_BORDER_COLOR,
};

const optionStyle = {
  display: 'block',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
};

const placeholderStyle = {
  fontFamily: 'Avenir',
  fontSize: 14,
  lineHeight: '19px',
  fontWeight: 300,
  color: colors.gray.tertiary,
};

const multiValueStyle = {
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 2,
};

const multiValueLabelStyle = {
  fontFamily: 'Avenir',
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const RemoveButton = Styled.ButtonV2`
  align-self: center;
  justify-content: center;
  padding-left: 4px;
`;

const GroupContainer = Styled.View`
  flex: 1;
`;

const GroupLabelText = Styled.Text`
  ${Typography.Label}
  text-transform: capitalize;
`;

const GroupDivider = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const getInputBackgroundColor = ({disabled, isDropdownSheet, required}: any) => {
  if (!disabled && isDropdownSheet) {
    return colors.white;
  }
  if (disabled) {
    return colors.gray.disabled;
  }
  if (required) {
    return colors.alpha(colors.yellow.hover, 0.1);
  }
  return colors.white;
};

const GroupLabel = ({label}: any) => {
  return (
    <React.Fragment>
      <GroupLabelText>{label}</GroupLabelText>
      <Space height={8} />
    </React.Fragment>
  );
};

const CheckboxOption = ({
  children,
  isSelected,
  data,
  overrideCheckboxes,
  isSectionedList,
  isDescriptionBelow,
  responsive,
  isEnabledSelectAll,
  isAllSelected,
  isAnySelected,
  customOptions,
  ...props
}: any) => {
  const hasDescription = data?.description;
  const hasDescriptionInline = hasDescription && !isDescriptionBelow;
  const hasDescriptionBelow = hasDescription && isDescriptionBelow;
  const isSelectAllOption = isEnabledSelectAll && data?.value === SELECT_ALL;
  const isOptionSelected = overrideCheckboxes || isSelected || (isSelectAllOption && isAllSelected);
  const isPartiallySelected = isSelectAllOption && !isAllSelected && isAnySelected;
  const isLastCustomOption =
    _.some(customOptions) && customOptions[customOptions.length - 1].value === data.value;
  const showDivider = isSelectAllOption || isLastCustomOption;

  if (data.isHidden) {
    return null;
  }

  return (
    <React.Fragment>
      <SelectComponents.Option {...props}>
        {/* HACK(dan): Because we reverted the global styles for the 'Group' component (see comment
        below in the customized Group component), we need to restore global styles for the 'Option'
        components that are children of the 'Group' component. */}
        <div id={'restoreGlobalStyles'}>
          <SelectOptionRow>
            {isSectionedList && <Space width={12} />}
            <CheckboxContainer isChecked={isOptionSelected || isPartiallySelected} {...responsive}>
              {isOptionSelected && <Icon color={colors.white} size={10} source={Icon.Check} />}
              {isPartiallySelected && <Icon color={colors.white} size={10} source={Icon.Minus} />}
            </CheckboxContainer>
            <Space width={8} />
            <Container>
              <Text responsive={responsive}>{children}</Text>
              {hasDescriptionBelow && (
                <GrayText responsive={responsive}>{data.description}</GrayText>
              )}
            </Container>
            {hasDescriptionInline && (
              <React.Fragment>
                <Space style={{flex: 1}} />
                <GrayText responsive={responsive}>{data.description}</GrayText>
              </React.Fragment>
            )}
          </SelectOptionRow>
        </div>
      </SelectComponents.Option>
      {showDivider && <Line />}
    </React.Fragment>
  );
};

// In react select, children[0] is the selecte values and children[1] is the input (search field).
// When truncating, we shift() children[0] to return only the first element, then append the input.
const TruncatedPillsContainer = ({getValue, children, ...props}: any) => {
  const responsive = useResponsive();
  const selectedCount = getValue().length;
  const isTruncating = selectedCount >= 3;
  return (
    <SelectComponents.ValueContainer {...props}>
      {isTruncating ? [children[0].shift(), children[1]] : children}
      <Space width={4} />
      <GrayText responsive={responsive}>{isTruncating && `+ ${selectedCount - 1} others`}</GrayText>
    </SelectComponents.ValueContainer>
  );
};

const handleUpdateValues = ({
  values,
  name,
  setFieldValue = () => {},
  onChangeValue,
}: {
  values: {value: unknown}[];
  name: string;
  setFieldValue?: (name: string, value: unknown) => void;
  onChangeValue: (value: unknown) => void;
}) => {
  const value = values.map(({value}: {value: unknown}) => value);
  setFieldValue(name, value);
  onChangeValue?.(value);
};

type FieldProps = {
  name: string;
  setFieldValue: (name: string, value: unknown) => void;
};
type NonFieldProps = {
  name?: never;
  setFieldValue?: never;
};
type MultiDropdownCheckboxInputProps = {
  disabled?: boolean;
  required?: boolean;
  isOptionDisabled?: (option: any) => boolean;
  label?: string;
  placeholder?: string;
  value: string[];
  options: any;
  customOptions?: {label: string; value: unknown}[];
  onChangeValue: (value: unknown) => void;
  onInputChange?: (value: string) => void;
  components?: any;
  style?: object;
  isPortaled?: boolean;
  usePills?: boolean;
  overrideCheckboxes?: boolean;
  onOptionAdded?: (value: unknown) => void;
  onOptionRemoved?: (value: unknown) => void;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDescriptionBelow?: boolean;
  isResponsive?: boolean;
  isEnabledSelectAll?: boolean;
  onSelectAll?: () => void;
} & (FieldProps | NonFieldProps);
const MultiDropdownCheckboxInput = ({
  disabled = false,
  required = false,
  isOptionDisabled = () => false,
  label,
  name = '',
  placeholder,
  value = [],
  options,
  customOptions = [],
  setFieldValue = () => {},
  onChangeValue,
  onInputChange = () => {},
  components = {},
  style,
  isPortaled = false,
  usePills = false,

  // HACK(cooper): This is an escape hatch for a situation where we want to mark all
  // checkboxes as if they're selected _without_ the actual values being selected
  overrideCheckboxes = false,

  // HACK(cooper): These two handlers are exposed for special circumstances and should not be
  // necessary for normal usage of this component
  onOptionAdded = () => {},
  onOptionRemoved = () => {},

  isLoading = false,
  isSearchable = false,
  isDescriptionBelow = false,
  isResponsive = false,
  isEnabledSelectAll = false,
  onSelectAll,
}: MultiDropdownCheckboxInputProps) => {
  const responsive = useResponsive();
  const displayDesktop = !isResponsive || responsive.desktop;
  const isDropdownSheet = !responsive.desktop;
  const mobileSheet = useSheet({name: 'Mobile Dropdown Sheet', enableTracking: false});
  const isSectionedList = options[0]?.options;

  // There are use cases where multiple options map to a single value. We consider equality if the unique options
  // are the same length as the selected values.
  const standardOptions = isSectionedList ? _.flatten(_.map(options, 'options')) : options;
  const standardOptionValues = _.map(standardOptions, 'value');
  const selectionsCount = value ? value.length : 0;
  const isAllSelected = selectionsCount === _.uniq(standardOptionValues).length;

  const customAndStandardOptions = [...customOptions, ...standardOptions];

  return (
    <React.Fragment>
      <DropdownInput.SheetButtonWrapper
        responsive={responsive}
        mobileSheet={mobileSheet}
        isDisabled={disabled}
        isResponsiveSheet
      >
        <Select
          isMulti
          maxMenuHeight={500}
          isLoading={isLoading}
          isDisabled={disabled || isDropdownSheet}
          isOptionDisabled={(option: any) => isOptionDisabled(option)}
          isSearchable={isSearchable}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          menuPortalTarget={isPortaled && document.body}
          name={name}
          placeholder={placeholder}
          value={_.map(value, (value) => _.find(customAndStandardOptions, {value}))}
          options={[
            ...List.insertIf(isEnabledSelectAll && _.some(options), {
              label: 'Select All',
              value: SELECT_ALL,
            }),
            ...customOptions,
            ..._.sortBy(options, (option: any) => !_.includes(value, option.value)),
          ]}
          formatGroupLabel={GroupLabel}
          onChange={(values: any, metadata: any) => {
            const isSelectAllPressed = isEnabledSelectAll && metadata.option?.value === SELECT_ALL;
            if (isSelectAllPressed) {
              const values = isAllSelected ? [] : standardOptions;
              handleUpdateValues({values, name, setFieldValue, onChangeValue});
              onSelectAll?.();
              return;
            }

            handleUpdateValues({values, name, setFieldValue, onChangeValue});

            switch (metadata.action) {
              case 'select-option':
                onOptionAdded(metadata.option.value);
                break;
              case 'deselect-option':
                onOptionRemoved(metadata.option.value);
                break;
              case 'remove-value':
                onOptionRemoved(metadata.removedValue.value);
                break;
              default:
                break;
            }
          }}
          onInputChange={(value: any) => onInputChange(value)}
          components={{
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            ...(usePills && {
              ValueContainer: TruncatedPillsContainer,
            }),
            ...(!usePills && {
              MultiValueContainer: ({selectProps, data}: any) => {
                const values = selectProps.value;
                if (_.some(values)) {
                  return (
                    <Text responsive={isResponsive ? responsive : null} numberOfLines={1}>
                      {values[values.length - 1].label === data.label
                        ? data.label
                        : `${data.label}, `}
                    </Text>
                  );
                } else return '';
              },
            }),
            MultiValueRemove: (props: any) => {
              if (!responsive.desktop || disabled) {
                return null;
              }
              return (
                <RemoveButton onPress={props.innerProps.onClick}>
                  <Icon
                    {...props}
                    source={Icon.Xmark}
                    size={12}
                    color={props.data.hasError ? colors.red.warning : colors.gray.secondary}
                  />
                </RemoveButton>
              );
            },
            DropdownIndicator: (props: any) => {
              return (
                <MultiDropdownCheckboxInput.DropdownIndicator {...props}>
                  <Icon
                    source={props.selectProps.menuIsOpen ? Icon.ChevronUp : Icon.ChevronDown}
                    size={displayDesktop ? 14 : 16}
                    color={colors.gray.secondary}
                    style={{marginLeft: 4, marginRight: 4, marginTop: 2}}
                  />
                </MultiDropdownCheckboxInput.DropdownIndicator>
              );
            },
            Group: (props: any) => {
              const allOptions = props.selectProps.options;
              const isFirstGroup = _.get(props, 'data.label') === _.get(allOptions, '0.label');
              const isLastGroup =
                _.get(props, 'data.label') === _.get(allOptions, `${allOptions.length - 1}.label`);

              // HACK(dan): When it is a sectioned list, the 'Group' container wraps the label and the
              // group options. However, there is an additional div that wraps the group options that
              // we need to target to revert the global styles.
              return (
                <div id={'revertGlobalStyles'}>
                  <GroupContainer>
                    {!isFirstGroup && <GroupDivider />}
                    <Space height={12} />
                    <SelectComponents.Group {...props} />
                    {!isLastGroup && <Space height={4} />}
                  </GroupContainer>
                </div>
              );
            },
            Option: (props: any) => (
              <CheckboxOption
                {...props}
                overrideCheckboxes={overrideCheckboxes}
                isSectionedList={isSectionedList}
                isDescriptionBelow={isDescriptionBelow}
                responsive={responsive}
                isEnabledSelectAll={isEnabledSelectAll}
                isAllSelected={isAllSelected}
                isAnySelected={_.some(value, (selectedValue) =>
                  _.includes(standardOptionValues, selectedValue),
                )}
                customOptions={customOptions}
              />
            ),
            ...components,
          }}
          styles={{
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            container: (current, state) => ({
              ...current,
              pointerEvents: 'auto',
              cursor: state.isDisabled ? 'not-allowed' : 'text',
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            control: (current, state) => ({
              ...current,
              ...controlStyle,
              pointerEvents: state.isDisabled ? 'none' : 'auto',
              minHeight: displayDesktop ? 36 : 48,
              height: 'auto',
              width: '100%',

              // Custom 'required' feature to change the background-color.
              backgroundColor: getInputBackgroundColor({disabled, isDropdownSheet, required}),
              ...style,
            }),
            menu: (current: any) => ({
              ...current,
              display: 'block',
            }),
            menuList: (current: any) => ({
              ...current,
              display: 'block',
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            multiValue: (current, state) => ({
              ...current,
              ...multiValueStyle,

              backgroundColor: disabled
                ? colors.gray.border
                : state.data.hasError
                  ? colors.red.accent
                  : colors.gray.border,
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            multiValueLabel: (current, state) => ({
              ...current,
              ...multiValueLabelStyle,
              color: state.data.hasError ? colors.red.warning : colors.gray.secondary,
            }),
            option: (current: any) => ({
              ...current,
              ...optionStyle,
            }),
            placeholder: (current: any) => ({
              ...current,
              ...placeholderStyle,
              fontSize: displayDesktop ? 14 : 16,
            }),
            // @ts-expect-error TS(7006): Parameter 'current' implicitly has an 'any' type.
            singleValue: (current, state) => ({
              ...current,
              color: state.isDisabled ? colors.gray.tertiary : colors.gray.primary,
            }),
            ...(!usePills && {
              valueContainer: (current: any) => ({
                ...current,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'block',
                input: {height: 0},
              }),
            }),
          }}
        />
      </DropdownInput.SheetButtonWrapper>
      {isDropdownSheet && (
        <DropdownSheet
          key={mobileSheet.key}
          isOpen={mobileSheet.isOpen}
          handleClose={mobileSheet.handleClose}
          headerText={label}
          options={customAndStandardOptions}
          name={name}
          value={value}
          onChangeValue={onChangeValue}
          setFieldValue={setFieldValue}
          onInputChange={onInputChange}
          showDescriptionInOption={isDescriptionBelow}
          isLoading={isLoading}
          isMultiSelect
          isEnabledSelectAll={isEnabledSelectAll}
          isAllSelected={isAllSelected}
          onSelectAll={() => {
            const values = isAllSelected ? [] : standardOptions;
            handleUpdateValues({values, name, setFieldValue, onChangeValue});
            onSelectAll?.();
          }}
        />
      )}
    </React.Fragment>
  );
};

MultiDropdownCheckboxInput.DropdownIndicator = SelectComponents.DropdownIndicator;
MultiDropdownCheckboxInput.CheckboxOption = CheckboxOption;

export default MultiDropdownCheckboxInput;
