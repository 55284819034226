// Libraries
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import Styled from '../Styled';

export interface LoadingProps<T, P> {
  data?: T | undefined;
  loading: boolean;
  alwaysUpdate?: boolean;
  as?: (props: P) => JSX.Element | null;
  children: ({loadedData}: {loadedData: T}) => React.ReactNode;
  loadingProps?: P;
}

// This is take from the @increment/loading component
class DataLoader<T, P extends Record<string, unknown>> extends React.Component<LoadingProps<T, P>> {
  shouldComponentUpdate(nextProps: LoadingProps<T, P>) {
    return !nextProps.loading || !!nextProps.alwaysUpdate;
  }

  render() {
    const {loading = true, as: Component = null, children, data, loadingProps} = this.props;

    if (!loading) {
      return children({loadedData: data as T});
    } else if (!Component) {
      return null;
    } else if (React.isValidElement(Component)) {
      return Component;
    }

    return (<Component {...(loadingProps as P)} />) as JSX.Element;
  }
}

// This is the PageLoadingIndicator component,
// but that's in another module and can't be imported here
const PageLoaderContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const PageLoadingIndicator = () => (
  <PageLoaderContainer>
    <Indicator size={'large'} color={colors.gray.secondary} />
  </PageLoaderContainer>
);

export const PageLoader = <T, P extends Record<string, unknown>>(props: LoadingProps<T, P>) => {
  return <DataLoader as={PageLoadingIndicator} {...props} />;
};

export default DataLoader;
