// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {Form} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {CompanyFormProps} from '@shared/modules/Company/forms/CompanyForm';

const CompanyDetailsFields = ({
  form,
  field,
}: {
  form: Form<{companyForm: CompanyFormProps}>;
  field: string;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Company Name'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter company name',
        }}
        handleBlur={(event) => {
          const text = event.target.value;
          // Automatically generate an identifier based on the company name
          // Lowercase, replace spaces with -, and remove any non-alphanumeric characters
          form.setFieldValue(`${field}.identifier`, text.toLowerCase().replace(/[^\w-]/g, ''));
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.identifier`}
        label={'Identifier'}
        isRequired
        isResponsive
        input={{
          placeholder: 'Enter identifier',
        }}
        tooltip={`Requirements:
  • Must be unique
  • Must contain at least 3 characters
  • Only letters, numbers, -, and _ are allowed
  • Cannot contain spaces`}
      />
    </React.Fragment>
  );
};

export default CompanyDetailsFields;
