// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation, useQuery, useResponsive, ResponsiveType} from '@supermove/hooks';
import {SettingsModel, UserModel} from '@supermove/models';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import SettingsSection from '@shared/modules/Settings/components/SettingsSection';
import SettingsSectionOption from '@shared/modules/Settings/components/SettingsSectionOption';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsStorageEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsStorageEnabledMutation';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';

const StorageSettingsContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const StorageSettings = ({
  settings,
  viewer,
  refetch,
}: {
  settings: SettingsModel;
  viewer: UserModel;
  refetch: () => void;
}) => {
  const settingsForm = SettingsForm.edit(settings, {updatedById: viewer.id});
  const {handleSubmit} = useToggleSettingsIsStorageEnabledMutation({
    settingsForm,
    onSuccess: refetch,
    onError: (errors: unknown) => console.log({errors}),
  });
  return (
    <SettingsSection>
      <SettingsSectionOption
        name={'Storage'}
        description={'Allows full access to the Storage module and integrated Storage features.'}
        isEnabled={settings.isStorageEnabled}
        onPress={handleSubmit}
      />
    </SettingsSection>
  );
};

const CompanyModulesStoragePage = () => {
  const {params} = useNavigation();
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompanyModulesStoragePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  return (
    <CompanyDetailsPage
      selectedLabel={'modules/storage'}
      pageTitle={'Storage'}
      pageDescription={
        'Configure storage features. These changes will apply to the company and, if applicable, its branches.'
      }
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <StorageSettingsContainer responsive={responsive}>
            <StorageSettings
              settings={data.company.primaryOrganization.settings}
              viewer={data.viewer}
              refetch={refetch}
            />
          </StorageSettingsContainer>
        )}
      </Loading>
    </CompanyDetailsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyModulesStoragePage.query = gql`
  ${SettingsForm.edit.fragment}

  query CompanyModulesStoragePage($slug: String!) {
    ${gql.query}
    viewer {
      id
    }
    company(slug: $slug) {
      id
      primaryOrganization {
        id
        settings {
          id
          isStorageEnabled
          ...SettingsForm_edit
        }
      }
    }
  }
`;

export default CompanyModulesStoragePage;
