// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import TreeNavigation from '@shared/design/components/TreeNavigation';
import DashboardStatus, {
  DashboardStatusType,
} from '@shared/modules/GlobalDashboard/enums/DashboardStatus';
import {GlobalDashboardSortKind} from '@shared/modules/GlobalDashboard/enums/GlobalDashboardSortKind';
import {GlobalDashboardsPageUrlFilters} from 'modules/Dashboards/components/types';

const NavigationContainer = Styled.View`
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
  height: 100%;
`;

const TabsContainer = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
`;

interface NavigationItem {
  label: string;
  value: string;
}

const getNavigationItems = ({
  items,
  countsByStatus,
}: {
  items: NavigationItem[];
  countsByStatus: Record<DashboardStatusType, number>;
}) =>
  items.map((item: NavigationItem) => ({
    key: item.value,
    label: `${item.label} (${countsByStatus[item.value as DashboardStatusType] || 0})`,
    value: item.value,
    valueKey: 'status',
  }));

const GlobalDashboardsPageNavigation = ({
  urlFilters,
  countsByStatus,
}: {
  urlFilters: GlobalDashboardsPageUrlFilters;
  countsByStatus: Record<DashboardStatusType, number>;
}) => {
  const responsive = useResponsive();
  const navigationItems = getNavigationItems({
    items: [
      {
        label: 'Published',
        value: DashboardStatus.PUBLISHED,
      },
      {
        label: 'Draft',
        value: DashboardStatus.DRAFT,
      },
      {
        label: 'Archived',
        value: DashboardStatus.ARCHIVED,
      },
    ],
    countsByStatus,
  });

  return responsive.desktop ? (
    <NavigationContainer>
      <Space height={16} />
      <TreeNavigation
        width={200}
        handleSetValues={({status}: {status: DashboardStatusType}) => {
          urlFilters.handleReset({status, sort: GlobalDashboardSortKind.ALPHABETICAL});
        }}
        navigationItems={navigationItems}
        values={urlFilters.getFilters()}
      />
      <Space height={16} />
    </NavigationContainer>
  ) : (
    <TabsContainer>
      <Space height={8} />
      <Tabs
        tabs={navigationItems}
        handlePressTab={(tab) => {
          urlFilters.handleReset({status: tab.value, sort: GlobalDashboardSortKind.ALPHABETICAL});
        }}
        activeTabIndex={navigationItems.findIndex(
          (navigationItem) => navigationItem.value === urlFilters.get('status'),
        )}
        tabStyle={{flex: 1}}
        isSpacedTabs
        isFullWidth
      />
    </TabsContainer>
  );
};

export default GlobalDashboardsPageNavigation;
