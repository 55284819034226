// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, ScrollView, Space, Styled} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import EmptyState from '@shared/design/components/EmptyState';
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import Line from '@shared/design/components/Line';
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import TableBuilder, {
  ColumnDefinitionType,
  RowHookType,
  ItemFunctionReturnVoidType,
  DefaultItemType,
} from '@shared/design/components/Table/components/TableBuilder';
import TableCard from '@shared/design/components/Table/components/TableCard';
import TableRow, {
  RowVerticalPaddingType,
} from '@shared/design/components/Table/components/TableRow';

const TableContentContainer = Styled.View`
  background-color: ${colors.white}
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  padding-horizontal: 8px;
  min-height: 16px;
`;

const HeaderLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const HeaderSecondaryLabel = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const EmptyStateContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 32px;
  justify-content: center;
  align-items: center;
  min-height: 120px;
`;

const LoadingContainer = Styled.View`
  padding: 16px;
`;

const BORDER_STYLE = {
  borderWidth: 1,
  borderRadius: 4,
};

const getTableBorderStyle = ({
  hasBorder,
  responsive,
}: {
  hasBorder?: boolean;
  responsive: ResponsiveType;
}) => {
  if (!_.isNil(hasBorder)) {
    return hasBorder ? BORDER_STYLE : {};
  }

  // Default to border on desktop and no border on mobile.
  // Assumption on mobile is that the table will be the
  // full display width.
  return responsive.desktop ? BORDER_STYLE : {};
};

const TableLoading = ({numberOfRows}: {numberOfRows: number}) => {
  const responsive = useResponsive();
  const loaderHeight = responsive.desktop ? 40 : 120;
  return (
    <LoadingContainer>
      {Array.from({length: numberOfRows}).map((_, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={16} />}
            <SkeletonLoader height={loaderHeight} />
          </React.Fragment>
        );
      })}
    </LoadingContainer>
  );
};

const TableEmpty = ({
  emptyStateTitle,
  emptyStateText,
  EmptyStateComponent,
}: {
  emptyStateTitle: string;
  emptyStateText: string;
  EmptyStateComponent?: React.FC;
}) => {
  if (EmptyStateComponent) {
    return <EmptyStateComponent />;
  }

  return (
    <EmptyStateContainer>
      <EmptyState title={emptyStateTitle} message={emptyStateText} />
    </EmptyStateContainer>
  );
};

const TablePlaceholder = ({
  columnDefinitions,
  hasError,
  emptyStateTitle,
  emptyStateText,
  EmptyStateComponent,
}: {
  columnDefinitions: ColumnDefinitionType[];
  hasError: boolean;
  emptyStateTitle: string;
  emptyStateText: string;
  EmptyStateComponent?: React.FC;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.desktop && (
        <React.Fragment>
          <TableHeader columnDefinitions={columnDefinitions} />
          <Line />
        </React.Fragment>
      )}
      {hasError ? (
        <EmptyStateContainer>
          <ErrorState />
        </EmptyStateContainer>
      ) : (
        <TableEmpty
          emptyStateTitle={emptyStateTitle}
          emptyStateText={emptyStateText}
          EmptyStateComponent={EmptyStateComponent}
        />
      )}
    </React.Fragment>
  );
};

const TableHeader = ({columnDefinitions}: {columnDefinitions: ColumnDefinitionType[]}) => {
  const responsive = useResponsive();

  return (
    <HeaderRow>
      {columnDefinitions.map((columnDefinition: ColumnDefinitionType, index: number) => {
        const {
          flex,
          minWidth,
          maxWidth,
          width,
          cellStyle = {},
          headerLabel,
          headerComponent,
        } = columnDefinition;
        return (
          <TableBuilder.Cell
            key={index}
            style={{
              flex,
              minWidth,
              maxWidth,
              width,
              ...cellStyle,
            }}
          >
            {headerComponent && headerComponent({})}
            {headerLabel && (
              <HeaderLabel responsive={responsive} numberOfLines={1}>
                {headerLabel}
              </HeaderLabel>
            )}
            {TableBuilder.getColumnHasSecondary(columnDefinition) &&
              columnDefinition.secondary?.headerLabel &&
              !columnDefinition.secondary.isHidden && (
                <React.Fragment>
                  <HeaderSecondaryLabel numberOfLines={1} responsive={responsive}>
                    {columnDefinition.secondary.headerLabel}
                  </HeaderSecondaryLabel>
                </React.Fragment>
              )}
          </TableBuilder.Cell>
        );
      })}
    </HeaderRow>
  );
};

interface TableBodyProps<T extends DefaultItemType = DefaultItemType> {
  columnDefinitions: ColumnDefinitionType<T>[];
  items: T[];
  itemKey?: string;
  onRowPress?: ItemFunctionReturnVoidType<T>;
  onRowHover?: (item: T | undefined, rowIndex: number) => void;
  emptyStateTitle?: string;
  emptyStateText?: string;
  EmptyStateComponent?: React.FC;
  isScrollable?: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  hasError?: boolean;
  hasBorder?: boolean;
  rowHook?: RowHookType;
  activeRowIndex?: number;
}
const TableBody = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  onRowHover,
  isScrollable,
  rowVerticalPadding,
  rowHook,
  activeRowIndex,
}: TableBodyProps<T>) => {
  return (
    <React.Fragment>
      {isScrollable ? (
        <FlatList<T>
          keyExtractor={(item: T, index: number) =>
            itemKey ? (_.get(item, itemKey) as React.Key) : index
          }
          data={items}
          renderItem={({item, index}: {item: T; index: number}) => (
            <React.Fragment>
              <TableRow
                key={itemKey ? (_.get(item, itemKey) as React.Key) : index}
                columnDefinitions={columnDefinitions}
                item={item}
                rowIndex={index}
                rowHook={rowHook}
                onRowPress={onRowPress}
                onRowHover={onRowHover}
                isRowSelected={index === activeRowIndex}
                rowVerticalPadding={rowVerticalPadding}
              />
              <Line />
            </React.Fragment>
          )}
          contentContainerStyle={{flex: 1}}
        />
      ) : (
        <React.Fragment>
          {items.map((item: T, index: number) => (
            <React.Fragment key={itemKey ? (_.get(item, itemKey) as React.Key) : index}>
              {index > 0 && <Line />}
              <TableRow
                columnDefinitions={columnDefinitions}
                item={item}
                rowIndex={index}
                rowHook={rowHook}
                onRowPress={onRowPress}
                onRowHover={onRowHover}
                isRowSelected={index === activeRowIndex}
                rowVerticalPadding={rowVerticalPadding}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

interface TableCardsListProps<T extends DefaultItemType = DefaultItemType>
  extends TableBodyProps<T> {
  mobileRowPressLabel?: string;
}
const TableCardsList = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  rowHook,
  activeRowIndex,
  mobileRowPressLabel,
  isScrollable,
}: TableCardsListProps<T>) => {
  return (
    <React.Fragment>
      {isScrollable ? (
        <FlatList
          keyExtractor={(item: T, index: number) =>
            itemKey ? (_.get(item, itemKey) as React.Key) : index
          }
          data={items}
          renderItem={({item, index}: {item: T; index: number}) => (
            <TableCard
              item={item}
              rowIndex={index}
              columnDefinitions={columnDefinitions}
              rowHook={rowHook}
              onRowPress={onRowPress}
              isRowSelected={index === activeRowIndex}
              mobileRowPressLabel={mobileRowPressLabel}
            />
          )}
          ItemSeparatorComponent={Line}
          style={{borderRadius: 4}}
        />
      ) : (
        <React.Fragment>
          {items.map((item: T, index: number) => {
            return (
              <React.Fragment key={itemKey ? (_.get(item, itemKey) as React.Key) : index}>
                {index > 0 && <Line />}
                <TableCard
                  item={item}
                  rowIndex={index}
                  columnDefinitions={columnDefinitions}
                  rowHook={rowHook}
                  onRowPress={onRowPress}
                  isRowSelected={index === activeRowIndex}
                  mobileRowPressLabel={mobileRowPressLabel}
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const TableContainer = ({
  minWidth,
  horizontalScrollViewStyle,
  style,
  children,
}: {
  minWidth?: number;
  horizontalScrollViewStyle?: React.CSSProperties;
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  if (responsive.desktop && minWidth) {
    return (
      <ScrollView
        horizontal
        style={horizontalScrollViewStyle}
        contentContainerStyle={{minWidth, width: '100%'}}
      >
        <TableContentContainer style={{...style, width: '100%'}}>{children}</TableContentContainer>
      </ScrollView>
    );
  }

  return <TableContentContainer style={style}>{children}</TableContentContainer>;
};

export interface BaseTableProps<T extends DefaultItemType = DefaultItemType>
  extends TableCardsListProps<T> {
  rowVerticalPadding?: RowVerticalPaddingType;
  containerStyle?: React.CSSProperties;
  isLoading?: boolean;
}
interface MinWidthProps {
  minWidth: number;
  horizontalScrollViewStyle?: React.CSSProperties;
}
type TableProps<T extends DefaultItemType = DefaultItemType> = BaseTableProps<T> &
  (MinWidthProps | {minWidth?: never; horizontalScrollViewStyle?: never});
const Table = <T extends DefaultItemType = DefaultItemType>({
  columnDefinitions,
  items,
  itemKey,
  onRowPress,
  onRowHover,
  emptyStateTitle = '',
  emptyStateText = 'No items to display',
  EmptyStateComponent,
  isLoading = false,
  isScrollable = false,
  minWidth,
  rowVerticalPadding,
  hasError = false,
  hasBorder,
  rowHook,
  activeRowIndex,
  containerStyle = {},
  horizontalScrollViewStyle,
}: TableProps<T>) => {
  const responsive = useResponsive();
  const filteredColumns = columnDefinitions.filter((column) => !column.isHidden);

  if (isLoading && !responsive.desktop) {
    return <TableLoading numberOfRows={5} />;
  }

  return (
    <TableContainer
      minWidth={minWidth}
      horizontalScrollViewStyle={horizontalScrollViewStyle}
      style={{
        flex: isScrollable && responsive.desktop ? 1 : undefined,
        flexShrink: isScrollable && !responsive.desktop ? 1 : undefined,
        ...getTableBorderStyle({hasBorder, responsive}),
        ...containerStyle,
      }}
    >
      {!isLoading && (hasError || _.isEmpty(items)) ? (
        <TablePlaceholder
          columnDefinitions={filteredColumns}
          hasError={hasError}
          emptyStateTitle={emptyStateTitle}
          emptyStateText={emptyStateText}
          EmptyStateComponent={EmptyStateComponent}
        />
      ) : responsive.desktop ? (
        <React.Fragment>
          <TableHeader columnDefinitions={filteredColumns} />
          <Line />
          {isLoading ? (
            <TableLoading numberOfRows={10} />
          ) : (
            <TableBody
              columnDefinitions={filteredColumns}
              items={items}
              itemKey={itemKey}
              onRowPress={onRowPress}
              onRowHover={onRowHover}
              isScrollable={isScrollable}
              rowVerticalPadding={rowVerticalPadding}
              rowHook={rowHook}
              activeRowIndex={activeRowIndex}
            />
          )}
        </React.Fragment>
      ) : (
        <TableCardsList
          columnDefinitions={filteredColumns}
          items={items}
          itemKey={itemKey}
          onRowPress={onRowPress}
          rowHook={rowHook}
          activeRowIndex={activeRowIndex}
          isScrollable={isScrollable}
        />
      )}
    </TableContainer>
  );
};

export const TableComponents = {
  PrimaryText: TableBuilder.PrimaryText,
  SecondaryText: TableBuilder.SecondaryText,
  LinkText: TableBuilder.LinkText,
  HeaderLabel,
  HeaderSecondaryLabel,
};

export default Table;
