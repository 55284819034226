// Libraries
import PropTypes from 'prop-types';
import React, {PropsWithChildren} from 'react';

// Supermove
import {Icon, IconSource} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

interface DeleteModalProps {
  isOpen: boolean;
  title: string;
  titleNumberOfLines?: number;
  subtitle?: string;
  handleClose?: () => void;
  handleDelete: () => void;
  deleteButtonText?: string;
  cancelButtonText?: string;
  isSubmitting?: boolean;
  icon?: IconSource;
  isMobileSheet?: boolean;
  isDisabled?: boolean;
}

const DeleteModal = ({
  title,
  titleNumberOfLines,
  subtitle,
  deleteButtonText,
  cancelButtonText,
  isOpen,
  handleClose,
  handleDelete,
  isSubmitting,
  isDisabled,
  icon,
  isMobileSheet,
  children,
}: PropsWithChildren<DeleteModalProps>) => {
  return (
    <ActionModal
      icon={icon}
      color={colors.red.warning}
      title={title}
      titleNumberOfLines={titleNumberOfLines}
      message={subtitle}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleDelete}
      primaryActionText={deleteButtonText}
      handleSecondaryAction={handleClose}
      secondaryActionText={cancelButtonText}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      isOpen={isOpen}
      isMobileSheet={isMobileSheet}
      children={children}
    />
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  icon: Icon.SourcePropType,
  isMobileSheet: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DeleteModal.defaultProps = {
  deleteButtonText: 'Delete',
  cancelButtonText: 'Cancel',
  isSubmitting: false,
  icon: Icon.Trash,
  isMobileSheet: false,
  isDisabled: undefined,
  subtitle: undefined,
};

export default DeleteModal;
