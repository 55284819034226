// Libraries
import React from 'react';

// Supermove
import {Icon, Link, PreventPropagation, Space, Styled, IconSource} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip, {
  TextTooltipProps,
  TextTooltipTextType,
} from '@shared/design/components/TextTooltip';

const Cell = Styled.View`
  padding-horizontal: 12px;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const PrimaryText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const SecondaryText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const SecondaryLink = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const DisplayContentRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DisplayContentTooltipContent = Styled.View`
  flex-direction: row;
  flex-shrink: 1;
`;

const getEmptyText = ({emptyLabel, emptyText}: {emptyLabel?: string; emptyText: string}) => {
  return `${emptyLabel ? `${emptyLabel}: ` : ''}${emptyText}`;
};

const PLACEHOLDER_ROW_HOOK = {
  hook: () => ({}),
  hookArgument: {},
  renderComponent: () => null,
};

const DisplayText = ({
  isSecondary,
  isTitle,
  numberOfLines = 1,
  children,
}: {
  isSecondary?: boolean;
  isTitle?: boolean;
  numberOfLines?: number;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  if (isSecondary) {
    return (
      <SecondaryText responsive={responsive} numberOfLines={numberOfLines}>
        {children}
      </SecondaryText>
    );
  }
  if (isTitle) {
    return (
      <TitleText responsive={responsive} numberOfLines={numberOfLines}>
        {children}
      </TitleText>
    );
  }
  return (
    <PrimaryText responsive={responsive} numberOfLines={numberOfLines}>
      {children}
    </PrimaryText>
  );
};

const LinkWrapper = <T extends DefaultItemType = DefaultItemType>({
  item,
  cellTextLink,
  children,
}: {
  item: T;
  cellTextLink?: ItemFunctionReturnStringType<T>;
  children: React.ReactNode;
}) => {
  if (cellTextLink) {
    return (
      <Link to={cellTextLink(item)} style={{overflow: 'hidden'}}>
        {children}
      </Link>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const DisplayLink = <T extends DefaultItemType = DefaultItemType>({
  item,
  cellTextLink,
  handlePressCellText,
  numberOfLines = 1,
  children,
}: {
  item: T;
  isTitle?: boolean;
  cellTextLink?: ItemFunctionReturnStringType<T>;
  handlePressCellText?: ItemFunctionReturnVoidType<T>;
  numberOfLines?: number;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  return (
    <PreventPropagation>
      <LinkWrapper item={item} cellTextLink={cellTextLink}>
        <LinkText
          onPress={handlePressCellText ? () => handlePressCellText(item) : undefined}
          style={{cursor: 'pointer'}}
          responsive={responsive}
          numberOfLines={numberOfLines}
        >
          {children}
        </LinkText>
      </LinkWrapper>
    </PreventPropagation>
  );
};

const DisplayContentWrapper = ({
  tooltip,
  tooltipProps,
  item,
  children,
}: {
  tooltip: CellTooltipType;
  tooltipProps?: Partial<TextTooltipProps>;
  item: DefaultItemType;
  children: React.ReactNode;
}) => {
  const tooltipText = typeof tooltip == 'function' ? tooltip(item) : tooltip;
  return (
    <DisplayContentRow>
      {tooltip ? (
        // When there is a tooltip we want to shrink the inner wrapper so that the
        // tooltip hugs the content.
        <TextTooltip
          text={tooltipText}
          placement={'top'}
          numberOfLines={8}
          style={{maxWidth: 400}}
          {...tooltipProps}
        >
          <DisplayContentTooltipContent>{children}</DisplayContentTooltipContent>
        </TextTooltip>
      ) : (
        children
      )}
    </DisplayContentRow>
  );
};

export interface DisplayContentProps<T extends DefaultItemType = DefaultItemType>
  extends CellContentType<T> {
  item: T;
  rowIndex: number;
  isSecondary?: boolean;
  isTitle?: boolean;
}
const DisplayContent = <T extends DefaultItemType = DefaultItemType>({
  cellIcon,
  cellIconColor = () => colors.gray.secondary,
  cellIconSize = () => 0,
  cellText,
  cellTextLink,
  handlePressCellText,
  cellComponent,
  emptyLabel,
  emptyText = 'None',
  tooltip,
  tooltipProps,
  numberOfLines,
  item,
  rowIndex,
  isSecondary,
  isTitle,
}: DisplayContentProps<T>) => {
  const responsive = useResponsive();
  const isUndefined = !cellIcon && !cellText && !cellComponent;
  if (isUndefined) {
    return null;
  }

  const iconSource = cellIcon?.(item);
  const displayText = cellText?.(item, rowIndex);
  const cellComponentContent = cellComponent?.(item, rowIndex);
  const isEmpty = !iconSource && !displayText && !cellComponentContent;

  if (isEmpty) {
    return <EmptyText responsive={responsive}>{getEmptyText({emptyLabel, emptyText})}</EmptyText>;
  }

  return (
    <DisplayContentWrapper tooltip={tooltip} tooltipProps={tooltipProps} item={item}>
      {iconSource && (
        <React.Fragment>
          <Icon
            source={iconSource}
            color={cellIconColor(item)}
            size={cellIconSize(item) || (responsive.desktop ? 14 : 16)}
          />
          {(displayText || cellComponentContent) && <Space width={8} />}
        </React.Fragment>
      )}
      {cellComponentContent && (
        <React.Fragment>
          {cellComponentContent}
          {displayText && <Space width={8} />}
        </React.Fragment>
      )}
      {displayText &&
        (handlePressCellText || cellTextLink ? (
          <DisplayLink
            item={item}
            cellTextLink={cellTextLink}
            handlePressCellText={handlePressCellText}
            numberOfLines={numberOfLines}
          >
            {displayText}
          </DisplayLink>
        ) : (
          <DisplayText isSecondary={isSecondary} isTitle={isTitle} numberOfLines={numberOfLines}>
            {displayText}
          </DisplayText>
        ))}
    </DisplayContentWrapper>
  );
};

// --------------------------------------------------
// Types
// --------------------------------------------------
export type DefaultItemType = object;
export type ItemFunctionReturnVoidType<T extends DefaultItemType = DefaultItemType> = (
  item: T,
  hookProps?: {isOpen: boolean; handleOpen: () => void; handleClose: () => void},
) => void;
export type ItemFunctionReturnStringType<T extends DefaultItemType = DefaultItemType> = (
  item: T,
) => string;
export type RowHookType<T extends DefaultItemType = DefaultItemType> = {
  hook: (hookArgument: Record<string, any>) => Record<string, any>;
  hookArgument: Record<string, any>;
  renderComponent?: React.FC<{
    item?: T;
    isOpen: boolean;
    handleClose: () => void;
    hookKey?: string;
  }>;
};
export type HookOutputType = {
  isOpen?: boolean;
  handleOpen?: () => void;
  [key: string]: any;
};
type CellTooltipType = TextTooltipTextType | ((item: DefaultItemType) => TextTooltipTextType);
export type ActionType<T extends DefaultItemType = DefaultItemType> = {
  // Required
  text: string;
  color?: string;
  onPress: ({
    item,
    handleOpen,
    handleSubmit,
  }: {
    item?: T;
    handleOpen?: () => void;
    handleSubmit?: () => void;
  }) => void;

  // Optional
  isDisabled?: boolean;
  tooltip?: TextTooltipTextType;

  // Overlays
  actionHook?: RowHookType; // If an actionHook is provided, onPress will receive handleOpen
  hookOutput?: HookOutputType;

  // Adding either of these properties will make it standalone on desktop
  desktopIcon?: IconSource;
  desktopIconSize?: number;
  desktopLabel?: string;
};
type CellStylingType = {
  // All styling gets applied to the header cell as well
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  width?: number;
  isHidden?: boolean;
  cellStyle?: React.CSSProperties;
};
type CellHeaderType = {
  headerLabel?: string;
  headerComponent?: React.FC;
};
type CellContentType<T extends DefaultItemType = DefaultItemType> = CellHeaderType & {
  cellIcon?: (item: T) => IconSource;
  cellIconColor?: (item: T) => string;
  cellIconSize?: (item: T) => number;

  // A cellText can be accompanied by a cellTextLink and/or handlePressCellText.
  // cellTextLink is specifically for navigation links and adds the capability
  // to cmnd+click to open the link in a new tab.
  cellText?: (item: T, rowIndex: number) => string | undefined;
  cellTextLink?: ItemFunctionReturnStringType<T>;
  handlePressCellText?: (item: T) => void;

  cellComponent?: (item: T, rowIndex: number) => React.ReactNode;
  emptyLabel?: string;
  emptyText?: string;
  tooltip?: CellTooltipType;
  tooltipProps?: Partial<TextTooltipProps>;
  numberOfLines?: number;

  // Secondary content
  secondary?: Omit<CellContentType<T>, 'secondary'> & {isHidden?: boolean};
};
export type ColumnDefinitionCellContentType<T extends DefaultItemType = DefaultItemType> =
  CellStylingType &
    CellContentType<T> & {
      // Mobile
      mobileOptions?: {
        isInHeader?: boolean; // Moves content to the card header
        rank?: number; // Customize the order of the content
      };
    };
export type ColumnDefinitionCellActionsType<T extends DefaultItemType = DefaultItemType> =
  CellStylingType &
    CellHeaderType & {
      actions?: (item: T) => ActionType<T>[];
    };
export type ColumnDefinitionType<T extends DefaultItemType = DefaultItemType> =
  | ColumnDefinitionCellContentType<T>
  | ColumnDefinitionCellActionsType<T>;

const getColumnHasActions = (
  column: ColumnDefinitionType,
): column is ColumnDefinitionCellActionsType => {
  return 'actions' in column;
};
const getColumnHasSecondary = (
  column: ColumnDefinitionType,
): column is ColumnDefinitionCellContentType => {
  return 'secondary' in column;
};

const TableBuilder = {
  // Typography
  PrimaryText,
  SecondaryText,
  LinkText,

  // Constants
  PLACEHOLDER_ROW_HOOK,

  // Components
  Cell,
  DisplayContent,

  // Helpers
  getColumnHasActions,
  getColumnHasSecondary,
};

export default TableBuilder;
