import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useMemo, useToast, ResponsiveType, Form} from '@supermove/hooks';
import {CompanyModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProgressModal from '@shared/design/components/ProgressModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useProgress from '@shared/modules/App/hooks/useProgress';
import CreateCompanyBranchStep, {
  StepType,
} from '@shared/modules/Organization/enums/CreateCompanyBranchStep';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import CompanyBranchForm, {
  CompanyBranchFormToFormType,
} from '@shared/modules/Organization/forms/CompanyBranchForm';
import useCreateCompanyBranchMutation from '@shared/modules/Organization/hooks/useCreateCompanyBranchMutation';
import CreateCompanyBranchModalFooter from 'modules/Company/CompanyDetails/components/CreateCompanyBranchModalFooter';
import CreateCompanyBranchReviewStep from 'modules/Company/CompanyDetails/components/CreateCompanyBranchReviewStep';

const MobilePaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const StepHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const StepHeaderDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const StepFields = ({
  stepKind,
  form,
  field,
  setCurrentStepKind,
  responsive,
  companySlug,
}: {
  stepKind: string;
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
  setCurrentStepKind: (kind: string) => void;
  responsive: ResponsiveType;
  companySlug: string;
}) => {
  switch (stepKind) {
    case CreateCompanyBranchStep.BRANCH_DETAILS:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <FieldInput
            {...form}
            name={`${field}.name`}
            label={'Name'}
            isResponsive
            isRequired
            input={{
              autoFocus: true,
              placeholder: 'Enter branch name',
            }}
            handleBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const text = event.target.value;
              // Automatically generate an slug based on the name
              // Lowercase, replace spaces with -, and remove any non-alphanumeric characters
              const slug = text.toLowerCase().replace(/[^\w-]/g, '');
              const companyPrefix = `${companySlug}-`;
              if (_.includes(slug, companySlug) && !_.includes(slug, companyPrefix)) {
                const slugWithoutCompany = slug.replace(companySlug, '');
                form.setFieldValue(`${field}.slug`, `${companyPrefix}${slugWithoutCompany}`);
              } else {
                form.setFieldValue(`${field}.slug`, slug);
              }
            }}
          />
          <Space height={16} />
          <FieldInput
            {...form}
            name={`${field}.slug`}
            label={'Identifier'}
            isResponsive
            isRequired
            input={{
              placeholder: 'Enter branch identifier',
            }}
            tooltip={`Requirements:\n• Must be unique\n• Must contain at least 3 characters\n• Only letters, numbers, -, and _ are allowed\n• Cannot contain spaces`}
          />
        </MobilePaddingContainer>
      );
    case CreateCompanyBranchStep.BRANCH_TYPE:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <FieldInput
            {...form}
            index={0}
            name={`${field}.kind`}
            label={'Branch Type'}
            component={DropdownInput}
            isResponsive
            isRequired
            input={{
              autoFocus: true,
              setFieldValue: form.setFieldValue,
              isPortaled: true,
              placeholder: 'Select branch type',
              options: [
                OrganizationKind.BRANCH,
                OrganizationKind.FRANCHISE,
                OrganizationKind.CONTRACTOR,
              ].map((kind) => ({
                label: OrganizationKind.getLabel(kind),
                value: kind,
              })),
              style: {flex: 1},
            }}
            style={{flex: 1}}
          />
        </MobilePaddingContainer>
      );
    case CreateCompanyBranchStep.REVIEW:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <CreateCompanyBranchReviewStep
            form={form}
            field={field}
            setCurrentStepKind={setCurrentStepKind}
          />
        </MobilePaddingContainer>
      );
    default:
      return null;
  }
};

const CreateCompanyBranchModalBody = ({
  form,
  field,
  currentStep,
  currentStepKind,
  setCurrentStepKind,
  companySlug,
}: {
  form: Form<{companyBranchForm: CompanyBranchFormToFormType}>;
  field: string;
  currentStep?: StepType;
  currentStepKind: string;
  setCurrentStepKind: (kind: string) => void;
  companySlug: string;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <StepHeaderText responsive={responsive}>{currentStep?.title || ''}</StepHeaderText>
        <Space height={8} />
        <StepHeaderDescription responsive={responsive}>
          {currentStep?.description || ''}
        </StepHeaderDescription>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        responsive={responsive}
        companySlug={companySlug}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

const CreateCompanyBranchModal = ({
  isOpen,
  handleClose,
  refetch,
  company,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  company: CompanyModel;
}) => {
  const branchCreatedSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Branch created.',
  });
  const companyBranchForm = CompanyBranchForm.new({
    companyId: company.id,
    name: `${company.name}: `,
    slug: `${company.slug}-`,
  });
  const {form, submitting, handleSubmit} = useCreateCompanyBranchMutation({
    companyBranchForm,
    onSuccess: () => {
      branchCreatedSuccessToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps: CreateCompanyBranchStep.STEP_KINDS.map((kind) => ({kind})),
    getPreviousStepKind: CreateCompanyBranchStep.getPreviousStepKind,
    getNextStepKind: CreateCompanyBranchStep.getNextStepKind,
  });

  const currentStep = useMemo(
    () => CreateCompanyBranchStep.getStep(currentStepKind),
    [currentStepKind],
  );

  // If all steps are completed, we are reviewing, allowing the user to easily navigate to the review step.
  const isReviewingSteps = _.isEqual(
    _.sortBy(allStepKinds.filter((stepKind) => stepKind !== CreateCompanyBranchStep.REVIEW)),
    _.sortBy(completedSteps),
  );

  return (
    <ProgressModal
      isOpen={isOpen}
      handleClose={handleClose}
      steps={CreateCompanyBranchStep.STEPS}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={(kind) => CreateCompanyBranchStep.getStep(kind)?.title || ''}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Create Branch'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <CreateCompanyBranchModalBody
          form={form}
          field={'companyBranchForm'}
          currentStep={currentStep}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          companySlug={company.slug}
        />
      }
      FooterElement={
        <CreateCompanyBranchModalFooter
          isViewingFirstStep={isViewingFirstStep}
          previousStepKind={previousStepKind}
          nextStepKind={nextStepKind}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          setCompletedSteps={setCompletedSteps}
          form={form}
          isReviewingSteps={isReviewingSteps}
          handleSubmit={handleSubmit}
          isSubmitting={submitting}
        />
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCompanyBranchModal.fragment = gql`
  fragment CreateCompanyBranchModal on Company {
    id
    name
    slug
  }
`;

export default CreateCompanyBranchModal;
