// Libraries
import React from 'react';

export interface IdentifyType {
  id: string;
  firstName: string;
  lastName: string;
  fullName?: string;
  phoneNumber?: string;
  email: string;
  position?: string;
  role: string;
  createdAt?: string;
  userflowSecuritySignature?: string;
  organizationSlug: string;
  companyName?: string;
  companyIdentifier: string;
  companyCity?: string;
  companyState?: string;
  companyCountry?: string;
  companyContractedTruckQuantity?: number;
  companySize?: string;
  companySalesforceId: string;

  // TODO(mark): Remove these later on.
  Organization: string;
  Role: string;
}

export interface AnalyticsContextType {
  identify: (params: IdentifyType) => void;
  skipIdentify?: () => void;
  track: (params: {event: string; data: Record<string, any>}) => void;
  reset: () => void;
  setGlobalEventData?: (params: {name: string; version: string; buildNumber: number}) => void;
}

const AnalyticsContext = React.createContext<AnalyticsContextType>({
  identify: () => {
    console.error('Must provide a `identify` function to the AnalyticsContext.Provider.');
  },
  skipIdentify: () => {
    console.error('Must provide a `skipIdentify` function to the AnalyticsContext.Provider.');
  },
  track: () => {
    console.error('Must provide a `track` function to the AnalyticsContext.Provider.');
  },
  reset: () => {
    console.error('Must provide a `reset` function to the AnalyticsContext.Provider.');
  },
  setGlobalEventData: () => {
    console.error('Must provide a `setGlobalEventData` function to the AnalyticsContext.Provider.');
  },
});

export default AnalyticsContext;
