// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import CompanyBranchForm, {
  CompanyBranchFormType,
} from '@shared/modules/Organization/forms/CompanyBranchForm';

const useCreateCompanyBranchMutation = ({
  companyBranchForm,
  onSuccess,
  onError,
}: {
  companyBranchForm: CompanyBranchFormType;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      companyBranchForm: CompanyBranchForm.toForm(companyBranchForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCompanyBranchMutation.mutation,
    variables: {
      companyBranchForm: CompanyBranchForm.toMutation(form.values.companyBranchForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateCompanyBranchMutation.mutation = gql`
  mutation useCreateCompanyBranchMutation($companyBranchForm: CompanyBranchForm!) {
    response: createCompanyBranch(companyBranchForm: $companyBranchForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useCreateCompanyBranchMutation;
