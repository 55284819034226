// Libraries
import _ from 'lodash';

export interface StepType {
  kind: string;
  title: string;
  description: string;
}

const BRANCH_DETAILS = 'BRANCH_DETAILS';
const BRANCH_TYPE = 'BRANCH_TYPE';
const REVIEW = 'REVIEW';
const STEP_KINDS = [BRANCH_DETAILS, BRANCH_TYPE, REVIEW] as const;

const STEPS: StepType[] = [
  {
    kind: BRANCH_DETAILS,
    title: 'Branch Details',
    description: 'Enter the name and identifier for this branch.',
  },
  {
    kind: BRANCH_TYPE,
    title: 'Branch Type',
    description: 'Branch types include corporate, franchise, and contractors.',
  },
  {
    kind: REVIEW,
    title: 'Review',
    description: 'Before submitting, make sure this information is correct.',
  },
];

const getStep = (kind: string): StepType | undefined => {
  return _.find(STEPS, (step) => step.kind === kind);
};

const getPreviousStep = (kind: string): StepType | undefined => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index <= 0 ? undefined : STEPS[index - 1];
  return step;
};

const getNextStep = (kind: string): StepType | undefined => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index < 0 || index >= STEPS.length - 1 ? undefined : STEPS[index + 1];
  return step;
};

const CreateCompanyBranchStep = {
  BRANCH_DETAILS,
  BRANCH_TYPE,
  REVIEW,

  STEPS,
  STEP_KINDS,
  getStep,
  getPreviousStep,
  getNextStep,

  getPreviousStepKind: (kind: string) => CreateCompanyBranchStep.getPreviousStep(kind)?.kind || '',
  getNextStepKind: (kind: string) => CreateCompanyBranchStep.getNextStep(kind)?.kind || '',
};

export default CreateCompanyBranchStep;
