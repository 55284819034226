// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled, SupermoveLogo} from '@supermove/components';
import {DrawerType, useDrawer, useModal, usePopover, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Badge from '@shared/design/components/Badge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Drawer from '@shared/design/components/Drawer';
import useAppContext from 'modules/App/context/useAppContext';
import SignOutModal from 'modules/Authentication/components/SignOutModal';

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 48px;
  background-color: ${colors.blue.accentDark};
  align-items: center;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.white};
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LogoContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const MobileFooterContainer = Styled.View`
  padding: 8px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const Pressable = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

interface UserActionMenuProps {
  handleSignOut: () => void;
  children: ({handleToggle}: {handleToggle: () => void}) => NonNullable<React.ReactNode>;
}

const UserActionMenu: React.FC<UserActionMenuProps> = ({handleSignOut, children}) => {
  const userActionMenuPopover = usePopover();

  return (
    <ActionMenuPopover
      sheetLabel={'Actions'}
      popover={userActionMenuPopover}
      placement={ActionMenuPopover.Position.BottomEnd}
      width={200}
      actions={[
        {
          text: 'Sign Out',
          color: colors.red.warning,
          onPress: handleSignOut,
        },
      ]}
    >
      {children({handleToggle: userActionMenuPopover.handleToggle})}
    </ActionMenuPopover>
  );
};

interface HeaderIconButtonProps {
  onPress: () => void;
  icon: IconSource;
}

const HeaderIconButton: React.FC<HeaderIconButtonProps> = ({onPress, icon}) => {
  return (
    <TertiaryButton
      onPress={onPress}
      style={{
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon source={icon} size={16} color={colors.white} />
    </TertiaryButton>
  );
};

const Logo = () => {
  return (
    <LogoContainer>
      <SupermoveLogo.Icon color={colors.white} backgroundColor={colors.blue.accentDark} />
    </LogoContainer>
  );
};

const GlobalHeader = () => {
  const responsive = useResponsive();
  const {viewer, toggleIsGlobalNavSidebarExpanded} = useAppContext();
  const signOutModal = useModal({
    name: 'Sign Out Modal',
    onOpen: () => {},
    onClose: () => {},
  });

  return (
    <React.Fragment>
      <HeaderContainer>
        <Space width={16} />
        <HeaderIconButton onPress={toggleIsGlobalNavSidebarExpanded} icon={Icon.Bars} />
        <Space width={16} />
        <Logo />
        <Space width={8} />
        <LabelText responsive={responsive}>Admin</LabelText>
        <Space width={12} />
        <Badge label={'Only Visible to Supermove Employees'} color={colors.blue.interactive} />
        <Space style={{flex: 1, minWidth: 16}} />
        {viewer && (
          <UserActionMenu handleSignOut={signOutModal.handleOpen}>
            {({handleToggle}) => (
              <Pressable onPress={handleToggle} style={{padding: 14}}>
                <BodyText style={{color: colors.white}} responsive={responsive}>
                  {viewer.fullName}
                </BodyText>
              </Pressable>
            )}
          </UserActionMenu>
        )}
        <Space width={24} />
      </HeaderContainer>
      <SignOutModal
        key={signOutModal.key}
        isOpen={signOutModal.isOpen}
        handleClose={signOutModal.handleClose}
      />
    </React.Fragment>
  );
};

interface MobileHeaderProps {
  mobileNavigationDrawer: {
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;
  };
}

const MobileHeader: React.FC<MobileHeaderProps> = ({mobileNavigationDrawer}) => {
  const responsive = useResponsive();

  return (
    <HeaderContainer>
      <Space width={16} />
      {mobileNavigationDrawer.isOpen ? (
        <HeaderIconButton onPress={mobileNavigationDrawer.handleClose} icon={Icon.Xmark} />
      ) : (
        <React.Fragment>
          <HeaderIconButton onPress={mobileNavigationDrawer.handleOpen} icon={Icon.Bars} />
          <Space width={16} />
          <LabelText responsive={responsive}>Admin</LabelText>
          <Space width={12} />
          <Badge label={'Only Visible to Supermove Employees'} color={colors.blue.interactive} />
        </React.Fragment>
      )}
      <Space style={{flex: 1, minWidth: 24}} />
    </HeaderContainer>
  );
};

interface MobileGlobalHeaderProps {
  NavigationComponent: React.FC<{mobileNavigationDrawer: DrawerType}>;
}

const MobileGlobalHeader: React.FC<MobileGlobalHeaderProps> = ({NavigationComponent}) => {
  const {viewer} = useAppContext();
  const signOutModal = useModal({
    name: 'Sign Out Modal',
    onOpen: () => {},
    onClose: () => {},
  });
  const mobileNavigationDrawer = useDrawer({
    onOpen: () => {},
    onClose: () => {},
    name: 'Mobile Navigation Drawer',
  });

  return (
    <React.Fragment>
      {/* An empty HeaderContainer allows for a smooth transistion from the header outside the drawer to inside the drawer on open */}
      {mobileNavigationDrawer.isOpen ? (
        <HeaderContainer />
      ) : (
        <MobileHeader mobileNavigationDrawer={mobileNavigationDrawer} />
      )}
      <Drawer
        isOpen={mobileNavigationDrawer.isOpen}
        handleClose={mobileNavigationDrawer.handleClose}
        isLeft
      >
        <MobileHeader mobileNavigationDrawer={mobileNavigationDrawer} />
        <NavigationComponent mobileNavigationDrawer={mobileNavigationDrawer} />
        <MobileFooterContainer>
          <UserActionMenu handleSignOut={signOutModal.handleOpen}>
            {({handleToggle}) => (
              <Pressable onPress={handleToggle} style={{padding: 14}}>
                <BodyText>{viewer?.fullName || ''}</BodyText>
              </Pressable>
            )}
          </UserActionMenu>
        </MobileFooterContainer>
      </Drawer>
      <SignOutModal
        key={signOutModal.key}
        isOpen={signOutModal.isOpen}
        handleClose={signOutModal.handleClose}
      />
    </React.Fragment>
  );
};

GlobalHeader.Mobile = MobileGlobalHeader;

export default GlobalHeader;
