// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useNavigation, useQuery, useResponsive} from '@supermove/hooks';
import {CompanyModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import OrganizationKind, {
  OrganizationKindType,
} from '@shared/modules/Organization/enums/OrganizationKind';
import CompanyDetailsPage from 'modules/Company/CompanyDetails/CompanyDetailsPage';
import CreateCompanyBranchModal from 'modules/Company/CompanyDetails/components/CreateCompanyBranchModal';
import DeleteOrganizationModal from 'modules/Company/CompanyDetails/components/DeleteOrganizationModal';
import EditCompanyBranchDrawer from 'modules/Company/CompanyDetails/components/EditCompanyBranchDrawer';

const getCompanyBranches = ({company}: {company: CompanyModel}) => {
  // NOTE(jholston): All companies must have a main branch, so we enforce the type.
  const mainBranch = company.organizations.find(
    (organization) => organization.kind === OrganizationKind.MAIN,
  )!;
  const corporateBranches = company.organizations.filter(
    (organization) => organization.kind === OrganizationKind.BRANCH,
  );
  const franchiseBrances = company.organizations.filter(
    (organization) => organization.kind === OrganizationKind.FRANCHISE,
  );
  const contractorBranches = company.organizations.filter(
    (organization) => organization.kind === OrganizationKind.CONTRACTOR,
  );
  return {mainBranch, corporateBranches, franchiseBrances, contractorBranches};
};

const getColumnDefinitions = ({refetch}: {refetch: () => void}) => {
  return [
    {
      flex: 2,
      headerLabel: 'Name',
      cellText: (organization: OrganizationModel) => organization.name,
      mobileOptions: {
        isInHeader: true,
      },
    },
    {
      flex: 2,
      headerLabel: 'Identifier',
      cellText: (organization: OrganizationModel) => organization.slug,
      mobileOptions: {
        isInHeader: true,
      },
    },
    {
      flex: 1,
      headerLabel: 'Type',
      cellText: (organziation: OrganizationModel) => OrganizationKind.getLabel(organziation.kind),
      mobileOptions: {
        isInHeader: true,
      },
    },
    {
      flex: 1,
      headerLabel: 'Created At',
      cellText: (organization: OrganizationModel) =>
        Datetime.toDisplayDatetime(Datetime.fromDatetime(organization.createdAt)),
      mobileOptions: {
        isInHeader: true,
      },
    },
    {
      width: 56,
      actions: (organization: OrganizationModel) => {
        const isDeletable = organization.kind !== OrganizationKind.MAIN;
        return [
          {
            text: 'Edit branch',
            onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
            actionHook: {
              hook: useDrawer,
              hookArgument: {name: 'Edit Company Branch Drawer'},
              renderComponent: ({
                hookKey,
                isOpen,
                handleClose,
              }: {
                hookKey: string;
                isOpen: boolean;
                handleClose: () => void;
              }) => {
                return (
                  <EditCompanyBranchDrawer
                    key={hookKey}
                    isOpen={isOpen}
                    handleClose={handleClose}
                    organization={organization}
                    refetch={refetch}
                  />
                );
              },
            },
          },
          {
            color: colors.red.warning,
            isDisabled: !isDeletable,
            tooltip: isDeletable ? '' : 'The main branch cannot be deleted.',
            text: 'Delete',
            onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
            actionHook: {
              hook: useModal,
              hookArgument: {name: 'Delete Organization Modal'},
              renderComponent: ({
                hookKey,
                isOpen,
                handleClose,
              }: {
                hookKey: string;
                isOpen: boolean;
                handleClose: () => void;
              }) => {
                return (
                  <DeleteOrganizationModal
                    key={hookKey}
                    isOpen={isOpen}
                    handleClose={handleClose}
                    organization={organization}
                    refetch={refetch}
                  />
                );
              },
            },
          },
        ];
      },
    },
  ];
};

const BranchKindTable = ({
  title,
  organizationKind,
  branches,
  refetch,
}: {
  title: string;
  organizationKind: OrganizationKindType;
  branches: OrganizationModel[];
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  return (
    <ActionPanel
      index={0}
      title={`${title} (${branches.length})`}
      BodyComponent={({
        branches,
        organizationKind,
        refetch,
      }: {
        branches: OrganizationModel[];
        organizationKind: OrganizationKindType;
        refetch: () => void;
      }) => (
        <Table
          columnDefinitions={getColumnDefinitions({refetch})}
          items={_.sortBy(branches, ['name'])}
          itemKey={'id'}
          emptyStateText={`No ${_.lowerCase(OrganizationKind.getLabel(organizationKind))} branches added yet.`}
          hasBorder={responsive.desktop}
          containerStyle={{
            borderColor: colors.gray.border,
            borderTopWidth: 1,
            borderBottomWidth: 1,
          }}
        />
      )}
      bodyComponentProps={{branches, organizationKind, refetch}}
      mobileBodyStyle={{padding: 0}}
    />
  );
};

const TableSpace = () => {
  const responsive = useResponsive();
  return responsive.desktop ? <Space height={24} /> : null;
};

const BranchesPageContent = ({company, refetch}: {company: CompanyModel; refetch: () => void}) => {
  const responsive = useResponsive();
  const {mainBranch, corporateBranches, franchiseBrances, contractorBranches} = getCompanyBranches({
    company,
  });
  return (
    <React.Fragment>
      <BranchKindTable
        title={'Main Branch'}
        organizationKind={OrganizationKind.MAIN}
        branches={[mainBranch]}
        refetch={refetch}
      />
      <TableSpace />
      <BranchKindTable
        title={'Corporate Branches'}
        organizationKind={OrganizationKind.BRANCH}
        branches={corporateBranches}
        refetch={refetch}
      />
      <TableSpace />
      <BranchKindTable
        title={'Franchise Branches'}
        organizationKind={OrganizationKind.FRANCHISE}
        branches={franchiseBrances}
        refetch={refetch}
      />
      <TableSpace />
      <BranchKindTable
        title={'Contractors'}
        organizationKind={OrganizationKind.CONTRACTOR}
        branches={contractorBranches}
        refetch={refetch}
      />
      {responsive.desktop && <Space height={48} />}
    </React.Fragment>
  );
};

const CompanyDetailsBranchesPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigation();
  const createCompanyBranchModal = useModal({name: 'Create Company Branch Modal'});
  const {loading, data, refetch} = useQuery(CompanyDetailsBranchesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });
  const backgroundColorStyle = {
    backgroundColor: responsive.desktop ? colors.gray.background : colors.white,
  };
  return (
    <CompanyDetailsPage
      selectedLabel={'company/branches'}
      pageTitle={'Branches'}
      pageDescription={`View and manage this company's branches.`}
      actionElement={
        <Button
          text={'Create Branch'}
          iconLeft={Icon.Plus}
          isDisabled={loading}
          onPress={createCompanyBranchModal.handleOpen}
        />
      }
      // NOTE(jholston): Both of these are required due to nested hardcoded background colors
      pageContainerStyle={backgroundColorStyle}
      contentContainerStyle={backgroundColorStyle}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <React.Fragment>
              <BranchesPageContent company={data.organization.company} refetch={refetch} />
              <CreateCompanyBranchModal
                key={createCompanyBranchModal.key}
                isOpen={createCompanyBranchModal.isOpen}
                handleClose={createCompanyBranchModal.handleClose}
                refetch={refetch}
                company={data.organization.company}
              />
            </React.Fragment>
          );
        }}
      </Loading>
    </CompanyDetailsPage>
  );
};

CompanyDetailsBranchesPage.query = gql`
  ${CreateCompanyBranchModal.fragment}
  ${DeleteOrganizationModal.fragment}
  ${EditCompanyBranchDrawer.fragment}
  query CompanyDetailsBranchesPage($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      company {
        id
        organizations {
          id
          name
          slug
          kind
          createdAt
          ...DeleteOrganizationModal
          ...EditCompanyBranchDrawer
        }
        ...CreateCompanyBranchModal
      }
    }
  }
`;

export default CompanyDetailsBranchesPage;
