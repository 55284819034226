// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useMemo} from '@supermove/hooks';
import {withFragment} from '@supermove/utils';

interface UsePaginationProps {
  currentPage: number;
  paginationMetadata: Record<string, any>;
  onChangePage: (page: number) => void;
}

export type PaginationType = {
  currentPage: number;
  pageRange: number[] | null;
  hasPrevious: boolean;
  hasNext: boolean;
  handleChangePage: (newPage: number) => void;
};

export type PaginationInput = {
  page: number;
  resultsPerPage: number;
};

/**
 * This hook ensures that the necessary information is available
 * for the PaginationBar component.
 * 1. It ensures that server data (totalPages, hasNext, hasPrevious)
 *    are fetched correctly and returned.
 * 2. It calculates the pageRange, returning the first, last, visible range, and ellipsis
 * 3. It accepts onChangePage which allows the parent component to define
 *    what happens when a page is clicked
 */
const DISPLAY_PAGES_COUNT = 7;

const usePagination = withFragment(
  ({currentPage, paginationMetadata = {}, onChangePage}: UsePaginationProps): PaginationType => {
    // Extract values from paginationMetadata
    const {totalPages, hasNext, hasPrevious} = paginationMetadata;

    // Calculate page range to be displayed on pagination bar
    const pageRange = useMemo(() => {
      if (!currentPage || _.isNil(totalPages)) {
        return null;
      }

      // If the number of available pages is less than DISPLAY_PAGES_COUNT
      // then return the range [1 -> totalPages]
      if (DISPLAY_PAGES_COUNT >= totalPages) {
        return totalPages > 0 ? _.range(1, totalPages + 1) : [1];
      }

      const ELLIPSIS = 'ELLIPSIS';
      const offset = Math.floor(DISPLAY_PAGES_COUNT / 2);
      const isShowingFirstEllipsis = currentPage > offset && currentPage - 1 !== 2;
      const isShowingLastEllipsis =
        currentPage < totalPages - offset && currentPage + 1 !== totalPages - 1;

      return _.flatten([
        1,
        isShowingFirstEllipsis ? ELLIPSIS : _.range(2, offset + 3),
        isShowingFirstEllipsis && isShowingLastEllipsis
          ? _.range(currentPage - 1, currentPage + 2)
          : [],
        isShowingLastEllipsis ? ELLIPSIS : _.range(totalPages - offset - 1, totalPages),
        totalPages,
      ]);
    }, [totalPages, currentPage]);

    const handleChangePage = (newPage: number) => {
      onChangePage(newPage);
    };

    return {
      currentPage,
      pageRange,
      hasPrevious,
      hasNext,
      handleChangePage,
    };
  },
  gql`
    fragment usePagination on PaginationMetadata {
      totalPages
      hasNext
      hasPrevious
    }
  `,
);

export default usePagination;
