// Libraries
import _ from 'lodash';
import React from 'react';
import {View} from 'react-native';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {generated, gqlTyped as gql} from '@supermove/graphql';
import {Form, useResponsive, useState, useRef} from '@supermove/hooks';
import {Dashboard} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {existenceFilter, List} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInput from '@shared/design/components/MultiDropdownInput';
import DashboardCategoryForm from '@shared/modules/DashboardCategory/forms/DashboardCategoryForm';
import DashboardTagForm from '@shared/modules/DashboardTag/forms/DashboardTagForm';

const Container = Styled.View`
`;

const CustomOptionButton = Styled.ButtonV2`
    flex: 1;
    align-items: flex-start;
`;

const CustomOptionText = Styled.Text`
    ${Typography.Responsive.Body}
    color: ${colors.blue.interactive};
`;

type Option = {
  id: string;
  name?: string | null;
};

const toDashboardCategoryOptions = (dashboardCategories: Option[]) =>
  dashboardCategories
    .map((category) => ({
      label: category.name,
      value: _.toNumber(category.id),
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

const toDashboardTagOptions = (dashboardTags: Option[]) =>
  dashboardTags
    .filter(existenceFilter)
    .map((tag) => ({
      label: tag.name,
      value: _.toNumber(tag.id),
    }))
    .sort((a, b) => a.label?.localeCompare(b.label || '') || 0);

interface GlobalDashboardFieldsProps {
  form: Form<{globalDashboardForm: generated.GlobalDashboardForm}>;
  dashboardCategories: NonNullable<generated.GlobalDashboardFieldsFragment['dashboardCategories']>;
  dashboardTags: NonNullable<generated.GlobalDashboardFieldsFragment['dashboardTags']>;
}

const GlobalDashboardFields = ({
  form,
  dashboardCategories,
  dashboardTags,
}: GlobalDashboardFieldsProps) => {
  const ref = useRef<View | null>(null);
  const responsive = useResponsive();
  const field = 'globalDashboardForm';
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const dashboardCategoryForm = _.get(form.values, `${field}.dashboardCategoryForm`);
  const {dashboardCategoryId, name: dashboardCategoryName} = dashboardCategoryForm || {};
  const isCustomDashboardCategory = dashboardCategoryId === null && dashboardCategoryName !== '';

  const dashboardTagForms = _.get(
    form.values,
    `${field}.dashboardTagForms`,
  ) as (generated.DashboardTagForm & {abstractId?: string})[];
  const dashboardTagIds = dashboardTagForms.map((tag) => tag.dashboardTagId);
  const newDashboardTagForms = dashboardTagForms.filter((tag) => !!tag.abstractId);

  return (
    <Container ref={ref}>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Report Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter report name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.dashboardCategoryForm.dashboardCategoryId`}
        errorName={`${field}.dashboardCategoryForm`}
        label={'Category'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          options: [
            // Custom values are added to the list of options so the input value is not empty
            ...List.insertIf(isCustomDashboardCategory, {
              label: dashboardCategoryName,
              value: null,
            }),
            ...toDashboardCategoryOptions(dashboardCategories.filter(existenceFilter)),
          ],
          placeholder: `Type to search or create`,
          setFieldValue: (name: string, value: number | null) => {
            if (value) {
              const dashboardCategory = dashboardCategories
                .filter(existenceFilter)
                .find((category) => _.toNumber(category.id) === value);
              if (dashboardCategory) {
                form.setFieldValue(
                  `${field}.dashboardCategoryForm`,
                  DashboardCategoryForm.edit(dashboardCategory),
                );
              }
            }
          },
          style: {flex: 1},
          // We track the value separately so we're able to fetch it when adding a custom value
          onInputChange: (value: string) => setCategoryInputValue(value),
          noOptionsMessage: () => (
            <CustomOptionButton
              onPress={() => {
                form.setFieldValue(
                  `${field}.dashboardCategoryForm`,
                  DashboardCategoryForm.new({name: categoryInputValue}),
                );
                // Focus on the outer container forces the dropdown to close
                // Otherwise, the no option message will trigger adding an option and leave the dropdown open
                ref?.current?.focus(); // eslint-disable-line
              }}
            >
              <CustomOptionText
                responsive={responsive}
              >{`Create '${categoryInputValue}'`}</CustomOptionText>
            </CustomOptionButton>
          ),
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.exploDashboardEmbedId`}
        label={'Explo ID'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter Explo ID',
          disabled: !!form.values?.globalDashboardForm?.globalDashboardId,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        name={`${field}.exploVariables`}
        label={'Explo Variables'}
        tooltip={'JSON of custom Explo variables passed in to this report'}
        isResponsive
        input={{
          placeholder: 'Enter Explo Variables',
          value: Dashboard.formatJson(form.values?.globalDashboardForm?.exploVariables || ''),
          multiline: true,
          style: {height: 136, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={4}
        name={`${field}.description`}
        label={'Description'}
        isResponsive
        input={{
          placeholder: 'Enter description',
          multiline: true,
          style: {height: 76, paddingTop: 8},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Tags'}
        isResponsive
        tooltip={
          'Descriptive tags to help customers easily find this report based on keywords and topics.'
        }
        component={MultiDropdownInput}
        input={{
          value: dashboardTagIds,
          options: [
            // Custom values are added to the list of options so the input value is not empty
            ...newDashboardTagForms.map((tag) => ({
              label: tag.name,
              value: tag.abstractId,
            })),
            ...toDashboardTagOptions(dashboardTags.filter(existenceFilter)),
          ],
          placeholder: `Type to search`,
          setFieldValue: (name: string, value: number[]) => {
            if (value) {
              const foundDashboardTags = value.reduce(
                (acc, v) => {
                  const foundTag = dashboardTags
                    .filter(existenceFilter)
                    .find((tag) => _.toNumber(tag.id) === v);
                  if (foundTag) {
                    acc.push(DashboardTagForm.edit(foundTag));
                  }
                  return acc;
                },
                [] as ReturnType<typeof DashboardTagForm.edit>[],
              );

              const foundNewDashboardTags = value.reduce((acc, v) => {
                const foundTag = newDashboardTagForms.find(
                  (tag) => _.toString(tag.abstractId) === _.toString(v),
                );
                if (foundTag) {
                  acc.push(foundTag);
                }
                return acc;
              }, [] as generated.DashboardTagForm[]);

              form.setFieldValue(`${field}.dashboardTagForms`, [
                ...foundDashboardTags,
                ...foundNewDashboardTags,
              ]);
            }
          },
          style: {flex: 1},
          // We track the value separately so we're able to fetch it when adding a custom value
          onInputChange: (value: string) => setCategoryInputValue(value),
          noOptionsMessage: () => (
            <CustomOptionButton
              onPress={() => {
                const dashboardTagForms = _.get(
                  form.values,
                  `${field}.dashboardTagForms`,
                ) as generated.DashboardTagForm[];
                const minId = _.min(
                  dashboardTags.filter(existenceFilter).map((tag) => _.toNumber(tag.id)),
                );
                form.setFieldValue(
                  `${field}.dashboardTagForms`,
                  dashboardTagForms.concat({
                    ...DashboardTagForm.new({name: categoryInputValue}),
                    // We use the minimum id to ensure the new tag is unique
                    dashboardTagId: Math.min(minId || 0, 0) - 1,
                  }),
                );
                // Focus on the outer container forces the dropdown to close
                // Otherwise, the no option message will trigger adding an option and leave the dropdown open
                ref?.current?.focus(); // eslint-disable-line
              }}
            >
              <CustomOptionText
                responsive={responsive}
              >{`Create '${categoryInputValue}'`}</CustomOptionText>
            </CustomOptionButton>
          ),
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={5}
        name={`${field}.helpArticleUrl`}
        label={'Link to Help Article'}
        tooltip={'This will be shown to the customer when they are viewing this report.'}
        isResponsive
        input={{
          placeholder: 'https://',
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={_.get(form.values, `${field}.isDefault`)}
        handleToggle={() =>
          form.setFieldValue(`${field}.isDefault`, !_.get(form.values, `${field}.isDefault`))
        }
        label={'Add to new companies by default'}
        hint={'When checked, this report will automatically be added to all companies.'}
        isResponsive
      />
    </Container>
  );
};

export const GlobalDashboardFieldsFragment = gql(`
  fragment GlobalDashboardFields on Query {
    dashboardCategories {
      id
      name
      ...DashboardCategoryFormEdit
    }
    dashboardTags {
      id
      name
      ...DashboardTagFormEdit
    }
  }
`);

export default GlobalDashboardFields;
