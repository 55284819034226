// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Message = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const ValidationError = ({
  name,
  touched,
  errors,
}: {
  name: string;
  touched: object;
  errors: object;
}) => {
  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  if (!isTouched || !error) {
    return null;
  }

  return <Message>{error}</Message>;
};

export default ValidationError;
