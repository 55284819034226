// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import DropdownSheetOption, {
  OptionProps,
} from '@shared/design/components/DropdownInput/components/DropdownSheetOption';

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
  width: 100%;
`;

type SingleSelectValueType = string | number;
type MultiSelectValueType = (string | number)[];
type SelectionOptionType = {label: string; value: string | number; [key: string]: unknown};
type MultiDropdownEventType = {
  name: string;
  action: string;
  removedValue?: SelectionOptionType;
  option?: SelectionOptionType;
};
type MultiSelectOnChangeValueType = (
  value: MultiSelectValueType,
  event: MultiDropdownEventType,
) => void;

interface BaseProps {
  options?: OptionProps[];
  name?: string;
  setFieldValue?: (name: string, value: SingleSelectValueType | MultiSelectValueType) => void;
  showDescriptionInOption?: boolean;
  handleClose: () => void;
  optionStyle?: React.CSSProperties;
  query?: string;
  NoResultsComponent?: React.FC<any> | null;
  noResultsComponentProps?: Record<string, any>;
}
interface MultiSelectProps extends BaseProps {
  value: MultiSelectValueType;
  onChangeValue?: MultiSelectOnChangeValueType;
}
interface SingleSelectProps extends BaseProps {
  value: SingleSelectValueType;
  onChangeValue?: (
    newValue: SingleSelectValueType,
    option: SelectionOptionType,
    prevValue: SingleSelectValueType,
  ) => void;
}
type DropdownSheetOptionsListProps = MultiSelectProps | SingleSelectProps;

const isMultiSelectProps = (props: DropdownSheetOptionsListProps): props is MultiSelectProps => {
  return Array.isArray(props.value);
};

const isSingleSelectProps = (props: DropdownSheetOptionsListProps): props is SingleSelectProps => {
  return typeof props.value === 'string';
};

const handlePressMultiSelectOption = ({
  option,
  currentValues,
  name,
  setFieldValue,
  onChangeValue,
}: {
  option: SelectionOptionType;
  currentValues: MultiSelectValueType;
  name: string;
  setFieldValue: (name: string, value: MultiSelectValueType) => void;
  onChangeValue?: MultiSelectOnChangeValueType;
}) => {
  const isRemove = currentValues.includes(option.value);
  const updatedValues = isRemove
    ? currentValues.filter((value) => value !== option.value)
    : [...currentValues, option.value];
  setFieldValue(name, updatedValues);

  // This matches the onChangeValue behavior of @supermove/components/MultiDropdownInput
  const event = {
    name,
    action: isRemove ? 'remove-value' : 'select-option',
    ...(isRemove ? {removedValue: option} : {option}),
  };
  onChangeValue && onChangeValue(updatedValues, event);
};

const DropdownSheetOptionsList = (props: DropdownSheetOptionsListProps) => {
  const isMultiSelect = isMultiSelectProps(props);
  const {
    options = [],
    name = '',
    setFieldValue = () => {},
    showDescriptionInOption = false,
    handleClose,
    optionStyle,
    query = '',
    NoResultsComponent,
    noResultsComponentProps = {},
  } = props;

  return (
    <React.Fragment>
      {_.isEmpty(options) && NoResultsComponent && (
        <NoResultsComponent {...noResultsComponentProps} searchQuery={query} />
      )}
      {options.map((option: any, index: number) => {
        return (
          <React.Fragment key={option.value || option.label}>
            {index > 0 && <Line />}
            <DropdownSheetOption
              option={option}
              showDescriptionInOption={showDescriptionInOption}
              isSelected={
                isMultiSelect ? props.value.includes(option.value) : props.value === option.value
              }
              handleOption={() => {
                if (isMultiSelect) {
                  handlePressMultiSelectOption({
                    option,
                    currentValues: props.value,
                    name,
                    setFieldValue,
                    onChangeValue: props.onChangeValue,
                  });
                } else {
                  setFieldValue(name, option.value);
                  props.onChangeValue && props.onChangeValue(option.value, option, props.value);
                  handleClose();
                }
              }}
              style={optionStyle}
            />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default DropdownSheetOptionsList;
