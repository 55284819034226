// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import Tabs, {TabsProps} from '@shared/design/components/Tabs';

const FullWidthTabsRow = Styled.View`
  flex-direction: row;
`;

const FullWidthTab = ({tab, handlePressTab, isActive, style}: any) => {
  return (
    <Tabs.TabButton onPress={handlePressTab} isActive={isActive} style={{flex: 1, ...style}}>
      <Tabs.TabContent tab={tab} isActive={isActive} />
    </Tabs.TabButton>
  );
};

const FullWidthTabs = <T,>({
  tabs,
  activeTabIndex,
  handlePressTab,
  TabComponent,
  style,
}: TabsProps<T>) => {
  return (
    <FullWidthTabsRow style={style}>
      <Tabs.TabsItems
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        handlePressTab={handlePressTab}
        TabComponent={TabComponent}
        isFullWidth
      />
    </FullWidthTabsRow>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
FullWidthTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  TabComponent: PropTypes.func,
  handlePressTab: PropTypes.func.isRequired,
  activeTabIndex: PropTypes.number,
  style: PropTypes.object,
};

FullWidthTabs.defaultProps = {
  TabComponent: FullWidthTab,
  activeTabIndex: 0,
  style: null,
};

FullWidthTab.propTypes = {
  tab: PropTypes.object.isRequired,
  handlePressTab: PropTypes.func.isRequired,
  style: PropTypes.object,
};

FullWidthTab.defaultProps = {
  style: {},
};

export default FullWidthTabs;
