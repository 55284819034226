// Libraries
import React from 'react';

// Supermove
import {SupermoveApp, MaintenancePage} from '@supermove/app';
import {ToastContainer, UpdateChecker} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';

// App
import ErrorModal from 'modules/App/components/ErrorModal';
import UpdateAppBanner from 'modules/App/components/UpdateAppBanner';
import AppContextProvider from 'modules/App/context/AppContextProvider';

import {AppInfo} from '../config';

const AdminAppContent = ({children}: {children: React.ReactNode}) => {
  const name = 'Admin';
  const version = AppInfo.getVersion();
  const buildNumber = AppInfo.getBuildNumber();
  const {data} = useQuery(AdminApp.query, {fetchPolicy: 'cache-first'});

  return (
    <ErrorModal
      trigger={({handleOpen}) => (
        <SupermoveApp
          name={name}
          version={version}
          buildNumber={buildNumber}
          showOfflineOverlay={false}
          onError={(error) => {
            handleOpen();
          }}
          isAuthenticated={data?.isAuthenticated}
          viewer={data?.viewer}
        >
          <UpdateChecker name={name} buildNumber={buildNumber} pollInterval={1000 * 30}>
            {({isVisible}) => (isVisible && !Environment.isLocal ? <UpdateAppBanner /> : null)}
          </UpdateChecker>
          <AppContextProvider>{children}</AppContextProvider>
          <ToastContainer />
        </SupermoveApp>
      )}
    />
  );
};

const AdminApp = ({router: Router}: {router: React.ComponentType}) => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <AdminAppContent>
      <Router />
    </AdminAppContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AdminApp.query = gql`
  ${SupermoveApp.fragment}

  query AdminApp {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      ...SupermoveApp
    }
  }
`;

export default AdminApp;
