// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {ResponsiveType, useMountEffect, useNavigation, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Header = Styled.View<{responsive: ResponsiveType}>`
  width: 100%;
  min-height: 68px;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  padding-horizontal: ${({responsive}) => (responsive.desktop ? '24' : '16')}px;
  padding-vertical: ${({responsive}) => (responsive.desktop ? '14' : '16')}px;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

interface AdminAppPageProps {
  title?: string;
  HeaderElement?: React.ReactNode;
  isLoading?: boolean;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const AdminAppPage: React.FC<AdminAppPageProps> & {
  HeaderContainer: typeof Header;
  PageHeadingText: typeof PageHeading;
} = ({title, HeaderElement, isLoading, containerStyle, children}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  const {setGlobalNavigationTab, globalNavigationTab} = useAppContext();
  useMountEffect(() => {
    // Set the global navigation tab based on the current route if not already set
    // This is to ensure that the correct tab is highlighted when the user navigates to a page directly
    const {routeName} = navigator.state;
    if (routeName !== '' && routeName !== globalNavigationTab) {
      setGlobalNavigationTab(routeName);
    }
  });

  return (
    <Container style={containerStyle}>
      {HeaderElement || (
        <Header responsive={responsive}>
          <PageHeading responsive={responsive}>{title || ''}</PageHeading>
        </Header>
      )}
      {isLoading ? <PageLoadingIndicator /> : children}
    </Container>
  );
};

AdminAppPage.HeaderContainer = Header;
AdminAppPage.PageHeadingText = PageHeading;

export default AdminAppPage;
