// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {UserModel} from '@supermove/models';

// App
import UpdateUserStatusForm, {
  UpdateUserStatusFormProps,
} from '@shared/modules/User/forms/UpdateUserStatusForm';

const useUpdateUserStatusMutation = ({
  updateUserStatusForm,
  onSuccess,
  onError,
}: {
  updateUserStatusForm: UpdateUserStatusFormProps;
  onSuccess: ({user}: {user: UserModel}) => void;
  onError: (error: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      updateUserStatusForm: UpdateUserStatusForm.toForm(updateUserStatusForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserStatusMutation.mutation,
    variables: {
      updateUserStatusForm: UpdateUserStatusForm.toMutation(form.values.updateUserStatusForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserStatusMutation.mutation = gql`
  mutation useUpdateUserStatusMutation($updateUserStatusForm: UpdateUserStatusForm!) {
    response: updateUserStatus(updateUserStatusForm: $updateUserStatusForm) {
      user {
        id
        status
      }
      ${gql.errors}
    }
  }
`;

export default useUpdateUserStatusMutation;
