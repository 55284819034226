// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useNavigation} from '@supermove/hooks';
import {OrganizationModel, UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TreeNavigation from '@shared/design/components/TreeNavigation';
import NavigationItems from 'core/NavigationItems';
import AdminAppPage from 'modules/App/components/AdminAppPage';
import CompanyDetailsNavigation from 'modules/Company/CompanyDetails/CompanyDetailsNavigation';
import CompanyPageContentHeader from 'modules/Company/CompanyDetails/components/CompanyPageContentHeader';

const BackButtonContainer = Styled.Touchable`
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const ContentBodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const NavigationContainer = Styled.View`
  width: 260px;
  background-color: ${colors.white};
`;

const NavigationContent = ({selectedLabel, viewer}: {selectedLabel: string; viewer: UserModel}) => {
  const {navigator, params} = useNavigation();
  const navigationItems = CompanyDetailsNavigation.getItems({viewer});
  const selectedRoute = NavigationItems.getRouteForLabel({
    navigationItems,
    label: selectedLabel,
  });

  return (
    <TreeNavigation
      navigationItems={navigationItems}
      values={{route: selectedRoute}}
      handleSetValues={({route}: {route: string}) => {
        navigator.navigate(route, params);
      }}
    />
  );
};

const Header = ({organization}: {organization: OrganizationModel}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigation();
  return (
    <AdminAppPage.HeaderContainer responsive={responsive}>
      <BackButtonContainer onPress={() => navigator.navigate('CompaniesListPage')}>
        <AdminAppPage.PageHeadingText responsive={responsive} style={{color: colors.gray.tertiary}}>
          Companies /{' '}
        </AdminAppPage.PageHeadingText>
      </BackButtonContainer>
      <AdminAppPage.PageHeadingText responsive={responsive}>
        {organization?.name}
      </AdminAppPage.PageHeadingText>
    </AdminAppPage.HeaderContainer>
  );
};

const MobileHeader = ({organization}: {organization: OrganizationModel}) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigation();
  const handleBackNavigation = () => {
    navigator.navigate('CompanyDetailsPageMobileNavigation', params);
  };
  return (
    <AdminAppPage.HeaderContainer responsive={responsive}>
      <Space height={8} />
      <TertiaryButton
        onPress={handleBackNavigation}
        text={'Settings'}
        iconLeft={Icon.AngleLeft}
        textColor={colors.blue.interactive}
        isResponsive
      />
      <Space height={12} />
      <AdminAppPage.PageHeadingText responsive={responsive}>
        {organization.name}
      </AdminAppPage.PageHeadingText>
    </AdminAppPage.HeaderContainer>
  );
};

interface CompanyDetailsPageContentProps {
  children: React.ReactNode;
  viewer: UserModel;
  organization: OrganizationModel;
  selectedLabel: string;
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  pageContainerStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

const CompanyDetailsPageContent = ({
  children,
  viewer,
  organization,
  selectedLabel,
  pageTitle,
  pageDescription,
  actionElement,
  pageContainerStyle,
  contentContainerStyle,
}: CompanyDetailsPageContentProps) => {
  const responsive = useResponsive();
  return (
    <AdminAppPage
      title={organization?.name}
      containerStyle={pageContainerStyle}
      HeaderElement={
        responsive.desktop ? (
          <Header organization={organization} />
        ) : (
          <MobileHeader organization={organization} />
        )
      }
    >
      <ContentContainer>
        {responsive.desktop && (
          <React.Fragment>
            <NavigationContainer>
              <NavigationContent selectedLabel={selectedLabel} viewer={viewer} />
            </NavigationContainer>
            <VerticalDivider />
          </React.Fragment>
        )}
        <ContentBodyContainer style={contentContainerStyle}>
          <ScrollView
            contentContainerStyle={{
              alignSelf: 'center',
              width: '100%',
              // The extra maxWidth comes from the horizontal padding.
              maxWidth: 1200 + 2 * (responsive.desktop ? 24 : 0),
              paddingHorizontal: responsive.desktop ? 24 : 0,
              paddingVertical: responsive.desktop ? 24 : 16,
            }}
          >
            <CompanyPageContentHeader
              pageTitle={pageTitle}
              pageDescription={pageDescription}
              actionElement={actionElement}
            />
            <Space height={24} />
            {children}
          </ScrollView>
        </ContentBodyContainer>
      </ContentContainer>
    </AdminAppPage>
  );
};

interface CompanyDetailsPageProps {
  children: React.ReactNode;
  selectedLabel: string;
  pageTitle: string;
  pageDescription?: string;
  actionElement?: React.ReactNode;
  pageContainerStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

const CompanyDetailsPage = ({
  children,
  selectedLabel,
  pageTitle,
  pageDescription,
  actionElement,
  pageContainerStyle,
  contentContainerStyle,
}: CompanyDetailsPageProps) => {
  const {params} = useNavigation();
  const {loading, data} = useQuery(CompanyDetailsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {slug: params.slug},
  });

  if (loading && !data) {
    return <AdminAppPage title={'Companies / ...'} containerStyle={pageContainerStyle} isLoading />;
  }

  return (
    <CompanyDetailsPageContent
      viewer={data.viewer}
      organization={data.organization}
      selectedLabel={selectedLabel}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      actionElement={actionElement}
      pageContainerStyle={pageContainerStyle}
      contentContainerStyle={contentContainerStyle}
    >
      {children}
    </CompanyDetailsPageContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompanyDetailsPage.query = gql`
  ${CompanyDetailsNavigation.getItems.fragment}
  query CompanyDetailsPage($slug: String!) {
    ${gql.query}
    viewer {
      id
      ...CompanyDetailsNavigation_getItems
    }
    organization(slug: $slug) {
      id
      name
    }
  }
`;

export default CompanyDetailsPage;
