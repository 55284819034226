// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import CompanyIsActiveForm from '@shared/modules/Company/forms/CompanyIsActiveForm';
import useUpdateCompanyIsActiveMutation from '@shared/modules/Company/hooks/useUpdateCompanyIsActiveMutation';

const DeactivateCompanyModal = ({
  company,
  isOpen,
  handleClose,
}: {
  company: CompanyModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const companyIsActiveForm = CompanyIsActiveForm.edit(company, {isActive: false});
  const {handleSubmit, submitting} = useUpdateCompanyIsActiveMutation({
    companyIsActiveForm,
    onSuccess: handleClose,
    onError: (error: unknown) => {
      console.log({error});
    },
  });

  return (
    <ActionModal
      icon={Icon.Trash}
      color={colors.red.warning}
      isOpen={isOpen}
      title={'Deactivate Company?'}
      message={
        'This company will no longer be a part of Supermove.' +
        ' All access permission will be halted.' +
        ' This company can be reactivated at any time.'
      }
      handlePressOutside={handleClose}
      handleSecondaryAction={handleClose}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      primaryActionText={'Confirm'}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeactivateCompanyModal.fragment = gql`
  ${CompanyIsActiveForm.edit.fragment}

  fragment DeactivateCompanyModal on Company {
    id
    ...CompanyIsActiveForm_edit
  }
`;

export default DeactivateCompanyModal;
