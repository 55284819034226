// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {WorkflowModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowTriggerIdentifiers from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';

const filterTriggers = withFragment(
  (workflow: WorkflowModel, {triggers}) => {
    /*
  Conditionally filter triggers here. Eg:
  disabledTriggers = [
    ...(workflow.organization.featureFlag ? [] : [WorkflowTriggerIdentifer.IDENTIFIER])
  ]
*/
    const disabledTriggers = [
      ...((workflow as any).organization.features.isEnabledPaymentsAutomationTrigger
        ? []
        : [
            WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_FAILED,
            WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_SUCCEEDED,
          ]),
      ...((workflow as any).organization.features
        .isEnabledAutomationSendProposalJobStatusStaffAssigned
        ? []
        : [WorkflowTriggerIdentifiers.PREPARE_STARTED]),
      ...((workflow as any).organization.features.isEnabledAdditionalSalespersonIdsInput
        ? []
        : [WorkflowTriggerIdentifiers.PROJECT_ADDITIONAL_SALESPERSON_CHANGED]),
    ];
    return _.filter(triggers, (trigger) => !_.includes(disabledTriggers, trigger));
  },
  gql`
    fragment Workflow_filterTriggers on Workflow {
      id
      organization {
        id
        features {
          isEnabledPaymentsAutomationTrigger: isEnabled(feature: "PAYMENTS_AUTOMATION_TRIGGER")
          isEnabledAutomationSendProposalJobStatusStaffAssigned: isEnabled(
            feature: "AUTOMATION_SEND_PROPOSAL_JOB_STATUS_STAFF_ASSIGNED"
          )
          isEnabledAdditionalSalespersonIdsInput: isEnabled(
            feature: "ADDITIONAL_SALESPERSON_IDS_INPUT"
          )
        }
      }
    }
  `,
);

const getIsJobTriggerIdentifier = withFragment(
  (triggerIdentifier: any) => {
    return WorkflowTriggerIdentifiers.JOB_LEVEL_WORKFLOW_IDENTIFIERS.includes(triggerIdentifier);
  },
  gql`
    fragment Workflow_getIsJobTriggerIdentifier on Workflow {
      id
      triggerIdentifier
    }
  `,
);

const getWorkflowStepActionKindOptions = withFragment(
  (workflow: WorkflowModel, triggerIdentifierFromForm) => {
    const isJobTriggerIdentifier = getIsJobTriggerIdentifier(triggerIdentifierFromForm);
    return _.reduce(
      WorkflowStepActionKind.VALUES,
      // @ts-expect-error TS(2769): No overload matches this call.
      (result, actionKind) => {
        switch (actionKind) {
          case WorkflowStepActionKind.ASSIGN_TO_BRANCH:
          case WorkflowStepActionKind.ASSIGN_TO_BRANCH_JOB:
            // If the action kind is assign to branch, we first check if its an org that has multi
            // organization, if it is not, we don't show this actionKind
            if (!(workflow as any).organization.hasMultipleOrganizations) {
              return result;
              // If the action kind is assign to branch, we don't show this for any job level trigger identifiers
            } else if (
              actionKind === WorkflowStepActionKind.ASSIGN_TO_BRANCH &&
              isJobTriggerIdentifier
            ) {
              return result;
              // This checks if the action kind is assign job to branch
            } else if (actionKind === WorkflowStepActionKind.ASSIGN_TO_BRANCH_JOB) {
              // If the feature flag is on, we don't show this actionkind
              if (!(workflow as any).organization.features.isEnabledJobLevelAssignToBranch) {
                return result;
              }
              // If the trigger identifier is a project category, we also don't show this actionkind
              if (!isJobTriggerIdentifier) {
                return result;
              }
            }
            return [...result, actionKind];
          case WorkflowStepActionKind.INVOKE_WEBHOOK:
            return [...result, actionKind];
          case WorkflowStepActionKind.PROJECT_ADD_TAG:
          case WorkflowStepActionKind.PROJECT_REMOVE_TAG:
            return [...result, actionKind];
          case WorkflowStepActionKind.JOB_ADD_TAG:
          case WorkflowStepActionKind.JOB_REMOVE_TAG:
            if (!isJobTriggerIdentifier) {
              return result;
            }
            return [...result, actionKind];
          case WorkflowStepActionKind.GET_JOB_DATA:
            // For now just block job data since its not used
            return result;
          case WorkflowStepActionKind.ASSIGN_OFFICE_STAFF:
            if (
              !(workflow as any).organization.features.isEnabledAssignOfficeStaffAutomationAction
            ) {
              return result;
            }
            return [...result, actionKind];
          case WorkflowStepActionKind.STOP_IF:
          case WorkflowStepActionKind.GET_PROJECT_DATA:
          case WorkflowStepActionKind.FIND_TASK_FROM_PREVIOUS_STEP:
            if ((workflow as any).organization.features.isEnabledAutomationStopIf) {
              return result;
            }
            return [...result, actionKind];
          case WorkflowStepActionKind.GET_DATA_AND_STOP_IF:
            if (!(workflow as any).organization.features.isEnabledAutomationStopIf) {
              return result;
            }
            return [...result, actionKind];
          // ////////////////////////////////////////////////////////////////////
          // FEATURE FLAG REMOVAL BLOCK START: isEnabledAutomationSendProposalJobStatusStaffAssigned
          // ////////////////////////////////////////////////////////////////////
          case WorkflowStepActionKind.SEND_QUOTE:
            if (
              !(workflow as any).organization.features
                .isEnabledAutomationSendProposalJobStatusStaffAssigned ||
              triggerIdentifierFromForm === WorkflowTriggerIdentifiers.QUOTE_SENT
            ) {
              return result;
            }
            return [...result, actionKind];
          case WorkflowStepActionKind.SEND_CONFIRMATION:
            if (
              !(workflow as any).organization.features
                .isEnabledAutomationSendProposalJobStatusStaffAssigned ||
              triggerIdentifierFromForm === WorkflowTriggerIdentifiers.CONFIRMATION_SENT
            ) {
              return result;
            }
            return [...result, actionKind];
          // ////////////////////////////////////////////////////////////////////
          // FEATURE FLAG REMOVAL BLOCK END
          // ////////////////////////////////////////////////////////////////////
          case WorkflowStepActionKind.SEND_DOCUMENT_FLOW:
            if (
              !workflow.organization.features.isEnabledDocumentFlowAutomation ||
              triggerIdentifierFromForm ===
                WorkflowTriggerIdentifiers.PROJECT_DOCUMENT_FLOW_RUN_SENT
            ) {
              return result;
            }
            return [...result, actionKind];
          // DEPRECATED ACTIONS
          case WorkflowStepActionKind.SEND_SMS:
            return result;
          default:
            return [...result, actionKind];
        }
      },
      [],
    );
  },
  gql`
    fragment Workflow_getWorkflowStepActionKindOptions on Workflow {
      id
      triggerIdentifier
      organization {
        id
        hasMultipleOrganizations
        features {
          isEnabledJobLevelAssignToBranch: isEnabled(feature: "JOB_LEVEL_ASSIGN_TO_BRANCH")
          isEnabledAssignOfficeStaffAutomationAction: isEnabled(
            feature: "ASSIGN_OFFICE_STAFF_AUTOMATION_ACTION"
          )
          isEnabledAutomationStopIf: isEnabled(feature: "AUTOMATION_STOP_IF")
          isEnabledAutomationSendProposalJobStatusStaffAssigned: isEnabled(
            feature: "AUTOMATION_SEND_PROPOSAL_JOB_STATUS_STAFF_ASSIGNED"
          )
          isEnabledDocumentFlowAutomation: isEnabled(feature: "DOCUMENT_FLOW_AUTOMATION")
        }
      }
    }
  `,
);

const WORKFLOW_PROJECT_VARIABLE_OPTIONS = [
  {
    value: HardcodedSystemVariables.ALL_VARIABLES.PROJECT_TOTAL_REVENUE.value,
    label: 'Grand Total',
  },
];

const getProjectValueDropdownOptions = withFragment(
  (workflow) => {
    const sortedOptions = _.sortBy(WORKFLOW_PROJECT_VARIABLE_OPTIONS, ['label']);
    return sortedOptions;
  },
  gql`
    fragment Workflow_getProjectValueDropdownOptions on Workflow {
      id
    }
  `,
);

const Workflow = {
  filterTriggers,
  getWorkflowStepActionKindOptions,
  getProjectValueDropdownOptions,
  getIsJobTriggerIdentifier,
};

export default Workflow;
