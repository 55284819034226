// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface CompanyIsActiveFormType {
  companyId: string;
  isActive: boolean;
}

const edit = withFragment(
  (company: CompanyModel, {isActive}: {isActive?: boolean} = {}): CompanyIsActiveFormType => ({
    companyId: company.id,
    isActive: isActive ?? company.isActive,
  }),
  gql`
    fragment CompanyIsActiveForm_edit on Company {
      id
      isActive
    }
  `,
);

const toForm = (companyIsActiveForm: CompanyIsActiveFormType): CompanyIsActiveFormType => ({
  companyId: companyIsActiveForm.companyId,
  isActive: companyIsActiveForm.isActive,
});

const toMutation = (companyIsActiveForm: CompanyIsActiveFormType): CompanyIsActiveFormType => ({
  companyId: companyIsActiveForm.companyId,
  isActive: companyIsActiveForm.isActive,
});

const CompanyIsActiveForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyIsActiveForm;
